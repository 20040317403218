import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private message = {};

  constructor(private _translateService: TranslateService) {
    this._translateService.get('error-message').subscribe(message => {
      this.message = message;
    });
  }

  public getClientMessage(error: Error): string {
    // Online/Offline check
    if (!navigator.onLine) {
      return this.message['please-retry'];
    } else {
      return error.message ? error.message : error.toString();
    }
  }

  public getClientStack(error: Error): string {
    return error.stack;
  }

  public getServerMessage(error: HttpErrorResponse): string {
    if (error) {
      if (!navigator.onLine) {
        return this.message['please-retry'];
      } else {
        // api server generated errors
        return JSON.stringify(error.error);
      }
    }
  }

  public getServerStack(error: HttpErrorResponse): string {
    // return angular error message as stack
    return error.message;
  }
}
