<div class="navbar">
  <div class="btn-group" dropdown>
    <button
      data-testid="user-profile-dropdown"
      id="user_profile_dropdown_menu"
      type="button"
      class="btn btn-link"
      (mouseover)="showTooltip($event, userElem, 'user-profile.account-settings-header')"
      (mouseleave)="hideTooltip()"
      dropdownToggle
      #userElem>
      <div class="user-initials-icon">
        {{ initials }}
      </div>
    </button>
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
      <li class="user-profile-text-container">
        <div class="user-profile-text">
          <h5 data-testid="user-profile-username" class="d-inline-block w-medium">
            {{ name }}
          </h5>
          <h5 class="email-display">
            {{ displayEmail }}</h5>
        </div>
      </li>

      <div class="horizontal-bar"></div>

      <li class="clickable-heading">
        <a class="dropdown-item" data-testid="user-profile-link"
           [routerLink]="['./user-profile']"
           routerLinkActive="bold">
          <img src="./assets/icons/line-icons/shared/grey/profile.svg" width="15px" height="15px">
          {{ 'main-navigation.profile' | translate }}
        </a>
      </li>

      <!-- Management (Org Admin) -->
      <ng-container *ngIf="orgAdmin">
        <p class="category-header">
          <img src="./assets/icons/line-icons/shared/grey/company.svg" width="15px" height="15px">
          {{ 'main-navigation.admin.management' | translate }}
        </p>
        <li>
          <a class="dropdown-item" data-testid="manage-orgs-link"
             [routerLink]="['./organization-management/profile/organization-profile']"
             routerLinkActive="bold">
            {{ 'main-navigation.organization' | translate }}
            <ng-container *ngIf="!profileIsCompleted">
              <i class="fa fa-exclamation-triangle"
                  container="body"
                  placement="left"
                  tooltip="{{ 'account-setup.tooltip' | translate }}">
              </i>
            </ng-container>
          </a>
        </li>
        <li>
          <a class="dropdown-item" data-testid="manage-data-link"
             [routerLink]="['./organization-management/data-manager']"
             routerLinkActive="bold">
            {{ 'main-navigation.data-manager' | translate }}</a>
        </li>
        <li>
          <a class="dropdown-item" data-testid="manage-users-link"
             [routerLink]="['./organization-management/users']"
             routerLinkActive="bold">
            {{ 'main-navigation.organization-users' | translate }}</a>
        </li>
      </ng-container>

      <!-- System Admin -->
      <ng-container *ngIf="sysAdmin">
        <p class="category-header">
          <img src="./assets/icons/line-icons/shared/grey/admin.svg" width="15px" height="15px">
          {{ 'main-navigation.admin.system-configuration' | translate }}
        </p>
        <li>
          <a class="dropdown-item" data-testid="manage-all-orgs-link"
             [routerLink]="['./admin/organizations']"
             routerLinkActive="bold">
            {{ 'main-navigation.admin-organizations' | translate }}</a>
        </li>
        <li>
          <a class="dropdown-item" data-testid="manage-all-users-link"
             [routerLink]="['./admin/users']"
             routerLinkActive="bold">
            {{ 'main-navigation.admin-users' | translate }}</a>
        </li>
        <li>
          <a class="dropdown-item" data-testid="form-managers-link"
             [routerLink]="['./admin/form-manager']"
             routerLinkActive="bold">
            {{ 'main-navigation.form-manager' | translate }}</a>
        </li>
        <li>
          <a class="dropdown-item" data-testid="data-admin-nav-link"
             [routerLink]="['./admin/data-admin']"
             routerLinkActive="bold">
             {{ 'main-navigation.data-admin' | translate }}</a>
        </li>
        <li>
          <a class="dropdown-item" data-testid="c&i-link"
             [routerLink]="['./admin/manage']"
             routerLinkActive="bold">
            {{ 'main-navigation.manage' | translate }}</a>
        </li>
      </ng-container>

      <div class="horizontal-bar"></div>

      <li class="clickable-heading logout-button">
        <a class="dropdown-item"
           data-testid="keycloak-logout"
           href="{{logoutUri}}"
           (click)="logout()">

          <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9999 4.33333L14.6666 7M14.6666 7L11.9999 9.66667M14.6666 7H5.99992M9.99992 1.80269C9.15009 1.29218 8.16341 1 7.11103 1C3.92005 1 1.33325 3.68629 1.33325 7C1.33325 10.3137 3.92005 13 7.11103 13C8.16341 13 9.15009 12.7078 9.99992 12.1973" stroke="#EE7802" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ 'main-navigation.logoff' | translate }}
        </a>
      </li>

      <div class="horizontal-bar mb-0"></div>

      <li class="nav-footer">
        <oes-footer></oes-footer>
      </li>
    </ul>
  </div>
</div>
