import { NavItem } from '../nav-item.model';

export class RfqDetailVendorNav {
  public static item: NavItem[] = [
    {
      title: 'my-rfqs.nav.overview',
      url: 'overview',
      testId: 'my-portfolio-nav-overview',
      icon: '<img src="./assets/icons/line-icons/nav/grey/overview.svg" width="23px" height="23px">',
    },
    {
      title: 'my-rfqs.nav.messages',
      url: 'messages',
      testId: 'my-portfolio-nav-introduction',
      icon: '<img src="./assets/icons/line-icons/nav/grey/messages.svg" width="23px" height="23px">',
    },
  ];
}
