import { Injectable } from '@angular/core';
import { DeviceDetectorService, BROWSERS, DeviceInfo } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class BrowserCheckService {
  private browserBlackList = [
    BROWSERS.IE,
    BROWSERS.MS_EDGE
  ];
  public deviceInfo: DeviceInfo;

  constructor(private _deviceService: DeviceDetectorService) {
    this.deviceInfo = this._deviceService.getDeviceInfo();
  }

  get isSupportedBrowser(): boolean {
    if (this.browserBlackList.includes(this.deviceInfo.browser)) {
      return false;
    }
    // Supported safari version is greater than 10.x
    if (this.deviceInfo.browser === BROWSERS.SAFARI &&
        Number(this.deviceInfo.browser_version.split('.')[0]) <= 10 ) {
      return false;
    }
    return true;
  }

  get isSupportedDevice(): boolean {
     if (this._deviceService.isMobile() || this._deviceService.isTablet()) {
      return false;
    }
    return true;
  }
}
