import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-rmi',
  templateUrl: './rmi.component.html',
  styleUrls: ['./rmi.component.scss']
})
export class RmiComponent {

  constructor() { }

}
