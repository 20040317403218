<ng-container *ngIf="!renderOapHome">
  <oes-popup-message></oes-popup-message>

  <!-- Progress Bar -->
  <div class="progress-container" *ngIf="loading">
    <div class="progress-bar-main progress-bar-main-animated" role="progressbar"
         [style.width.%]="progress" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
  </div>

  <!-- Spinner -->
  <div *ngIf="spinner" class="spinner-border text-primary" role="status">
    <div class="loading-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <feature-toggle-provider [features]="getFeatureToggles()">
    <div *ngIf="['oes-qa', 'oes-dev'].includes(environment)" [formGroup]="formGroup">
      <select
        class="theme-box"
        formControlName="themeSelector">
        <option *ngFor="let theme of allThemes">{{theme}}</select>
    </div>
    <div class="main-router" [ngClass]="{'no-clicking': spinner }">
      <router-outlet></router-outlet>
    </div>
    <oes-modal-dialog
      [size]="'small'"
      [title]="'login.reload-dialog.title' | translate"
      [allowSubmit]="true"
      [allowCancel]="false"
      [submitButtonText]="'buttons.reload' | translate"
      (close)="reload()"
      (submit)="reload()">
      <div>{{'login.reload-dialog.message' | translate}}</div>
    </oes-modal-dialog>
  </feature-toggle-provider>
</ng-container>
