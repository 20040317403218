import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { GridOptions,  GridApi, ColumnApi } from 'ag-grid-community';
import { EventService } from '@shared/event.service';
import { DataManagerGridSettingService } from '@organization-management/data-manager/data-manager-grid-setting.service';
import { DataImportRequest } from '@shared/models/io/data-import-request.model';
import { DataImportRequestItem } from '@shared/models/io/data-import-request-item.model';
import { DataImportRequestService } from '@organization-management/data-manager/data-import-request.service';
import { DataImportRequestItemService } from '@organization-management/data-manager/data-import-request-item.service';
import { UserService } from '@user/user.service';
import { User } from '@user/user.model';
import { take } from 'rxjs/operators';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { OAuthService } from 'angular-oauth2-oidc';
import { zip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { GridState } from '@shared/ag-grid/gird-state';

@Component({
  selector: 'oes-data-manager',
  templateUrl: './data-manager.component.html',
  styleUrls: ['./data-manager.component.scss'],
  providers: [
  ]
})

export class DataManagerComponent implements OnInit {
  columnApi: ColumnApi;
  defaultGridState: GridState;
  gridApi: GridApi;
  gridOptions: GridOptions = {};
  gridState: GridState;
  organizationId: string;
  uploader: FileUploader = new FileUploader({ autoUpload: true, url: '' });

  private requests: Array<DataImportRequestItem> = new Array<DataImportRequestItem>();
  private uploadEl: any;
  private gridStateStorageKey: string;

  constructor(private _dataImportRequestService: DataImportRequestService,
              private _dataImportRequestItemService: DataImportRequestItemService,
              public _dataManagerGridSettingService: DataManagerGridSettingService,
              private _eventService: EventService,
              private _userService: UserService,
              private _translateService: TranslateService,
              private _oauthService: OAuthService) {
    this._userService.getCurrentUser().subscribe((user: User) => {
    });
  }

  ngOnInit() {
    this.gridStateStorageKey = this._dataManagerGridSettingService.buildGridStateStorageKey('dataManager');
    this.gridOptions = this._dataManagerGridSettingService.getGridOptions();
    this.configureFileUploader();
  }

  public onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._dataManagerGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._dataManagerGridSettingService.buildDefaultGridState();
    this.getRequests();
  }

   gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._dataManagerGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
    }
  }

  private getRequests() {
    this._dataImportRequestService.list(this._userService.organizationId)
    .pipe(take(1))
    .subscribe((results: DataImportRequest[]) => {
      if (results.length > 0) {
        this.requests = [];
        const subscribeAll = results.map(result => {
          return this._dataImportRequestItemService.getRequestItems(this._userService.organizationId, result.id);
        });
        zip(...subscribeAll)
        .subscribe((items) => {
          if (items?.length > 0) {
            items.forEach(itemSets => {
              this.requests = [...this.requests, ...itemSets];
            });
          }
          this.gridApi.setRowData(this.requests);
          this._dataManagerGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
        });
        this.gridApi.showLoadingOverlay();
        this.gridApi.hideOverlay();
        this.gridApi.sizeColumnsToFit();
      }
    });
  }

  configureFileUploader() {
    const uploadUrl = this._dataImportRequestService.uploadUrl(this._userService.organizationId);
    const theAuthHeader = 'Bearer ' + this._oauthService.getAccessToken();
    this.uploader.setOptions({url: uploadUrl, removeAfterUpload: true, authToken: theAuthHeader});
    this.uploader.onAfterAddingAll = () => {
    };
    this.uploader.onCompleteAll = (() => {
      // refresh list
      this.getRequests();
      // reset file value so same file can be re-uploaded
      if (this.uploadEl) { this.uploadEl.value = ''; }
    });
    this.uploader.onCompleteItem = (item, response: any) => {
      response = JSON.parse(response);
      if (response.message) {
        this._eventService.error(response.message);
      } else {
        this._eventService.success(item.file.name + ' ' + this._translateService.instant('success-message.has-been-uploaded'));
      }
    };
  }

  onUploadChange(event: any) {
    this.uploadEl = event.target || event.srcElement;
  }

  public subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList:
        this._dataManagerGridSettingService.exportCsv(this.gridApi, 'DataManager', true);
        break;
      case GridSubAction.reload:
        this.getRequests();
        break;
      case GridSubAction.clearFilter:
        this._dataManagerGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        break;
    }
  }
}
