
import { of as observableOf, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ROLE_TYPE } from '@user/role-type';
import { Organization } from '@shared/models/organization/organization.model';
import { DeveloperInformation } from '@organization-management/organization/developer-information/developer-information.model';
import { InvestorInformation } from '@organization-management/organization/investor-information/investor-information.model';
import { VendorInformation } from '@organization-management/organization/vendor-information/vendor-information.model';
import { OrganizationService } from '@organization-management/organization/organization.service';
import { DeveloperInformationService } from '@organization-management/organization/developer-information/developer-information.service';
import { UserService } from '@user/user.service';
import { InvestorInformationService } from '@organization-management/organization/investor-information/investor-information.service';
import { VendorInformationService } from '@organization-management/organization/vendor-information/vendor-information.service';

@Injectable({
  providedIn: 'root'
})
export class AccountSetupService {
  private requestObject: {[key: string]: Observable<Organization |
                                  DeveloperInformation |
                                  InvestorInformation |
                                  VendorInformation>
                  } = {};

  constructor(private _orgProfileService: OrganizationService,
              private _developerProfileService: DeveloperInformationService,
              private _investorInformationService: InvestorInformationService,
              private _vendorInformationService: VendorInformationService,
              private _userService: UserService) { }

  public validateProfilesAndPasswordReset(): Observable<boolean> {
    if (this._userService.organizationId) {
      this.buildRequestObject();
      const requests = Object.keys(this.requestObject).map(key => this.requestObject[key]);
      let areProfilesValid: Observable<boolean> = observableOf(true);
      if (requests.length) {
        return areProfilesValid = forkJoin(requests).pipe(map(results => {
          const keys: string[] = Object.keys(this.requestObject);
          let isOrganizationProfileValid = true;
          let isDeveloperProfileValid = true;
          let isInvestorProfileValid = true;
          let isVendorProfileValid = true;
          keys.forEach((key: string, index: number) => {
            switch (key) {
              case 'orgAdmin':
                isOrganizationProfileValid = new Organization(results[index]).isOrganizationProfileValid;
                break;
              case 'devProfile':
                isDeveloperProfileValid = new DeveloperInformation(results[index]).isDeveloperInfoValid;
                break;
              case 'invProfile':
                isInvestorProfileValid = (<InvestorInformation>results[index]).isInvestorInfoValid;
                break;
              case 'vendProfile':
                isVendorProfileValid = new VendorInformation(results[index]).isVendorInfoValid;
                break;
            }
          });
          return isOrganizationProfileValid && isDeveloperProfileValid &&
                 isInvestorProfileValid && isVendorProfileValid;
        }));
      } else {
        return observableOf(true);
      }
    } else {
      return observableOf(true);
    }
  }

  private buildRequestObject() {
    this.requestObject = {};
    if (this._userService.hasRole(ROLE_TYPE.ORGANIZATION_ADMIN)) {
      this.requestObject.orgAdmin = this._orgProfileService.getMyOrganization();
      if (this._userService.hasRole(ROLE_TYPE.DEVELOPER_USER)) {
        this.requestObject.devProfile = this._developerProfileService.list(this._userService.organizationId);
      }
      if (this._userService.hasRole(ROLE_TYPE.FINANCE_USER)) {
        this.requestObject.invProfile = this._investorInformationService.list(this._userService.organizationId);
      }
      if (this._userService.hasRole(ROLE_TYPE.VENDOR_USER)) {
        this.requestObject.vendProfile = this._vendorInformationService.list(this._userService.organizationId);
      }
    }
  }
}
