import { ROLE_TYPE } from '@user/role-type';
import { NavItem } from '../nav-item.model';

export class OrganizationProfileNav {
  public static item: NavItem[] = [
    {
      title: 'profile-nav.org-profile',
      url: 'organization-management/profile/organization-profile',
      roles: [ROLE_TYPE.ORGANIZATION_ADMIN],
      bold: true,
    },
    {
      title: 'profile-nav.project-dev-profile',
      roles: [ROLE_TYPE.DEVELOPER_USER],
      bold: true,
      children: [
        {
          title: 'profile-nav.public-profile',
          url: 'organization-management/profile/developer/public-profile',
        },
        {
          title: 'profile-nav.further-info',
          url: 'organization-management/profile/developer/further-information',
        },
      ]
    },
    {
      title: 'profile-nav.investor-profile',
      roles: [ROLE_TYPE.FINANCE_USER],
      bold: true,
      children: [
        {
          title: 'profile-nav.public-profile',
          url: 'organization-management/profile/investor/public-profile',
        },
        {
          title: 'profile-nav.further-info',
          url: 'organization-management/profile/investor/further-information',
        },
      ]
    },
    {
      title: 'profile-nav.vendor-profile',
      roles: [ROLE_TYPE.VENDOR_USER],
      bold: true,
      children: [
        {
          title: 'profile-nav.public-profile',
          url: 'organization-management/profile/vendor/public-profile',
        },
      ]
    }
  ];
}
