<div class="home" data-testid="tdb-home-container">
  <div class="header-images">
    <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/tdb-bg.jpg')"></div>
  </div>

  <div class="tdb-content-top">
    <img src="/assets/images/tdb/tdb-logo.png" width="200px" />
    <p>The Trade and Development Bank (TDB) has established the Regional Energy Access Finance Platform (REAF) to support distributed renewable energy (DRE) and clean cooking private sector initiatives in eligible countries of the World Bank's International Development Association (IDA) that are TDB member states.</p>
  </div>

  <div class="tdb-content-bottom">
    <div class="tdb-program">
      <p><b>Lending to Distributed Renewable Energy (DRE) and clean cooking companies</b></p>
      <p>Debt financing to DRE and clean cooking companies to expand electricity and clean cooking access in IDA-eligible countries in the Eastern and Southern Africa Region that are TDB members.</p>
      <div class="tdb-program-footer">
        <button [routerLink]="['../finance/programs']" class="btn btn-primary">{{ 'buttons.apply' | translate }}</button>
      </div>
    </div>

    <div class="tdb-program">
      <p><b>Performance Grant Facility for the frontier markets</b></p>
      <p>Grant funding via Results-Based Financing (RBF) and performance-based catalytic grants to support Distributed Renewable Energy (DRE) and clean cooking expansion in markets unserved or underserved by national programs.</p>
      <div class="tdb-program-footer">
        <button [routerLink]="['../finance/programs']" class="btn btn-primary">{{ 'buttons.apply' | translate }}</button>
      </div>
    </div>
  </div>

  <div class="tdb-institutions">
    <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/tdb-inst.jpg')"></div>
  </div>
</div>
