<div class="home" data-testid="amap-home-container">
  <div class="body">
    <img src="/assets/images/amap/sefa.png" width="400px" class="mb-3" />
    <img src="/assets/images/amap/afdb.jpg" width="300px" />
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
