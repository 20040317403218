import { NavItem } from '../nav-item.model';

export class OfftakerDetailDeveloperNav {
  public static item: NavItem[] = [
    {
      title: 'offtaker-navigation.overview',
      url: 'overview',
      testId: 'offtakear-nav-overview',
      icon: '<img src="./assets/icons/line-icons/nav/grey/overview.svg" width="23px" height="23px">',
    }
  ];
}
