import {BaseModel} from '../../base.model';

export class DataImportRequest extends BaseModel<DataImportRequest> {
  created: Date;
  fileUniqueIdentifier: string;
  requestType: string;
  status: string;
  updated: Date;

  constructor(data: DataImportRequest) {
    super();
    this.id = data.id;
    this.created = data.created;
    this.fileUniqueIdentifier = data.fileUniqueIdentifier;
    this.requestType = data.requestType;
    this.status = data.status;
    this.updated = data.updated;
  }
}
