<ng-container *ngIf="platformVis">
  <div class="community-container">
    <h3>{{ 'home.community-activity' | translate }}</h3>
    <p>{{ 'home.community-activity-sub' | translate }}</p>
    <div class="community-metrics">
      <div class="community-metric">
        <h5>{{ 'home.active-organizations' | translate }}</h5>
        <div class="kpi-display">{{ platformVis.organizationsCount | number : '1.0-0' }}</div>
      </div>
      <div class="community-metric">
        <h5>{{ 'home.projects-planned' | translate }}</h5>
        <div class="kpi-display">{{ platformVis.projectsCount | number : '1.0-0' }}</div>
      </div>
      <div class="community-metric">
        <h5>{{ 'home.countries' | translate }}</h5>
        <div class="kpi-display">{{ platformVis.countriesCount | number : '1.0-0' }}</div>
      </div>
      <div class="community-metric">
        <h5>{{ 'home.available-funding' | translate }}</h5>
        <div class="kpi-display">{{ totalAvailableFunding }}</div>
      </div>
      <div class="community-metric">
        <h5>{{ 'home.active-programs' | translate }}</h5>
        <div class="kpi-display">{{ platformVis.activeProgramsCount | number : '1.0-0' }}</div>
      </div>
    </div>
  </div>
  <div class="spacer"></div>
</ng-container>
