import { Component, Input } from '@angular/core';
import { OdysseyPlatform } from '../odyssey-platform';
import { Organization } from '@shared/models/organization/organization.model';
import { SimpleProject } from '@project/shared/simple-project.model';
import { ROLE_TYPE } from '@user/role-type';

@Component({
  selector: 'oes-org-metrics',
  templateUrl: './org-metrics.component.html',
  styleUrls: ['./org-metrics.component.scss']
})
export class OrgMetricsComponent {
  @Input() hasCommercialInvestorDemoRole: boolean;
  @Input() mapProjects: SimpleProject[];
  @Input() organization: Organization;
  @Input() platformVis: OdysseyPlatform;
  @Input() selectedRole: ROLE_TYPE;

}
