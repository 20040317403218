import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-powertrust',
  templateUrl: './powertrust.component.html',
  styleUrls: ['./powertrust.component.scss']
})
export class PowertrustComponent {

  constructor() { }

}
