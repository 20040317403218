import { environment } from '@environments/environment';

export class InvestorInformationApiConstants {
  public static organizations = environment.serverUri + `api/${environment.apiVersion}/organizations`;

  public static investorInformation = {
    list: (organizationId: string) => {
      return `${InvestorInformationApiConstants.organizations}/${organizationId}/investor-information`;
    },
    detail: (organizationId: string, informationId: string) => {
      return `${InvestorInformationApiConstants.organizations}/${organizationId}/investor-information/${informationId}`;
    },
    update: (organizationId: string, informationId: string) => {
      return `${InvestorInformationApiConstants.organizations}/${organizationId}/investor-information/${informationId}`;
    }

  };
}
