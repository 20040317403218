import { MainMenuCategory } from '../main-menu-category.enum';

export class VendorMarketplaceNav {
  public static item = {
    title: 'main-navigation.vendor-marketplace',
    testId: 'vendor-marketplace-nav-link',
    icon: '<img src="./assets/icons/line-icons/nav/colors/package.svg" width="24px" height="24px">',
    themedIcon: {
      avorenewables: '<img src="./assets/images/avorenewables/icons/package.svg" width="24px" height="24px">'
    },
    bold: true,
    type: MainMenuCategory.build,
    children: [
      // { DT-3965
      //   title: 'main-navigation.my-rfqs',
      //   url: './vendor-marketplace/my-rfqs',
      //   icon: '',
      //   testId: 'my-rfqs-nav-link',
      // },
      {
        title: 'main-navigation.developer-profiles',
        url: '/oes/vendor-marketplace/developer-profiles',
        icon: '',
        testId: 'developer-profiles-nav-link',
      }
    ]
  };
}
