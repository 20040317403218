<form [formGroup]="formGroup" class="row">
  <ng-container *ngIf="organization">
      <!-- <div class="col-12"> -->
      <div class="bg-white">
        <div>
          <h3>{{'organization-information.developer.developer-overview.heading' | translate}}</h3>
          <p for="companyOverview" class="text-black">{{'organization-information.developer.developer-overview.subheading' | translate}}</p>
          <label class="required">{{'organization-information.developer.developer-overview.overview-heading' | translate}}</label>
          <oes-quill
            *ngIf="organization"
            formControlName="companyOverview"
            [initialValue]="organization?.companyOverview"
            [placeholder]="'organization-information.developer.developer-overview.overview' | translate"
            [characterLimit]="3000"
            [required]="true">
          </oes-quill>
          <div class="err-msg" *ngIf="formGroup?.controls?.companyOverview.invalid &&
                                      (formGroup?.controls?.companyOverview.touched ||
                                      formGroup?.controls?.companyOverview.dirty ||
                                      formGroup.submitted)">
            <p>{{'organization-information.developer.developer-overview.overview-err-msg' | translate}}</p>
          </div>
        </div>

        <!-- short video -->
        <div class="mt-30">
          <h4>{{'organization-information.developer.short-video.heading' | translate}}</h4>
          <p>{{'organization-information.developer.short-video.subheading' | translate}}</p>
          <div>
            <input
              type="text"
              class="col-4"
              placeholder="https://" name="videoLink"
              formControlName="videoLink" />
            <input
              type="text"
              class="col-4 ml-4"
              placeholder="Optional Description"
              name="videoLinkDescription"
              formControlName="videoLinkDescription" />
          </div>
        </div>

        <!-- micro-grid status -->
        <div class="mt-30">
          <h4 class="required">{{'organization-information.developer.have-you-developed-microgrids.heading' | translate}}</h4>
          <div class="form-group">
            <label class="input-control--sm input-control--radio mb-2">
              {{'organization-information.developer.have-you-developed-microgrids.no-not-yet' | translate}}
              <input
                id="statusNone"
                type="radio"
                formControlName="microGridStatus"
                name="microGridStatus"
                [value]="microGridStatus.NONE">
              <div class="input-control__indicator--sm"></div>
            </label>
            <label class="input-control--sm input-control--radio mb-2">
              {{'organization-information.developer.have-you-developed-microgrids.yes-have-developed' | translate}}
              <input
                id="statusDeveloped"
                type="radio"
                formControlName="microGridStatus"
                name="microGridStatus"
                [value]="microGridStatus.DEVELOPED">
              <div class="input-control__indicator--sm"></div>
            </label>
            <label class="input-control--sm input-control--radio mb-2">
              {{'organization-information.developer.have-you-developed-microgrids.yes-have-developed-operating' | translate}}
              <input
                id="statusDevelopedOperating"
                type="radio"
                formControlName="microGridStatus"
                name="microGridStatus"
                [value]="microGridStatus.DEVELOPED_OPERATING">
              <div class="input-control__indicator--sm"></div>
            </label>
            <div class="err-msg" *ngIf="formGroup?.controls?.microGridStatus?.invalid &&
                        (formGroup?.controls?.microGridStatus.touched ||
                        formGroup?.controls?.microGridStatus.dirty ||
                        formGroup.submitted)">
              <p>{{'organization-information.developer.have-you-developed-microgrids.err-msg' | translate}}</p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="showSaveCancelButtons"
                  class="btn btn-primary"
                  type="submit"
                  (click)="save()"
                  [disabled]="!userIsOrgAdmin || !formGroup.valid">{{'buttons.save' | translate}}</button>
        </div>
      </div>
      <div class="w-100" *ngIf="developerInfo && organization">
        <h3>{{'organization-information.developer.upload-photos.heading'|translate}}</h3>
        <oes-files
          *ngIf="tagTypes"
          [storageKey]="'developerPublicProfile'"
          [autoHeightOverride]="true"
          [tagTypes]="tagTypes"
          [accessControl]="accessControl"
          height="250px">
        </oes-files>
      </div>
    <!-- </div> -->
  </ng-container>
</form>

<oes-unsaved-data-modal
  (saveDataEmitter)="save()"
  (discardChangesEmitter)="discardChanges()">
</oes-unsaved-data-modal>
