import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-arene',
  templateUrl: './arene.component.html',
  styleUrls: ['./arene.component.scss']
})
export class AreneComponent {

  constructor() { }

}
