import {Component, OnInit, ViewChild} from '@angular/core';
import {Organization} from '@shared/models/organization/organization.model';
import {OrganizationService} from '../../organization.service';
import {EventService} from '@shared/event.service';
import {UserService} from '@user/user.service';
import {User} from '@user/user.model';
import {ROLE_TYPE} from '@user/role-type';
import {InvestorInformation} from '../investor-information.model';
import {UnsavedDataModalComponent} from '@shared/components/unsaved-data-modal/unsaved-data-modal.component';
import {NgForm} from '@angular/forms';
import { ComponentCanDeactivate } from '@organization-management/organization/shared/guard/component-can-deactivate.interface';
import { InvestorInformationService } from '../investor-information.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-organization-investor-further-information',
  templateUrl: 'organization-investor-further-information.component.html',
  styleUrls: ['organization-investor-further-information.component.scss'],
})

export class OrganizationInvestorFurtherInformationComponent implements OnInit, ComponentCanDeactivate {
  organization: Organization;
  investorInfo: InvestorInformation;
  userIsOrgAdmin = false;
  user: User;
  id: string;
  @ViewChild(UnsavedDataModalComponent, {static: false}) unSavedDataModalComponent: UnsavedDataModalComponent;
  @ViewChild('investorFurtherInfoForm', {static: false}) investorFurtherInfoForm: NgForm;

  constructor(private _organizationService: OrganizationService,
              private _investorInformationService: InvestorInformationService,
              private _translateService: TranslateService,
              private _userService: UserService,
              private _eventService: EventService) {
  }

  getOrganization() {
    this._organizationService.getMyOrganization()
    .subscribe(organization => {
      this.organization = new Organization(organization);
      this.getInvestorInfo();
    });
  }

  getInvestorInfo() {
    this._investorInformationService.list(this.organization.id)
    .subscribe((investorInfo: InvestorInformation) => {
      this.investorInfo = investorInfo;
    });
  }

  getUser() {
    this._userService.getCurrentUser().subscribe(user => {
      this.user = new User(user);
      if (this.user.roles.filter(role => role.name === ROLE_TYPE.ORGANIZATION_ADMIN).length) {
        this.userIsOrgAdmin = true;
      }
    });
  }

  saveChanges() {
    this._organizationService
      .save(this.organization)
      .subscribe(() => {
        this._investorInformationService.update(this.organization.id, this.investorInfo)
        .subscribe(() => {
          this.investorFurtherInfoForm.form.markAsPristine();
          this._eventService.success(this._translateService.instant('success-message.changes-saved'));
          this.unSavedDataModalComponent.hide();
        });
      });
  }

  // ComponentCanDeactivate requires
  hasUnsavedData() {
    return this.investorFurtherInfoForm.dirty;
  }

  // ComponentCanDeactivate requires
  showUnsavedDataModal(nextUrl: string) {
    this.unSavedDataModalComponent.show(nextUrl);
    return false;
  }

  discardChanges() {
    this.investorFurtherInfoForm.form.markAsPristine();
  }

  ngOnInit() {
    this.organization = new Organization({});
    this.getOrganization();
    this.getUser();
  }
}
