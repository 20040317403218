import { Component, OnInit } from '@angular/core';
import { Program } from '@program/shared/program.model';
import { ProgramService } from '@program/shared/program.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'oes-theme-uef',
  templateUrl: './uef.component.html',
  styleUrls: ['./uef.component.scss']
})
export class UefComponent implements OnInit {
  availableProgramIds: string[];
  buttonsHidden = true;
  prodProgramIds = {
    nigeria: '4a3a0608-bdb0-4b7e-ac77-bf2f6d15f123',
    benin: 'bdc4b6da-6c18-447d-88b2-6f517485d82a',
    sierraLeone: 'b38cf204-e0a5-44bb-9487-e530bc3f9e02',
    madagascar: 'a64298dd-166c-462a-93bd-c08affb1bb59',
    drc: 'f648a0f4-3a99-4252-a538-568d4476c5d5',
    zambia: 'd361665c-cafd-46dc-b329-8e68cf3c9107'
  };
  programAvailable = {
    nigeria: false,
    benin: false,
    sierraLeone: false,
    madagascar: false,
    drc: false,
    zambia: false
  };

  constructor(private _programService: ProgramService) { }

  ngOnInit(): void {
    this.getPrograms();
  }

  getPrograms() {
    this._programService.getPrograms()
      .pipe(take(1))
      .subscribe((programs: Program[]) => {
        this.availableProgramIds = programs.map(program => program.id);
        this.setProgramAvailable();
        this.buttonsHidden = false;
      });
  }

  setProgramAvailable() {
    Object.keys(this.prodProgramIds).forEach(prodProgramName => {
      this.programAvailable[prodProgramName] = this.availableProgramIds.includes(this.prodProgramIds[prodProgramName]);
    });
  }


}
