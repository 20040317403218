import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../user/user.service';
import { User } from '../user/user.model';
import { ROLE_TYPE} from '../user/role-type';
import { FreshchatService } from '@shared/services/freshchat.service';
import { InspectletService } from '@shared/services/inspectlet.service';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { SideNavigationService } from './side-navigation/side-navigation.service';
import { Subject } from 'rxjs';
import { SideNavigationSecondaryService } from '../side-navigation-secondary/side-navigation-secondary.service';
import { NavItem } from './shared/navi-item/nav-item.model';

@Component({
  selector: 'oes-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss']
})
export class MainNavigationComponent implements OnInit, OnDestroy {
  collapseMenuOpen = false;
  displayRoleSelector = false;
  loadingCompleted: boolean;
  open: boolean;
  profileCollapseMenuOpen = false;
  roleType = ROLE_TYPE;
  secondaryNavOpen: boolean = false;
  user: User;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _userService: UserService,
              private _inspectletService: InspectletService,
              private _freshchatService: FreshchatService,
              private _sideNavigationService: SideNavigationService,
              private _cdr: ChangeDetectorRef,
              private _sideNavigationSecondaryService: SideNavigationSecondaryService) {
  }

  ngOnInit() {
    this.getUser();
    this.subscribeSideNav();
    this.subscribeSideSecondaryNav();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private subscribeSideNav() {
    // sidenav loading completed status
    this._sideNavigationService.loadingCompleted$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(status => {
      this.loadingCompleted = status;
      this._cdr.detectChanges();
    });
  }

  private subscribeSideSecondaryNav() {
    this._sideNavigationSecondaryService.open$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(status => {
      this.secondaryNavOpen = status;
    });
  }

  private getUser() {
    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe((user: User) => {
      this.user = user;
      // update the current selectedRole
      this._userService.getSelectedRole();
      if (environment.name !== 'dev') {
        this._inspectletService.addInspectletInfo(this.user);
        this._freshchatService.addUserInformation(this.user);
      }
    });
  }

}
