import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-rrealiberia',
  templateUrl: './rrealiberia.component.html',
  styleUrls: ['./rrealiberia.component.scss']
})
export class RrealiberiaComponent {

  constructor() { }

}
