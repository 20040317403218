import { MainMenuCategory } from '../main-menu-category.enum';

export class FinanceInvestorNav {
  public static item = {
    title: 'main-navigation.finance',
    testId: 'finance-nav-link',
    icon: '<img src="./assets/icons/line-icons/nav/colors/bank.svg" width="24px" height="24px">',
    themedIcon: {
      avorenewables: '<img src="./assets/images/avorenewables/icons/bank.svg" width="24px" height="24px">'
    },
    bold: true,
    type: MainMenuCategory.finance,
    children: [
      {
        title: 'main-navigation.programs',
        url: '/oes/finance/programs',
        queryParams: {
          type: 'list'
        },
        testId: 'program-nav-link',
      },
      {
        title: 'main-navigation.portfolios',
        url: '/oes/finance/finance-marketplace',
        testId: 'portfolio-nav-link',
      },
      // {
      //   title: 'main-navigation.developer-profiles',
      //   url: './finance/developer-profiles',
      //   testId: 'developer-profiles-nav-link',
      // }
    ]
  };
}
