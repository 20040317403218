import {Component, OnInit, ViewChild} from '@angular/core';
import {Organization} from '@shared/models/organization/organization.model';
import {OrganizationService} from '../../organization.service';
import {EventService} from '@shared/event.service';
import {UserService} from '@user/user.service';
import {User} from '@user/user.model';
import {ROLE_TYPE} from '@user/role-type';
import {DeveloperInformation} from '../developer-information.model';
import {DeveloperInformationService} from '../developer-information.service';
import {UntypedFormControl, UntypedFormGroup, NgForm} from '@angular/forms';
import {UnsavedDataModalComponent} from '@shared/components/unsaved-data-modal/unsaved-data-modal.component';
import { ComponentCanDeactivate } from '@organization-management/organization/shared/guard/component-can-deactivate.interface';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'oes-organization-further-information',
  templateUrl: 'organization-developer-further-information.component.html',
  styleUrls: ['organization-developer-further-information.component.scss'],
})

export class OrganizationDeveloperFurtherInformationComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(UnsavedDataModalComponent, {static: false}) unSavedDataModalComponent: UnsavedDataModalComponent;

  developerInfo: DeveloperInformation;
  formGroup: UntypedFormGroup;
  organization: Organization;
  user: User;
  userIsOrgAdmin = false;

  constructor(private _organizationService: OrganizationService,
              private _developerInformationService: DeveloperInformationService,
              private _userService: UserService,
              private _translateService: TranslateService,
              private _eventService: EventService) {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      totalProjects: new UntypedFormControl(''),
      totalOperationalProjects: new UntypedFormControl(''),
      totalServedCustomers: new UntypedFormControl(''),
      installedCapacity: new UntypedFormControl(''),
      keyProjectDescription: new UntypedFormControl('')
    });
    this.organization = new Organization({});
    this.getOrganization();
    this.getUser();
  }

  private getOrganization() {
    this._organizationService.getMyOrganization()
    .subscribe(organization => {
      this.organization = new Organization(organization);
      this.getDeveloperInfo();
    });
  }

  private getDeveloperInfo() {
    this._developerInformationService.list(this.organization.id)
    .subscribe((developerInfo: DeveloperInformation) => {
      this.developerInfo = developerInfo;
      this.formGroup.controls['totalProjects'].setValue(developerInfo?.totalProjects, {emitEvent: false});
      this.formGroup.controls['totalOperationalProjects'].setValue(developerInfo?.totalOperationalProjects, {emitEvent: false});
      this.formGroup.controls['totalServedCustomers'].setValue(developerInfo?.totalServedCustomers, {emitEvent: false});
      this.formGroup.controls['installedCapacity'].setValue(developerInfo?.installedCapacity, {emitEvent: false});
      this.formGroup.controls['keyProjectDescription'].setValue(developerInfo?.keyProjectDescription, {emitEvent: false});
    });
  }

  private getUser() {
    this._userService.getCurrentUser().subscribe(user => {
      this.user = user;
      if (this.user.roles.filter(role => role.name === ROLE_TYPE.ORGANIZATION_ADMIN).length) {
        this.userIsOrgAdmin = true;
      }
    });
  }

  public saveChanges() {
    this._organizationService
    .save(this.organization)
    .subscribe(organization => {
      this.developerInfo.totalProjects = this.formGroup.controls['totalProjects'].value;
      this.developerInfo.totalOperationalProjects = this.formGroup.controls['totalOperationalProjects'].value;
      this.developerInfo.totalServedCustomers = this.formGroup.controls['totalServedCustomers'].value;
      this.developerInfo.installedCapacity = this.formGroup.controls['installedCapacity'].value;
      this.developerInfo.keyProjectDescription = this.formGroup?.controls['keyProjectDescription']?.value?.textControl;
      this._developerInformationService.update(this.organization.id, this.developerInfo)
      .subscribe(response => {
        this.formGroup.markAsPristine();
        this._eventService.success(this._translateService.instant('success-message.changes-saved'));
        this.unSavedDataModalComponent.hide();
      });
    });
  }

  // ComponentCanDeactivate requires
  hasUnsavedData() {
    return this.formGroup.dirty && this.formGroup.touched;
  }

  // ComponentCanDeactivate requires
  showUnsavedDataModal(nextUrl: string) {
    this.unSavedDataModalComponent.show(nextUrl);
    return false;
  }

  discardChanges() {
    this.formGroup.reset();
  }
}
