<div class="pnf-container">
  <div class="pnf-main">
    <h1>{{ 'page-not-found.title' | translate }}</h1>
    <p class="text-med">{{ 'page-not-found.sorry' | translate }}</p>

    <p class="text-med">{{ 'page-not-found.hang-on' | translate }}
      <span class="redirect-seconds"><span class="bracket">[</span> {{ redirectSeconds }} <span class="bracket">]</span></span>
      {{ 'page-not-found.seconds' | translate }}.
    </p>

    <button class="btn btn-primary" (click)="redirectUserHome()">{{ 'page-not-found.link' | translate }}</button>
  </div>
</div>