import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavItem } from '@main-navigation/shared/navi-item/nav-item.model';
import { OfftakerDetailDeveloperNav } from '@main-navigation/shared/navi-item/offtaker-detail/developer.constant';
import { QuoteDetailDeveloperNav } from '@main-navigation/shared/navi-item/quote-detail/developer.constant';
import { RfqDetailVendorNav } from '@main-navigation/shared/navi-item/quote-detail/vendor.constant';
import { OrganizationProfileNav } from '@main-navigation/shared/navi-item/role/organization-profile.constant';
import { PortfolioMenuService } from '@main-navigation/shared/portfolio-menu.service';
import { ProgramMenuService } from '@main-navigation/shared/program-menu.service';
import { ProjectMenuService } from '@main-navigation/shared/project-menu.service';
import { RoleCheckService } from '@main-navigation/shared/role-check.service';
import { SecondaryMenuCategory } from '@main-navigation/shared/secondary-menu-category.enum';
import { UrlCategory } from '@main-navigation/shared/url-category.model';
import { UrlCheckService } from '@main-navigation/shared/url-check.service';
import { SideNavigationService } from '@main-navigation/side-navigation/side-navigation.service';
import { OrganizationService } from '@organization-management/organization/organization.service';
import { Program } from '@program/shared/program.model';
import { OfftakerService } from '@project/offtakers/offtaker.service';
import { Project } from '@project/shared/project.model';
import { ProjectGroup } from '@shared/services/project-group.model';
import { ROLE_TYPE } from '@user/role-type';
import { UserService } from '@user/user.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { SideNavigationSecondaryService } from './side-navigation-secondary.service';

@Component({
  selector: 'oes-side-navigation-secondary',
  templateUrl: './side-navigation-secondary.component.html',
  styleUrls: ['./side-navigation-secondary.component.scss']
})
export class SideNavigationSecondaryComponent implements OnInit, OnDestroy {
  category: string;
  currentUrl: string;
  horizontalNav: boolean = true;
  location: string;
  menuItems: NavItem[] = [];
  selectedRole: ROLE_TYPE;
  subtitle: string;
  title: string;

  private lastCategory;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _offtakerService: OfftakerService,
              private _organizationService: OrganizationService,
              private _programMenuService: ProgramMenuService,
              private _projectMenuService: ProjectMenuService,
              private _portfolioMenuService: PortfolioMenuService,
              private _roleCheckService: RoleCheckService,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _location: Location,
              private _sideNavigationSecondaryService: SideNavigationSecondaryService,
              private _sideNavigationService: SideNavigationService,
              private _urlCheckService: UrlCheckService,
              private _userService: UserService) {
  }

  ngOnInit(): void {
    this.subscribeMenuItem();
    this.subscribeUserRole();
    this.subscribeProject();
    this.subscribeProgram();
    this.subscribePortfolio();
    this.subscribeOrganization();
    this.subscribeRouter();
    this.subscribeReload();
    this.subscribeCurrentUrl();
    this.findUrl();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    // initialize for the next login
    this._sideNavigationSecondaryService.navItems = [];
  }

  private findUrl() {
    this.location = this._location.path();
  }

  private subscribeMenuItem() {
    this._sideNavigationSecondaryService.navItems$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((menuItems: NavItem[]) => {
      this.menuItems = menuItems;
      if (this.currentUrl) {
        this.resolveInitialSecondaryMenuItem(this.currentUrl);
      }
    });
  }

  private subscribeUserRole() {
    this._userService.userRole$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((role: ROLE_TYPE) => {
      if (role && this.selectedRole !== role) {
        this.selectedRole = role;
        this.loadMenuItem();
      }
    });
  }

  private subscribeProject() {
    this._sideNavigationSecondaryService.project$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((project: Project) => {
      if (project) {
        this.title = project.name;
        this.subtitle = `${project.city ? project.city + ', ' : ''}${project.country?.name ? project.country.name : ''}`;
      }
    });
  }

  private subscribeProgram() {
    this._sideNavigationSecondaryService.program$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((program: Program) => {
      if (program) {
        this.title = program.name;
        this.subtitle = program.sponsor.name;
      }
    });
  }

  private subscribeCurrentUrl() {
    this._sideNavigationSecondaryService.currentUrl$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((url: string) => {
        this.currentUrl = url;
        if (this.menuItems) {
          this.resolveInitialSecondaryMenuItem(url);
        }
      });
  }

  private resolveInitialSecondaryMenuItem(url: string) {
    const baseUrl = url.split('?')[0];
    const cleanUrl = baseUrl.split('/oes/')[baseUrl.split('/oes/').length - 1];
    const currentNavItem = this.menuItems.find((item: NavItem) => {
      return cleanUrl.includes(item.url);
    });
    if (currentNavItem) {
      this._sideNavigationSecondaryService.currentNavItem = currentNavItem;
    }
  }

  private subscribePortfolio() {
    this._sideNavigationSecondaryService.portfolio$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((portfolio: ProjectGroup) => {
      if (portfolio) {
        this.title = portfolio.name;
        this.subtitle = `${portfolio.totalProjects} Projects`;
      }
    });
  }

  private subscribeReload() {
    this._sideNavigationSecondaryService.reload$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((reload: boolean) => {
      if (reload) {
        this.loadMenuItem();
      }
    });
  }

  private subscribeOrganization() {
    this._organizationService.getMyOrganization()
    .subscribe(organization => {
      this.title = organization.name;
    });
  }

  private subscribeRouter() {
    this._router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((next) => {
      if (next) {
        this.loadMenuItem();
        this.findUrl();
      }
    });
  }

  private loadMenuItem() {
    const result: UrlCategory = this._urlCheckService.detectUrl();
    const category: SecondaryMenuCategory = result?.category;

    if (this.selectedRole && result) {
      if (category === undefined) {
        this.menuItems = undefined;
        this._sideNavigationSecondaryService.open = false;
        return;
      }
      this._sideNavigationService.loadCompleted = false;
      const beforeCategory = this.category;
      this.category = category.toString().replace(/[\s&]+/g, '-').toLowerCase();

      switch (category) {
        case SecondaryMenuCategory.offtakers:
          if (this.selectedRole === ROLE_TYPE.DEVELOPER_USER) {
            this._sideNavigationSecondaryService.joinParentPath(result.url, OfftakerDetailDeveloperNav.item);
            this.title = this._offtakerService.offtaker.name;
          }
          this.horizontalNav = false;
          break;
        case SecondaryMenuCategory.projects:
          this._projectMenuService.checkProject(result.url);
          this.horizontalNav = true;
          if (beforeCategory !== this.category) {
            this.menuItems = [];
          }
          break;
          case SecondaryMenuCategory.program:
            this._programMenuService.selectMenuByProgram(this.selectedRole, result.url);
            this.horizontalNav = true;
          break;
        case SecondaryMenuCategory.portfolio:
          this._portfolioMenuService.selectMenuByProgram(this.selectedRole, result.url);
          this.horizontalNav = false;
          break;
        case SecondaryMenuCategory.ciPortfolio:
          this._portfolioMenuService.getCiPortfolioNavByRole(this.selectedRole, result.url);
          this.horizontalNav = false;
          break;
        case SecondaryMenuCategory.financeCiPortfolio:
          this._portfolioMenuService.getCiPortfolioNavByRole(this.selectedRole, result.url);
          this.horizontalNav = false;
          break;
        case SecondaryMenuCategory.financeMarketplace:
          this._portfolioMenuService.selectMenuByProgram(this.selectedRole, result.url);
          this.horizontalNav = false;
          break;
        case SecondaryMenuCategory.myQuotes:
          if (this.selectedRole === ROLE_TYPE.DEVELOPER_USER) {
            this._sideNavigationSecondaryService.joinParentPath(result.url, QuoteDetailDeveloperNav.item);
          }
          this.horizontalNav = false;
          break;
        case SecondaryMenuCategory.myRfqs:
          if (this.selectedRole === ROLE_TYPE.VENDOR_USER) {
            this._sideNavigationSecondaryService.joinParentPath(result.url, RfqDetailVendorNav.item);
          }
          this.horizontalNav = false;
          break;
        case SecondaryMenuCategory.organizationProfile:
          this._sideNavigationSecondaryService.navItems = this._roleCheckService.check(OrganizationProfileNav.item);
          this._sideNavigationSecondaryService.open = true;
          this.horizontalNav = false;
          break;
        default:
          this._sideNavigationSecondaryService.open = true;
          this.horizontalNav = false;
          break;
      }
      this.lastCategory = result.category;
      this._sideNavigationService.loadCompleted = true;
    }

  }
}
