import { Component } from '@angular/core';
import { TermsOfServiceAndPrivacyPolicyService } from '@global/terms-of-service-and-privacy-policy/terms-of-service-and-privacy-policy.service';
import { EulaType } from '@global/terms-of-service-and-privacy-policy/eula-type.enum';

@Component({
  selector: 'oes-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(private _TermsOfServiceAndPrivacyPolicyService: TermsOfServiceAndPrivacyPolicyService) {
  }

  // footer: auto update year
  public getYear(): string {
    return new Date().getFullYear().toString();
  }

  // footer
  public openTerms() {
    this._TermsOfServiceAndPrivacyPolicyService.open(EulaType.termAndPrivacy);
  }
}
