import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-amader',
  templateUrl: './amader.component.html',
  styleUrls: ['./amader.component.scss']
})
export class AmaderComponent {

  constructor() { }

}
