<div class="home" data-testid="sun-africa-home-container">
  <div class="body">
    <img class="sa-logo-text" src="/assets/images/sun-africa/sa-logo-text.svg" width="458px">
    <h4 class="sub-heading">{{ 'home-sun-africa.asset-management-platform' | translate }}</h4>
    <div class="sa-links">

        <div  class="sa-link ">
          <div class="icon-container" tooltip="{{'home-sun-africa.coming-soon-tt' | translate}}">
            <img src="/assets/images/sun-africa/system-om.svg" style="cursor:pointer;"/>
          </div>
          <span>{{ 'home-sun-africa.system-om' | translate }}</span>
        </div>

        <a [routerLink]="['../analytics/dashboards']" class="sa-link">
          <div class="icon-container">
            <img src="/assets/images/sun-africa/analytics.svg" />
          </div>
          <span>{{ 'home-sun-africa.analytics' | translate }}</span>
        </a>

        <a href="https://fernview.odysseyenergysolutions.com/" class="sa-link">
          <div class="icon-container">
            <img src="/assets/images/sun-africa/am.svg" />
          </div>
          <span>{{ 'home-sun-africa.asset-management' | translate }}</span>
        </a>

        <div class="sa-link dropdown-container"
             dropdown
             container="body">
          <div class="icon-container">
            <img src="/assets/images/sun-africa/meters.svg" />
          </div>
          <span dropdownToggle
                aria-hidden="true"
                class="d-flex">{{ 'home-sun-africa.meters' | translate }}
            <i class="fa fa-caret-right" aria-hidden="true"></i>
          </span>
          <ul *dropdownMenu
              role="menu"
              class="dropdown-menu dropdown-menu-right">
            <li role="menuitem">
              <a href="https://www.sparkmeter.cloud/login?next=%2F">{{ 'home-sun-africa.sparkmeter' | translate }}</a>
            </li>
            <li role="menuitem">
              <a href="https://fernview.odysseyenergysolutions.com/">{{ 'home-sun-africa.fernview' | translate }}</a>
            </li>
            <li role="menuitem">
              <a href="https://steama.co/">{{ 'home-sun-africa.steamaco' | translate }}</a>
            </li>
          </ul>
        </div>

    </div>

    <ng-container *ngIf="selectedRole === 'DEVELOPER_USER'">
      <div *ngIf="mapProjects" class="map-container">
        <div class="projects-count">
          <span class="projects-count-metric">{{ platformVis?.organizationMetrics?.projectsInPipelineCount ? platformVis?.organizationMetrics?.projectsInPipelineCount : '0' }}</span>
          {{ 'home-sun-africa.total-projects' | translate }}
          <div class="projects-tt"
               tooltip="{{'home-sun-africa.total-projects-tt' | translate}}"
               placement="right"
               containerClass="tool-tip">
            <i class="fa fa-question-circle primary"></i>
          </div>
        </div>
        <oes-project-map
          class="project-map"
          [projects]="mapProjects"
          [zoomControl]="true"
          [height]="'350px'"
          [mapTypeId]="'satellite'"
          [mapTypeControl]="false">
        </oes-project-map>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedRole === 'FINANCE_USER'">
      <div *ngIf="mapProjects" class="map-container">
        <div class="projects-count">
          <span class="projects-count-metric">{{ platformVis?.organizationMetrics?.sharedProjectsCount ? platformVis?.organizationMetrics?.sharedProjectsCount : '0' }}</span>
          {{ 'home-sun-africa.shared-projects' | translate }}
          <div class="projects-tt"
               tooltip="{{'home-sun-africa.shared-projects-tt' | translate}}"
               placement="right"
               containerClass="tool-tip">
            <i class="fa fa-question-circle primary"></i>
          </div>
        </div>
        <oes-project-map
          class="project-map"
          [projects]="mapProjects"
          [zoomControl]="true"
          [height]="'426px'"
          [mapTypeId]="'satellite'"
          [mapTypeControl]="false">
        </oes-project-map>
      </div>
    </ng-container>

    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
