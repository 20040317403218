<form [formGroup]="formGroup" class="row">
  <div class="col-12" *ngIf="organization != null && developerInfo!= null ">
    <div class="bg-white">
      <oes-notify
        class="warning mb-3"
        [type]="'warning'"
        [iconName]="'fa-bullhorn'"
        [message]="'organization-information.further-information.alert' | translate">
      </oes-notify>
      <div class="past-projects mt-3">
        <h3>{{'organization-information.past-projects.heading' | translate}}:</h3>
        <div class="row form-group mb-1">
          <label for="totalProjects" class="col-sm-5 col-form-label mt-0 bold">
            {{'organization-information.total-projects-to-date' | translate}}:
          </label>
          <div class="col-sm-5 col-md-2">
            <input type="number" class="form-control form-control--sm" id="totalProjects"
                   placeholder="0" name="totalProjects"
                   *ngIf="developerInfo != null && developerInfo != undefined"
                   formControlName="totalProjects"/>
          </div>
        </div>

        <div class="row form-group mb-1">
          <label for="totalOperationalProjects" class="col-sm-5 col-form-label mt-0 bold">
            {{'organization-information.number-of-projects' | translate}}:
          </label>
          <div class="col-sm-5 col-md-2">
            <input type="number" class="form-control form-control--sm" id="totalOperationalProjects"
                   placeholder="0" name="totalOperationalProjects"
                   *ngIf="developerInfo != null && developerInfo != undefined"
                   formControlName="totalOperationalProjects"/>
          </div>
        </div>

        <div class="row form-group mb-1">
          <label for="totalServedCustomers" class="col-sm-5 col-form-label mt-0 bold">
            {{'organization-information.number-of-customers' | translate}}:
          </label>
          <div class="col-sm-5 col-md-2">
            <input type="number" class="form-control form-control--sm" id="totalServedCustomers"
                   placeholder="0" name="totalServedCustomers"
                   *ngIf="developerInfo != null && developerInfo != undefined"
                   formControlName="totalServedCustomers"/>
          </div>
        </div>

        <div class="row form-group mb-1">
          <label for="installedCapacity" class="col-sm-5 col-form-label mt-0 bold">
            {{'organization-information.total-installed-capacity' | translate}}:
          </label>
          <div class="col-sm-5 col-md-2">
            <input type="number" class="form-control form-control--sm" id="installedCapacity"
                   placeholder="0" name="installedCapacity"
                   *ngIf="developerInfo != null && developerInfo != undefined"
                   formControlName="installedCapacity"/>
          </div>
        </div>

        <div class="form-group">
          <label for="keyProjectDescription">
            {{'organization-information.description-key-projects' | translate}}
          </label>
          <oes-quill
            [elementId]="'keyProjectDescription'"
            [isEditable]="true"
            [characterLimit]="3000"
            formControlName="keyProjectDescription"
            [placeholder]="'organization-information.description-key-projects' | translate">
          </oes-quill>
         </div>
      </div>
    </div>
    <button class="btn btn-primary pull-right" type="button"
            (click)="saveChanges()"
            [disabled]="!userIsOrgAdmin">{{'buttons.save' | translate}}
    </button>
  </div>
</form>

<oes-unsaved-data-modal
  (saveDataEmitter)="saveChanges()"
  (discardChangesEmitter)="discardChanges()">
</oes-unsaved-data-modal>
