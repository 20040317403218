import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-nea',
  templateUrl: './nea.component.html',
  styleUrls: ['./nea.component.scss']
})
export class NeaComponent {

  constructor() { }

}
