import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import { environment } from '@environments/environment';
import jwt_decode from 'jwt-decode';
import { ROLE_TYPE } from '@user/role-type';
import { UserService } from '@user/user.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { User } from '@user/user.model';
import { TranslateService } from '@ngx-translate/core';
import { AccountSetupService } from '@main-navigation/side-navigation/account-setup.service';

@Component({
  selector: 'oes-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss']
})
export class UserSettingComponent implements OnInit, OnDestroy {
  @Input() hideTooltip: () => void;
  @Input() navOpen: boolean = true;
  @Input() showTooltip: (e: MouseEvent, element: HTMLElement, text: string) => void;

  displayEmail: string = '';
  initials: string = '';
  logoutUri = ''; // used in html as a href=""
  name: string = '';
  ngUnsubscribe: Subject<any> = new Subject();
  orgAdmin = false;
  profileIsCompleted = false;
  sysAdmin = false;
  user: User;

  constructor(private _oauthService: OAuthService,
              private _translateService: TranslateService,
              private _accountSetupService: AccountSetupService,
              private _userService: UserService) {
  }

  ngOnInit() {
    this.logoutUri = this.generateLogoutUrl();
    this.setDisplayRoles();
    this._userService.getCurrentUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        this.user = user;
        this.displayEmail = user.emailAddress;
        this.checkAccountSetup();
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private checkAccountSetup() {
    this._accountSetupService.validateProfilesAndPasswordReset()
      .pipe(take(1))
      .subscribe((profileIsCompleted: boolean) => {
        this.profileIsCompleted = profileIsCompleted;
      });
  }

  private generateLogoutUrl() {
    const server = environment.keycloak.url + `/auth/realms/${environment.keycloak.realm}/protocol/openid-connect`;
    const idToken = this._oauthService.getIdToken();
    if (idToken) {
      const decodedToken: any = jwt_decode(idToken);
      this.name = decodedToken.name ? decodedToken.name : decodedToken.preferred_username;
      this.initials = this.parseInitials(decodedToken);
      const redirectUrl = encodeURIComponent(window.location.origin);
      return `${server}/logout?id_token_hint=${idToken}&redirect_uri=${redirectUrl}`;
    }
  }

  private parseInitials(token): string {
    const first = token.given_name;
    const last = token.family_name;
    const name = token.name;

    if (first?.length && last?.length) {
      return first[0] + last[0];
    }
    if (name && name.split(' ').length >= 2) {
      const splitName = name.split(' ');
      return splitName[0][0] + splitName[splitName.length - 1][0];
    }
    return '';
  }

  private setDisplayRoles() {
    if (this._userService.hasRole(ROLE_TYPE.SYSTEM_ADMIN)) {
      this.sysAdmin = true;
    }
    if (this._userService.hasRole(ROLE_TYPE.ORGANIZATION_ADMIN)) {
      this.orgAdmin = true;
    }
  }

  public logout() {
    this._oauthService.logOut();
  }
}
