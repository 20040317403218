import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { User } from '../user.model';
import { EventService } from '@shared/event.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ResetRequestService } from './reset-request.service';

@Component({
  selector: 'oes-user-profile',
  templateUrl: 'user-profile.component.html',
  styleUrls: ['user-profile.component.scss']
})

export class UserProfileComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public user: User;

  constructor(private _userService: UserService,
              private _resetRequestService: ResetRequestService,
              private _eventService: EventService,
              private _translateService: TranslateService) {
  }

  ngOnInit() {
    this.createForm();
    this.retrieveUser();
  }

  private createForm() {
    this.formGroup = new UntypedFormGroup({
      organizationName: new UntypedFormControl({value: '', disabled: true}),
      userName: new UntypedFormControl({value: '', disabled: true}),
      email: new UntypedFormControl(''),
      phone: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      firstName: new UntypedFormControl('', Validators.required)
    });
  }

  private retrieveUser() {
    // id is required
    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe(user => {
      this.user = user;
      this.formGroup.controls['organizationName'].setValue(this.user?.organization?.name, {eventEmit: false});
      this.formGroup.controls['userName'].setValue(this.user?.username, {eventEmit: false});
      this.formGroup.controls['email'].setValue(this.user?.emailAddress, {eventEmit: false});
      this.formGroup.controls['phone'].setValue(this.user?.primaryPhone, {eventEmit: false});
      this.formGroup.controls['lastName'].setValue(this.user?.lastName, {eventEmit: false});
      this.formGroup.controls['firstName'].setValue(this.user?.firstName, {eventEmit: false});
    });
  }

  public save() {
    this.user.emailAddress = this.formGroup.controls['email'].value;
    this.user.primaryPhone = this.formGroup.controls['phone'].value;
    this.user.lastName = this.formGroup.controls['lastName'].value;
    this.user.firstName = this.formGroup.controls['firstName'].value;
    this._userService.createUpdate(this.user)
    .pipe(take(1))
    .subscribe(user => {
      this._eventService.success(this._translateService.instant('success-message.profile-updated'));
    });
  }

  public cancel() {
  }

  public updatePassword() {
    this._resetRequestService.resetPassword()
    .pipe(take(1))
    .subscribe((user: User) => {
      this._eventService.success(this._translateService.instant('reset-password.update-success'));
      this.formGroup.reset();
    });
  }
}
