import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-omdf',
  templateUrl: './omdf.component.html',
  styleUrls: ['./omdf.component.scss']
})
export class OmdfComponent {

  constructor() { }

}
