<div class="home" data-testid="gbe-cotedivoire-home-container">
  <div class="body">
    <div class="header">
      <h2 class="my-2">{{ 'home-gbe-cotedivoire.header' | translate }}</h2>
      <h5>{{ 'home-gbe-cotedivoire.sub-header' | translate }}</h5>
    </div>
    <div class="image-display">
      <div class="image-container"></div>
      <div class="image-container"></div>
      <div class="image-container"></div>
    </div>
    <div class="bg-container">
      <div class="logos">
        <img src="/assets/images/gbe-cotedivoire/gbe-cotedivoire-logo.png" width="260px" height="120px"/>
        <img src="/assets/images/gbe-cotedivoire/german-cooperation.png" width="230px"/>
      </div>
      <button class="btn btn-primary my-4" [routerLink]="['../finance/programs']">
        {{ 'buttons.submit-claims' | translate }}
      </button>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
