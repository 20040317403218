import { Component, OnDestroy, OnInit } from '@angular/core';
import { Project } from '@project/shared/project.model';
import { ProjectService } from '@project/shared/project.service';
import { SimpleProject } from '@project/shared/simple-project.model';
import { ROLE_TYPE } from '@user/role-type';
import { UserService } from '@user/user.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OdysseyPlatform } from '../../dashboard/odyssey-platform';
import { OdysseyPlatformService } from '../../dashboard/odyssey-platform/odyssey-platform.service';

@Component({
  selector: 'oes-theme-sun-africa',
  templateUrl: './sun-africa.component.html',
  styleUrls: ['./sun-africa.component.scss']
})
export class SunAfricaComponent implements OnInit, OnDestroy {
  mapProjects: SimpleProject[];
  platformVis: OdysseyPlatform;
  selectedRole: ROLE_TYPE;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _userService: UserService,
              private _odysseyPlatformService: OdysseyPlatformService,
              private _projectService: ProjectService) { }

  ngOnInit(): void {
    this.setSelectedRole();
    this.getOdysseyPlatform();
  }

  private setSelectedRole() {
    this._userService.userRole$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((role: ROLE_TYPE) => {
      if (role) {
        this.selectedRole = role;

        if (this.selectedRole === ROLE_TYPE.DEVELOPER_USER) {
          this.getMyProjects();
        }

        if (this.selectedRole === ROLE_TYPE.FINANCE_USER) {
          this.getSharedProjects();
        }
      }
    });
  }

  private getOdysseyPlatform() {
    this._odysseyPlatformService.getData()
    .pipe(take(1))
    .subscribe((platformVis: OdysseyPlatform) => {
      this.platformVis = platformVis;
    });
  }

  private getMyProjects() {
    this._projectService.myList()
    .pipe(take(1))
    .subscribe((projects: SimpleProject[]) => {
      this.mapProjects = projects;
    });
  }

  private getSharedProjects() {
    this._projectService.sharedList()
    .pipe(take(1))
    .subscribe((projects: SimpleProject[]) => {
      this.mapProjects = projects;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
