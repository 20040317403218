import { AbstractControl } from '@angular/forms';

export function SelectCountryValidator(control: AbstractControl) {
  // selected "Select Countries"
  if (control.value === false) {
    const count = control.parent.controls['countries'].value?.length;
    if (!count || count === 0) {
      // invalid
      return { selectAtLeastOne: true };
    }
  }
  // valid
  return null;
}
