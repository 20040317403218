import { Injectable } from '@angular/core';

/**
 * Interceptor determine whether make a cache or not based on this uri list
 */
@Injectable({
  providedIn: 'root'
})
export class CacheRegistrationService {
  private services = [];

  public addedToCache(serviceUri: string) {
    return this.services.indexOf(serviceUri) > -1;
  }

  public addToCache(serviceUri: string) {
    if (!this.addedToCache(serviceUri)) {
      this.services.push(serviceUri);
    }
  }

  public removeFromCache(serviceUri: string) {
    const index = this.services.indexOf(serviceUri);
    if (index > -1) {
      this.services.splice(index, 1);
    }
  }
}
