<form [formGroup]="formGroup" class="row">
  <div class="col-12">
    <div class="table-wrap">
      <div class="row">
        <div class="col-11">
          <h3>{{'organization-information.investor.header' | translate}}</h3>
          <p>{{'organization-information.investor.description' | translate}}</p>
        </div>
      </div>
      <div>
        <!-- Language Spoken -->
        <label for="languages-spoken" class="p large required">
          {{'organization-information.investor.languages-spoken' | translate}}
        </label>
        <input
          type="text"
          class="form-control"
          placeholder="List Languages"
          id="languages-spoken"
          name="languages-spoken"
          formControlName="languagesSpoken"/>
        <oes-form-error-message
          [showInvalid]="formGroup?.controls?.languagesSpoken?.invalid">
        </oes-form-error-message>
      </div>
      <div class="mt-30">
        <!-- Overview -->
        <h4 class="mb-2 required">{{'organization-information.investor.investor-overview.heading' | translate}}</h4>
        <p class="mb-2">{{'organization-information.investor.company-mission' | translate}}</p>
        <oes-quill
          *ngIf="organization"
          formControlName="companyOverview"
          [initialValue]="organization?.companyOverview"
          [placeholder]="'organization-information.investor.investor-overview.overview' | translate"
          [characterLimit]="3000"
          [required]="true">
        </oes-quill>
        <oes-form-error-message
          [showInvalid]="formGroup?.controls?.companyOverview?.invalid"
          [text]="'organization-information.investor.investor-overview.overview-err-msg' | translate">
        </oes-form-error-message>
      </div>
      <div class="mt-30">
        <!-- Experience -->
        <h4 class="mb-2 required">{{'organization-information.investor.sector-experience.header' | translate}}</h4>
        <p class="mb-2">{{'organization-information.investor.sector-experience.description' | translate}}</p>
        <oes-quill
          *ngIf="investorInfo"
          formControlName="sectorExperience"
          [initialValue]="investorInfo?.sectorExperience"
          [placeholder]="'organization-information.investor.sector-experience.header' | translate"
          [characterLimit]="3000"
          [required]="true">
        </oes-quill>
        <div *ngIf="investorInfo">
          <oes-form-error-message
            [showInvalid]="formGroup?.controls?.sectorExperience?.invalid"
            [text]="'organization-information.investor.sector-experience.err-msg' | translate">
          </oes-form-error-message>
        </div>
      </div>
      <div class="mt-30">
        <!-- Country -->
        <h4 class="required">{{'organization-information.investor.countries-invest.header' | translate}}</h4>
        <p>{{'organization-information.investor.countries-invest.err-msg' | translate}}</p>
        <div class="ml-2">
          <div class="form-group" *ngIf="investorInfo">
            <label for="anyCountry" class="input-control--sm input-control--radio mb-2 col-2">
              {{'organization-information.investor.countries-invest.any-country' | translate}}
              <input
                type="radio"
                name="allCountries"
                id="anyCountry"
                formControlName="allCountries"
                [value]="true"
                (click)="clearCountries()">
              <div class="input-control__indicator--sm"></div>
            </label>
            <label class="input-control--sm input-control--radio mb-3 col-2">
              {{'organization-information.investor.countries-invest.select-countries' | translate}}
              <input
                type="radio"
                name="allCountries"
                formControlName="allCountries"
                [value]="false">
              <div class="input-control__indicator--sm"></div>
            </label>
            <!-- selected "Select Country" -->
            <ng-container *ngIf="formGroup?.controls?.allCountries?.value === false">
              <oes-country-multiselect
                [currentCountries]="investorInfo.countries"
                (countriesChanged)="countriesChanged($event)">
              </oes-country-multiselect>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="mt-30">
        <!-- Capital -->
        <h4 class="required">{{'organization-information.investor.types-of-capital.header' | translate}}</h4>
        <div class="ml-2">
          <div class="form-group">
            <div formGroupName="capitalOfferingGroup">
              <label class="input-control--sm input-control--checkbox">
                {{'organization-information.investor.types-of-capital.grant' | translate}}
                <input
                  type="checkbox"
                  formControlName="grantCapital"
                  id="grantCapital">
                <div class="input-control__indicator--sm"></div>
              </label>
              <label class="input-control--sm input-control--checkbox">
                {{'organization-information.investor.types-of-capital.project-equity' | translate}}
                <input
                  type="checkbox"
                  formControlName="projectEquityCapital"
                  id="projectEquityCapital">
                <div class="input-control__indicator--sm"></div>
              </label>
              <label class="input-control--sm input-control--checkbox">
                {{'organization-information.investor.types-of-capital.corporate-equity' | translate}}
                <input
                  type="checkbox"
                  formControlName="corporateEquityCapital"
                  id="corporateEquityCapital">
                <div class="input-control__indicator--sm"></div>
              </label>
              <label class="input-control--sm input-control--checkbox">
                {{'organization-information.investor.types-of-capital.debt' | translate}}
                <input
                  type="checkbox"
                  formControlName="debtCapital"
                  id="debtCapital">
                <div class="input-control__indicator--sm"></div>
              </label>
              <label class="input-control--sm input-control--checkbox">
                {{'organization-information.investor.types-of-capital.concessionary-debt' | translate}}
                <input
                  type="checkbox"
                  formControlName="concessionaryDebtCapital"
                  id="concessionaryDebtCapital">
                <div class="input-control__indicator--sm"></div>
              </label>
            </div>
          </div>
          <oes-form-error-message
            [showInvalid]="formGroup?.controls?.capitalOfferingGroup?.errors?.checkAtLeastOne"
            [text]="'organization-information.investor.types-of-capital.err-msg' | translate">
          </oes-form-error-message>
        </div>
      </div>
      <div class="mt-30">
        <!-- Activity -->
        <h4 class="required">{{'organization-information.investor.activities.header' | translate}}</h4>
        <div class="ml-2">
          <div class="form-group">
            <div formGroupName="activitiesGroup">
              <label class="input-control--sm input-control--checkbox">
                {{'organization-information.investor.activities.finance' | translate}}
                <input
                  type="checkbox"
                  formControlName="financeActivities"
                  id="financeActivities">
                <div class="input-control__indicator--sm"></div>
              </label>
              <label class="input-control--sm input-control--checkbox">
                {{'organization-information.investor.activities.project-development' | translate}}
                <input
                  type="checkbox"
                  formControlName="projectDevelopmentActivities"
                  id="projectDevelopmentActivities">
                <div class="input-control__indicator--sm"></div>
              </label>
              <label class="input-control--sm input-control--checkbox">
                {{'organization-information.investor.activities.supply' | translate}}
                <input
                  type="checkbox"
                  formControlName="supplyActivities"
                  id="supplyActivities">
                <div class="input-control__indicator--sm"></div>
              </label>
              <label class="input-control--sm input-control--checkbox">
                {{'organization-information.investor.activities.epc' | translate}}
                <input
                  type="checkbox"
                  formControlName="epcActivities"
                  id="epcActivities">
                <div class="input-control__indicator--sm"></div>
              </label>
            </div>
          </div>
          <oes-form-error-message
          [showInvalid]="formGroup?.controls?.activitiesGroup?.errors?.checkAtLeastOne"
          [text]="'organization-information.investor.activities.err-msg' | translate">
          </oes-form-error-message>
        </div>
      </div>
      <div class="mt-30">
        <!-- Investment -->
        <h4 class="required">{{'organization-information.investor.investment.header' | translate}}</h4>
        <p class="mb-0">{{'organization-information.investor.investment.description' | translate}}</p>
        <div class="row mt-2">
          <div class="col-12">
            <oes-quill
              *ngIf="investorInfo"
              formControlName="investmentObjectives"
              [initialValue]="investorInfo?.investmentObjectives"
              [placeholder]="'organization-information.investor.investment.placeholder' | translate"
              [characterLimit]="3000"
              [required]="true">
            </oes-quill>
            <div *ngIf="investorInfo">
              <oes-form-error-message
                [showInvalid]="formGroup?.controls?.investmentObjectives?.invalid"
                [text]="'organization-information.investor.investment.err-msg' | translate">
              </oes-form-error-message>
            </div>
          </div>
        </div>

        <!-- Sizes -->
        <table>
          <tbody>
            <tr *ngIf="investorInfo">
              <td style="width: 320px;">
                <label for="minimumProjectSize" class="bold">
                  {{'organization-information.investor.investment.minimum-investment-size' | translate}}:
                </label>
              </td>
              <td style="width: 230px;" class="pr-4">
                <div class="input-group input-group--sm">
                  <div class="input-group-addon">$</div>
                  <input
                    type="number"
                    class="form-control form-control--sm"
                    id="minimumProjectSize"
                    name="minimumProjectSize"
                    placeholder="{{'organization-information.investor.investment.minimum-investment-size-placeholder' | translate}}"
                    formControlName="minimumProjectSize"/>
                </div>
              </td>
              <td>
                <label for="noMinimumProjectSize" class="input-control--sm input-control--checkbox">
                  {{'organization-information.investor.investment.no-minimum' | translate}}
                  <input
                    type="checkbox"
                    name="noMinimumProjectSize"
                    id="noMinimumProjectSize"
                    formControlName="noMinimumProjectSize"
                    [value]="investorInfo.noMinimumProjectSize">
                  <div class="input-control__indicator--sm"></div>
                </label>
              </td>
            </tr>
            <tr *ngIf="investorInfo">
              <td>
                <label for="maximumProjectSize" class="bold">
                  {{'organization-information.investor.investment.maximum-investment-size' | translate}}:
                </label>
              </td>
              <td class="pr-4">
                <div class="input-group input-group--sm">
                  <div class="input-group-addon">$</div>
                    <input
                      type="number"
                      class="form-control form-control--sm"
                      id="maximumProjectSize" name="maximumProjectSize"
                      placeholder="{{'organization-information.investor.investment.maximum-investment-size-placeholder' | translate}}"
                      formControlName="maximumProjectSize"/>
                </div>
              </td>
              <td>
                <label for="noMaximumProjectSize" class="input-control--sm input-control--checkbox">
                  {{'organization-information.investor.investment.no-maximum' | translate}}
                  <input
                    type="checkbox"
                    name="noMaximumProjectSize"
                    id="noMaximumProjectSize"
                    formControlName="noMaximumProjectSize"
                    [value]="investorInfo.noMaximumProjectSize"/>
                  <div class="input-control__indicator--sm"></div>
                </label>
              </td>
            </tr>
            <tr *ngIf="investorInfo">
              <td>
                <label for="preferredSize" class="bold">
                  {{'organization-information.investor.investment.preferred-size' | translate}}:
                </label>
              </td>
              <td class="pr-4">
                <div class="input-group input-group--sm">
                  <div class="input-group-addon">$</div>
                  <input
                    type="number"
                    class="form-control form-control--sm"
                    id="preferredSize"
                    name="preferredSize"
                    placeholder="{{'organization-information.investor.investment.preferred-size-placeholder' | translate}}"
                    formControlName="preferredSize"/>
                </div>
              </td>
              <td>
                <label for="noPreferredSize" class="input-control--sm input-control--checkbox">
                  {{'organization-information.investor.investment.no-preferred-size' | translate}}
                  <input
                    type="checkbox"
                    name="noPreferredSize"
                    id="noPreferredSize"
                    formControlName="noPreferredSize"
                    [value]="investorInfo.noPreferredSize"/>
                  <div class="input-control__indicator--sm"></div>
                </label>
              </td>
            </tr>
            <tr *ngIf="investorInfo">
              <td>
                <label for="preferredTerm" class="bold">
                  {{'organization-information.investor.investment.preferred-term' | translate}}:
                </label>
              </td>
              <td class="pr-4">
                <div class="input-group input-group--sm">
                  <input
                    type="number"
                    class="form-control form-control--sm"
                    id="preferredTerm"
                    name="preferredTerm"
                    placeholder="{{'organization-information.investor.investment.preferred-term-placeholder' | translate}}"
                    formControlName="preferredTerm"/>
                  <div class="input-group-addon">years</div>
                </div>
              </td>
              <td>
                <label for="noPreferredTerm" class="input-control--sm input-control--checkbox">
                  {{'organization-information.investor.investment.no-preferred-term' | translate}}
                  <input
                    type="checkbox"
                    name="noPreferredTerm"
                    id="noPreferredTerm"
                    formControlName="noPreferredTerm"
                    [value]="investorInfo.noPreferredTerm"/>
                  <div class="input-control__indicator--sm"></div>
                </label>
              </td>
            </tr>
            <tr>
              <td>
                <label class="bold">{{'organization-information.investor.investment.invested-in-microgrids' | translate}}</label>
              </td>
              <td class="pr-4">
                <div class="row">
                  <div class="col pr-0">
                    <label for="yes" class="input-control--sm input-control--radio">
                      {{'organization-information.investor.investment.yes' | translate}}
                      <input
                        type="radio"
                        name="investedInMicroGrids"
                        id="yes"
                        *ngIf="investorInfo"
                        formControlName="investedInMicroGrids"
                        [value]="true"/>
                      <div class="input-control__indicator--sm"></div>
                    </label>
                  </div>
                  <div class="col pl-0">
                    <label for="no" class="input-control--sm input-control--radio">
                      {{'organization-information.investor.investment.no' | translate}}
                      <input
                        type="radio"
                        name="investedInMicroGrids"
                        id="no"
                        *ngIf="investorInfo"
                        formControlName="investedInMicroGrids"
                        [value]="false"/>
                      <div class="input-control__indicator--sm"></div>
                    </label>
                  </div>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <button *ngIf="showSaveCancelButtons"
        class="btn btn-primary pull-right"
        type="submit"
        (click)="save()"
        [disabled]="!formGroup.valid">
        {{'buttons.save' | translate}}
      </button>
    </div>
  </div>
</form>


<oes-unsaved-data-modal
  (saveDataEmitter)="save()"
  (discardChangesEmitter)="discardChanges()">
</oes-unsaved-data-modal>
