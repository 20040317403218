import { FinanceInvestorNav } from '../finance-investor.constant';
import { NavItem } from '../nav-item.model';
import { FinanceProjectNav } from '../finance-project.constant';
import { FinanceAnalyticsNav } from './finance-analytics.constant';
import { ProcurementNav } from '@main-navigation/shared/navi-item/procurement.constant';
import { OperateNav } from '@main-navigation/shared/navi-item/role/operate-nav.constant';

export class InvestorNav {
  public static item: NavItem[] = [
    FinanceProjectNav.item,
    FinanceInvestorNav.item,
    ProcurementNav.item,
    OperateNav.item,
    FinanceAnalyticsNav.item
  ];
}
