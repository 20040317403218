import { MainMenuCategory } from '../main-menu-category.enum';

export class ProcurementNav {
  public static item = {
    title: 'main-navigation.procurement',
    testId: 'procurement-nav-link',
    icon: '<img src="./assets/icons/line-icons/nav/colors/package.svg" width="24px" height="24px">',
    themedIcon: {
      avorenewables: '<img src="./assets/images/avorenewables/icons/package.svg" width="24px" height="24px">'
    },
    bold: true,
    type: MainMenuCategory.build,
    children: [
      {
        title: 'main-navigation.pace-home',
        externalUrl: 'https://procure.odyssey.energy/',
        testId: 'pace-home-nav-link',
      },
      // Removed for now
      // {
      //   title: 'main-navigation.vendor-profiles',
      //   url: './procurement/vendor-profiles',
      //   testId: 'vendor-profiles-nav-link',
      // }
    ]
  };
}
