import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-funae',
  templateUrl: './funae.component.html',
  styleUrls: ['./funae.component.scss']
})
export class FunaeComponent {

  constructor() { }

}
