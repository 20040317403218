import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-dream-ethiopia',
  templateUrl: './dream-ethiopia.component.html',
  styleUrls: ['./dream-ethiopia.component.scss']
})
export class DreamEthiopiaComponent {

  constructor() { }

}
