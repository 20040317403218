import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-cpi',
  templateUrl: './cpi.component.html',
  styleUrls: ['./cpi.component.scss']
})
export class CpiComponent {

  constructor() { }

}
