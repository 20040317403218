import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() {
  }

  public logError(message: string, stack: string) {
    // Inspectlet will log
    if (message && message !== '') {
      // output as error in dev
      if (environment.name === 'dev') {
        console.error('Error: ' + message);
      } else {
        console.log('Error: ' + message);
      }
    }
    if (stack && stack !== '') {
      // output as error in dev
      if (environment.name === 'dev') {
        console.trace('Stack: ' + stack);
      } else {
        console.trace('Stack: ' + stack);
      }
    }
  }
}
