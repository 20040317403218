import { Component, OnInit } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Component({
  selector: 'oes-internet-status',
  templateUrl: './internet-status.component.html',
  styleUrls: ['./internet-status.component.scss']
})
export class InternetStatusComponent {
  status = true;

  constructor() {
    merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    ).subscribe(status  => {
      this.status = status;
    });
  }

}
