import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-amap',
  templateUrl: './amap.component.html',
  styleUrls: ['./amap.component.scss']
})
export class AmapComponent {

  constructor() { }

}
