import { Component, Input } from '@angular/core';
import { ROLE_TYPE } from '@user/role-type';

@Component({
  selector: 'oes-selected-role',
  templateUrl: './selected-role.component.html',
  styleUrls: ['./selected-role.component.scss']
})
export class SelectedRoleComponent {
  @Input() role: string;
  @Input() selectedRole: ROLE_TYPE;
}
