<div class="home" data-testid="rrealiberia-home-container">
  <div class="body">
    <div class="header">
      <img src="/assets/images/rrealiberia/rrea-logo.jpg" width="250px">
    </div>
    <div class="bg-container">
      <div class="logos mb-2">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/liberia-seal.jpg" width="150px" class="mr-5">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/wb-logo.png" width="310px">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/undp-logo.png" width="80px" class="ml-5">
      </div>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>

