<div class="home">
  <div class="header">
    <div class="rearef-logo-container">
      <img src="/assets/images/rea/rearef-logos/eu-flag.jpg" class="rearef-big-logos">
      <img src="/assets/images/rea/rearef-logos/coat-of-arms.png" class="rearef-big-logos">
      <img src="/assets/images/rea/rearef-logos/german-cooperation.png" class="rearef-big-logos">
    </div>
    <div class="rearef-logo-container">
      <img src="/assets/images/rea/rea-logo-payoff-lg.jpg" class="rearef-big-logos">
      <img src="/assets/images/rea/rearef-logos/power-africa.jpg" height="120px">
      <img src="/assets/images/rea/rearef-logos/usaid-logo.png" class="rearef-big-logos rearef-wide">
    </div>
  </div>
  <div class="body">
    <div class="rea-guide-container">
      <div>Official Platform of the</div>
      <h3>Interconnected Mini-Grid Acceleration Scheme</h3>

      <div class="implemented">Implemented by:</div>
      <img src="/assets/images/rea/rearef-logos/giz-logo.jpg" width="175px">
    </div>

    <div class="participation-notes-container">
      <table>
        <tr>
          <th>Submission Deadline: </th>
          <td>August 14, 2019 (23:59 WAT)</td>
        </tr>
      </table>
      <span>
        Questions? Email
        <a href="mailto: support@odysseyenergysolutions.com">support&#64;odysseyenergysolutions.com</a>
        or use the Odyssey chat box.
      </span>

      <div class="instructions">
        <span class="deadline">
          Instructions: Click on the <a class="participation-guide" [routerLink]="['../finance/programs']">Programs</a>
          tab to access all program documents.</span>
        <div><a class="participation-guide" href="https://help.odysseyenergysolutions.com/portal/kb/articles/rea-imas-overview" target="_blank">Participation Guide </a>(Required)</div>
        <a class="participation-guide mb-3" href="https://forms.gle/aKm82dN39jyixF5h9" target="_blank">Register For Our Weekly Live Support Sessions</a>
      </div>
    </div>
  </div>
</div>
