import { Component, OnDestroy } from '@angular/core';
import { ThemesService } from '@shared/themes.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Theme } from '@shared/theme.enum.';
import { KlaviyoService } from '@shared/services/klaviyo.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'oes-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})

export class HomeComponent implements OnDestroy {
  public currentTheme = Theme.ODYSSEY;
  public theme = Theme;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _themesService: ThemesService,
              private _klaviyoService: KlaviyoService) {
    this._themesService.themeObservable
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((theme: Theme) => {
      this.currentTheme = theme;
    });
    if (environment.name === 'prod' || environment.name === 'uat') {
      this._klaviyoService.initializeCurrentUser();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
