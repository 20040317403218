import {Component, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {Organization} from '@shared/models/organization/organization.model';
import {OrganizationService} from '../organization.service';
import {EventService} from '@shared/event.service';
import {OrganizationImage} from '../organization-image.model';
import {FileUploader} from 'ng2-file-upload';
import {User} from '@user/user.model';
import {ROLE_TYPE} from '@user/role-type';
import {UserService} from '@user/user.service';
import {OrganizationLogoImageService} from '../organization-logo-image.service';
import {UnsavedDataModalComponent} from '@shared/components/unsaved-data-modal/unsaved-data-modal.component';
import {NgForm} from '@angular/forms';
import { ComponentCanDeactivate } from '@organization-management/organization/shared/guard/component-can-deactivate.interface';
import { environment } from '@environments/environment';
import { take } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-organization-profile',
  templateUrl: 'organization-profile.component.html',
  styleUrls: ['organization-profile.component.scss'],
})

export class OrganizationProfileComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(UnsavedDataModalComponent, {static: false}) unSavedDataModalComponent: UnsavedDataModalComponent;
  @ViewChild('organizationProfileForm', {static: false}) organizationProfileForm: NgForm;
  @Input() showSaveCancelButtons = true;
  @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
  organization: Organization;
  pictures: OrganizationImage[] = [];
  logoImage: OrganizationImage;
  id: string;
  user: User;
  userIsOrgAdmin = false;
  cdnUri: string;
  logoImageId: string;

  public logoUploader: FileUploader = new FileUploader({
    url: '',
    allowedMimeType: ['image/png', 'image/gif', 'image/jpeg']
  });

  constructor(private _organizationService: OrganizationService,
              private _organizationLogoImageService: OrganizationLogoImageService,
              private _userService: UserService,
              private _translateService: TranslateService,
              private _oauthService: OAuthService,
              private _eventService: EventService) {
  }

  getOrganization() {
    this._organizationService.getMyOrganization()
    .subscribe(organization => {
      this.organization = new Organization(organization);
      this.getLogo(this.organization.id);
    });
  }

  prepareUpload(currentLogoId?: any) {
    if (currentLogoId) {
      this._organizationLogoImageService.removeImage(this.organization.id, currentLogoId)
      .pipe(take(1))
      .subscribe(() => {
        this.uploadLogo();
      });
    } else {
      this.uploadLogo();
    }
  }

  private uploadLogo() {
    const uploadUrl = this._organizationLogoImageService.uploadUri(this.organization.id);
    const theAuthHeader = 'Bearer ' + this._oauthService.getAccessToken();
    this.logoUploader.setOptions({url: uploadUrl, removeAfterUpload: true, authToken: theAuthHeader});
    this.logoUploader.uploadAll();
    this.logoUploader.onCompleteItem = (item, response: any) => {
      if (response) {
        response = JSON.parse(response);
        if (response.message) {
          this._eventService.error(response.message);
        } else {
          const image = new OrganizationImage(response);
          this._organizationLogoImageService.createUpdate(this.organization.id, image)
          .pipe(take(1))
          .subscribe(() => {
            this.organizationProfileForm.form.markAsPristine();
            this.getLogo(this.organization.id);
          });
        }
      } else {
        this._eventService.error(this._translateService.instant('error-message.upload-failed'));
      }
    };
  }

  getLogo(organizationId: string) {
    this._organizationLogoImageService.getImage(organizationId)
    .pipe(take(1))
    .subscribe((responses: OrganizationImage[]) => {
      if (responses?.length > 0) {
        this.logoImage = responses[0];
        this.logoImageId = this.logoImage.id;
      }
    });
  }

  getUser() {
    this._userService.getCurrentUser().subscribe(user => {
      this.user = new User(user);
      if (this.user.roles.filter(role => role.name === ROLE_TYPE.ORGANIZATION_ADMIN).length) {
        this.userIsOrgAdmin = true;
      }
    });
  }

  saveChanges() {
    if (this.organizationProfileForm.valid) {
      this._organizationService
        .save(this.organization)
        .subscribe(() => {
          this.organizationProfileForm.form.markAsPristine();
          this._eventService.success(this._translateService.instant('success-message.changes-saved'));
          this.onSaveComplete();
          this.unSavedDataModalComponent.hide();
        });
    } else {
      this._eventService.error(this._translateService.instant('error-message.form-errors'));
    }
  }

  onSaveComplete() {
    if (this.logoImageId) {
      this.saved.emit(true);
    }
  }

  // ComponentCanDeactivate requires
  hasUnsavedData(): boolean {
    return (this.organizationProfileForm.dirty || this.logoUploader.queue.length > 0);
  }

  // ComponentCanDeactivate requires
  showUnsavedDataModal(nextUrl: string) {
    this.unSavedDataModalComponent.show(nextUrl);
    return false;
  }

  discardChanges() {
    this.organizationProfileForm.form.markAsPristine();
  }

  save() {
    this.saveChanges();
    if (this.logoUploader.queue.length) {
      if (this.logoImage) {
        this.prepareUpload(this.logoImage.id);
      } else {
        this.prepareUpload();
      }
    }
  }

  ngOnInit() {
    this.cdnUri = environment.organizationCdnUri;
    this.organization = new Organization({});
    this.getOrganization();
    this.getUser();
  }

}
