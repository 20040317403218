import { MainMenuCategory } from '../main-menu-category.enum';

export class ProjectNav {
  public static item = {
    title: 'main-navigation.projects',
    icon: '<img src="./assets/icons/line-icons/nav/colors/compass.svg" width="24px" height="24px">',
    themedIcon: {
      avorenewables: '<img src="./assets/images/avorenewables/icons/compass.svg" width="24px" height="24px">'
    },
    testId: 'projects-nav-link',
    bold: true,
    type: MainMenuCategory.plan,
    children: [
      {
        title: 'main-navigation.my-projects',
        url: '/oes/projects/my-projects',
        queryParams: {
          type: 'list'
        },
        testId: 'my-projects-nav-link',
      },
      {
        title: 'main-navigation.shared-projects',
        url: '/oes/projects/shared-projects',
        queryParams: {
          type: 'list'
        },
        testId: 'shared-projects-nav-link',
      },
      {
        title: 'main-navigation.installers',
        url: '/oes/projects/installers',
        testId: 'installers-nav-link'
      },
      {
        title: 'main-navigation.offtakers',
        url: '/oes/projects/offtakers',
        testId: 'offtakers-nav-link'
      }
    ]
  };
}
