<div class="home" data-testid="gbe-benin-home-container">
  <div class="body">
    <div class="header">
      <h2 class="my-2">{{ 'home-benin.header' | translate }}</h2>
      <h5>{{ 'home-benin.sub-header' | translate }}</h5>
    </div>
    <div class="image-display">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/GBE1s.jpg"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/GBE2s.jpg"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/GBE3s.jpg"/>
    </div>
    <div class="bg-container">
      <div class="logos">
        <img src="/assets/images/gbe-benin/gbe-benin-logo.jpg" width="330px" height="95px"/>
        <img src="/assets/images/gbe-benin/german-cooperation.png" width="230px"/>
      </div>
      <button class="btn btn-primary my-4" [routerLink]="['../finance/programs']">
        {{ 'buttons.submit-claims' | translate }}
      </button>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
