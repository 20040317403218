<div class="main-container" data-testid="dares-home-container">
  <div class="bg-container">
    <img src="/assets/images/dares/rea-logo.svg" />
    <h1>Distributed Access Through Renewable Energy Scale-Up Project (DARES)</h1>
  </div>

  <div class="subhead-block">
    <h5>The DARES project is the flagship distributed renewable energy electrification initiatve of REA Nigeria and World Bank.  With $750m in World Bank financing, the program will benefit over 17.5 million unserved and underserved Nigerians, and over 200,000 MSMEs through the deployment of standalone solar and mini-grids and replace more than 280,000 polluting and expensive petrol and diesel generator sets.</h5>
  </div>
  <h2>Program Components</h2>

  <div class="components-block">
    <ng-container *ngFor="let card of cardsLeft; let i = index;">
      <div *ngIf="i === currentCardLeft" class="component-card">
        <div class="btn-container">
          <button class="arrow-btn-left" (click)="switchCard('left', -1)" [disabled]="i <= 0">
            <img src="/assets/icons/line-icons/shared/white/chev-right.svg" width="12px" />
          </button>
        </div>

        <div class="card-center">
          <h3>{{ card.title }}</h3>
          <div class="image-bg" [ngClass]="{ 'disabled' : card.disabled }">
            <img [src]="'https://oes-hosted-images-public.s3.amazonaws.com/minigrid-icon-white-' + card.icon" width="80px" />
            <ng-container *ngIf="card.disabled">
              <div class="coming-soon">Coming Soon</div>
            </ng-container>
          </div>
          <h5>{{ card.text }}</h5>
        </div>

        <div class="btn-container">
          <button class="arrow-btn-right" (click)="switchCard('left', 1)" [disabled]="i >= (cardsLeft.length - 1)">
            <img src="/assets/icons/line-icons/shared/white/chev-right.svg" width="12px" />
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngFor="let card of cardsRight; let i = index;">
      <div *ngIf="i === currentCardRight" class="component-card">
        <div class="btn-container">
          <button class="arrow-btn-left" (click)="switchCard('right', -1)" [disabled]="i <= 0">
            <img src="/assets/icons/line-icons/shared/white/chev-right.svg" width="12px" />
          </button>
        </div>

        <div class="card-center">
          <h3>{{ card.title }}</h3>
          <div class="image-bg" [ngClass]="{ 'disabled' : card.disabled }">
            <img [src]="'https://oes-hosted-images-public.s3.amazonaws.com/minigrid-icon-white-' + card.icon" width="80px" />
            <ng-container *ngIf="card.disabled">
              <div class="coming-soon">Coming Soon</div>
            </ng-container>
          </div>
          <h5>{{ card.text }}</h5>

        </div>
        <div class="btn-container">
          <button class="arrow-btn-right" (click)="switchCard('right', 1)" [disabled]="i >= (cardsRight.length - 1)">
            <img src="/assets/icons/line-icons/shared/white/chev-right.svg" width="12px" />
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="world-bank-logo-container">
    <img class="world-bank-logo" src="https://oes-hosted-images-public.s3.amazonaws.com/dares-world-bank-logo.svg" />
  </div>

  <div class="help-banner">
    <h4>{{ 'home.questions-1' | translate }}</h4>
    <p>{{ 'home.questions-2' | translate }}</p>
  </div>
</div>
