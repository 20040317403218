<!-- Multiple Roles -->
<ng-container *ngIf="displayRoleSelector > 0">
  <div class="select-profile" dropdown data-testid="select-profile-dropdown">
    <button
      type="button"
      id="user-type-selector"
      class="dropdown-toggle btn-link"
      [ngClass]="{ 'nav-closed' : !navOpen }"
      (mouseover)="showTooltip($event, roleElem, 'main-navigation.select-a-profile')"
      (mouseleave)="hideTooltip()"
      dropdownToggle
      #roleElem>
      <div class="icon-container">
        <svg class="role-selector-icon" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 15H1M1 15L5 11M1 15L5 19M1 5H17M17 5L13 1M17 5L13 9" stroke="#FF7F00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </button>
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
      <ng-container  *ngIf="availableRoles.developer">
        <a class="dropdown-item type-select"
          (click)="selectType(roleType.DEVELOPER_USER)">
          <span id="developer-selection" data-testid="developer-selection" class="tab">
            {{'main-navigation.developer' | translate}}
          </span>
          <oes-selected-role [selectedRole]="selectedRole" [role]="roleType.DEVELOPER_USER"></oes-selected-role>
        </a>
      </ng-container>
      <ng-container  *ngIf="availableRoles.investor">
        <a class="dropdown-item type-select"
          (click)="selectType(roleType.FINANCE_USER)">
          <span id="investor-selection" data-testid="investor-selection" class="tab">
            {{'main-navigation.investor' | translate}}
          </span>
          <oes-selected-role [selectedRole]="selectedRole" [role]="roleType.FINANCE_USER"></oes-selected-role>
        </a>
      </ng-container>
      <ng-container  *ngIf="availableRoles.vendor">
        <a class="dropdown-item type-select"
          (click)="selectType(roleType.VENDOR_USER)">
          <span id="vendor-selection" data-testid="vendor-selection" class="tab">
            {{'main-navigation.vendor' | translate}}
          </span>
          <oes-selected-role [selectedRole]="selectedRole" [role]="roleType.VENDOR_USER"></oes-selected-role>
        </a>
      </ng-container>
    </ul>
  </div>
</ng-container>
