import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-ceiafrica',
  templateUrl: './ceiafrica.component.html',
  styleUrls: ['./ceiafrica.component.scss']
})
export class CeiAfricaComponent {

  constructor() { }

}
