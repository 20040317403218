import { Component, OnInit } from '@angular/core';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { take } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ROLE_TYPE } from '@user/role-type';

@Component({
  selector: 'oes-theme-avorenewables',
  templateUrl: './avorenewables.component.html',
  styleUrls: ['./avorenewables.component.scss']
})
export class AvoRenewablesComponent implements OnInit {
  environment = environment;
  currentRole: ROLE_TYPE;
  usernameString: string;

  constructor(private _userService: UserService) { }

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this._userService.getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.currentRole = user.selectedRole;
        if (user?.firstName) {
          this.usernameString = ', ' + user.firstName;
        } else {
          this.usernameString = null;
        }
      });
  }
}
