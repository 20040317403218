import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { ChangePasswordRequest } from './change-password.model';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class ResetRequestService extends BaseRestApiService {
  private changePasswordUri = environment.serverUri + `api/${environment.apiVersion}/users/change-password`;

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _oauthService: OAuthService) {
    super(_restApiWrapperService);
  }

  public resetPassword(): Observable<any> {
    const idToken: any = this._oauthService.getIdToken();
    if (idToken) {
      const decodedToken: any = jwt_decode(idToken);
      const passwords: ChangePasswordRequest = {
        username: decodedToken.preferred_username
      };
      return this.post<any>(this.changePasswordUri, JSON.stringify(passwords));
    }
  }
}
