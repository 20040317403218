import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-ager',
  templateUrl: './ager.component.html',
  styleUrls: ['./ager.component.scss']
})
export class AgerComponent {
  constructor() { }
}
