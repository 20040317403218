import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-gbe-benin',
  templateUrl: './gbe-benin.component.html',
  styleUrls: ['./gbe-benin.component.scss']
})
export class GbeBeninComponent {

  constructor() { }

}
