<ng-container *ngIf="menuItems && menuItems.length && !horizontalNav">
  <div class="side-nav-secondary">
    <div class="side-nav-secondary__inner">
      <div class="side-nav-secondary__header">
        <div class="side-nav-secondary__header-bg bg-color--{{ category }}"></div>
        <h2 class="side-nav-secondary__header-title">{{ title }}</h2>
        <p class="side-nav-secondary__header-subtitle" *ngIf="subtitle">{{ subtitle }}</p>
      </div>
      <div class="horizontal-bar"></div>
      <div class="side-nav-secondary__links">
        <div class="side-nav-secondary__links-home">
          <!-- This will later be implemented into a dynamic back button which takes to previous page -->
          <ul>
            <li>
              <a
                [routerLink]="['./home']"
                class="parent home"
                routerLinkActive="selected"
                title="{{ 'main-navigation.home' | translate }}">
                <div class="icon">
                  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 7H1M1 7L7 13M1 7L7 1" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="title w-medium">
                  {{ 'main-navigation.back-to-home' | translate }}
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="side-nav-secondary__links-nav">
          <ul>
            <ng-container *ngFor="let item of menuItems">
              <ng-container *ngTemplateOutlet="MenuItem; context: { item : item }"></ng-container>
              <ng-container *ngIf="location?.includes(item?.url)">
                <ng-container *ngFor="let child of item?.children">
                  <ng-container *ngTemplateOutlet="ChildItem; context: { child : child }"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-template #MenuItem let-item="item">
              <li>
                <a class="parent {{ item.type || '' }}"
                  [routerLink]="[item.url]"
                  [queryParams]="item.queryParams"
                  [ngClass]="item.class"
                  routerLinkActive="selected"
                  [attr.data-id]="item.testId"
                  title="{{ item.title | translate }}"
                >
                  <div class="icon" [innerHtml]="item.icon | safeHtml"></div>
                  <div class="title">{{ item.title | translate }}{{ selected }}</div>
                  <ng-container *ngIf="item.children">
                    <img src="./assets/icons/line-icons/shared/black/chev-right.svg" />
                  </ng-container>
                </a>
              </li>
            </ng-template>

            <ng-template #ChildItem let-child="child">
              <li>
                <a class="child {{ child.type || '' }}"
                  [routerLink]="!child.class?.includes('stage-na') ? [child.url] : null"
                  [queryParams]="child.queryParams"
                  [ngClass]="child.class"
                  routerLinkActive="selected"
                  [disabled]="true"
                  title="{{ child.title | translate }}"
                >
                  <div class="icon" [innerHtml]="child.icon | safeHtml"></div>
                  <div class="title">{{ child.title | translate }}</div>
                </a>
              </li>
            </ng-template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>