export class MessageItem {
  displayTime: number;
  messageType: string;
  message: string;
  timerId: number;

  constructor(errorHandlerInfo: any) {
    this.displayTime = errorHandlerInfo.displayTime;
    this.message = errorHandlerInfo.message;
    this.messageType = errorHandlerInfo.messageType;
    this.timerId = errorHandlerInfo.timerId;
  }
}
