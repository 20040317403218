<div class="side-navigation" data-testid="side-nav">
  <div class="nav-tooltip"
    *ngIf="tooltipIsShowing"
    [ngStyle]="tooltipStyle">
    <div class="triangle"></div>
    {{ tooltipText | translate }}
  </div>

  <div class="logo-container">
    <ng-container *ngIf="pin || open; else SmallLogo">
      <a class="logo-full-container" [routerLink]="['./home']">
        <div class="navbar-logo-full"></div>
      </a>
    </ng-container>
    <ng-template #SmallLogo>
      <a class="logo-small-container" [routerLink]="['./home']">
        <div class="navbar-logo-small"></div>
      </a>
    </ng-template>

    <div *ngIf="displayPoweredByIcon" class="powered-by-container">
      <ng-container *ngIf="pin || open; else SmallPoweredLogo">
        <img class="powered-by-logo-full" src="./assets/images/odyssey/powered-by-logo.svg" />
      </ng-container>
      <ng-template #SmallPoweredLogo>
        <img class="powered-by-logo-small" src="./assets/images/odyssey/logo-odyssey-simple.png" />
      </ng-template>
    </div>

    <div *ngIf="pin || open" class="lock">
      <div (click)="pinSidenav()" data-testid="lock">
        <ng-container *ngIf="pin; else UnPin">
          <i class="fa fa-lock" aria-hidden="true"></i>
        </ng-container>
        <ng-template #UnPin>
          <i class="fa fa-unlock-alt" aria-hidden="true"></i>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="switchable-nav">
    <div class="home-link-container">
      <ul>
        <li #navElem>
          <a
            [routerLink]="['./home']"
            class="parent home"
            routerLinkActive="selected"
            (mouseover)="showTooltip($event, navElem, 'main-navigation.home')"
            (mouseleave)="hideTooltip()">
            <div class="icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="home-line">
                <path id="Icon" d="M8 17H16M11.0177 2.764L4.23539 8.03912C3.78202 8.39175 3.55534 8.56806 3.39203 8.78886C3.24737 8.98444 3.1396 9.20478 3.07403 9.43905C3 9.70352 3 9.9907 3 10.5651V17.8C3 18.9201 3 19.4801 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4801 21 18.9201 21 17.8V10.5651C21 9.9907 21 9.70352 20.926 9.43905C20.8604 9.20478 20.7526 8.98444 20.608 8.78886C20.4447 8.56806 20.218 8.39175 19.7646 8.03913L12.9823 2.764C12.631 2.49075 12.4553 2.35412 12.2613 2.3016C12.0902 2.25526 11.9098 2.25526 11.7387 2.3016C11.5447 2.35412 11.369 2.49075 11.0177 2.764Z"
                      stroke="#404968" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <ng-container
      *ngIf="selectedRole === roleType.DEVELOPER_USER ||
            selectedRole === roleType.FINANCE_USER ||
            selectedRole === roleType.VENDOR_USER ||
            selectedRole === roleType.SYSTEM_ADMIN ||
            selectedRole === roleType.DATA_ANALYST ||
            selectedRole === roleType.ORGANIZATION_ADMIN; else NoRoleUserMenu">
      <div *ngIf="menuItems; else EmptySideNav" class="menu-items">
        <ul *ngFor="let item of menuItems">
          <ng-container *ngIf="!item.children; else HasChildren">
            <li #navElem>
              <a class="parent {{ item.type || '' }}"
                [routerLink]="[item.url]"
                [queryParams]="item.queryParams"
                routerLinkActive="selected"
                (click)="handleClick($event, item)"
                (mouseover)="showTooltip($event, navElem, item.title)"
                (mouseleave)="hideTooltip()">
              <div class="icon" [innerHtml]="(item?.themedIcon && item.themedIcon[theme] ? item.themedIcon[theme] : item.icon) | safeHtml"></div>
              </a>
            </li>
          </ng-container>
          <ng-template #HasChildren>
            <ng-container *ngIf="item.children[0].url; else ExternalUrl">
              <li #navElem>
                <a class="parent {{ item.type || '' }}"
                  [routerLink]="[getChild(item).url]"
                  [queryParams]="getChild(item).queryParams"
                  [ngClass]="{'selected': isRouteActive(item.children[0].url)}"
                  (click)="handleClick($event, item)"
                  (mouseover)="showTooltip($event, navElem, item.title)"
                  (mouseleave)="hideTooltip()">
                <div class="icon" [innerHtml]="(item?.themedIcon && item.themedIcon[theme] ? item.themedIcon[theme] : item.icon) | safeHtml"></div>
                </a>
              </li>
            </ng-container>
            <ng-template #ExternalUrl>
              <li #navElem>
                <a class="parent {{ item.type || '' }}"
                  [href]="getChild(item).externalUrl"
                  [queryParams]="getChild(item).queryParams"
                  routerLinkActive="selected"
                  target="_blank"
                  (mouseover)="showTooltip($event, navElem, item.title)"
                  (mouseleave)="hideTooltip()">
                <div class="icon" [innerHtml]="(item?.themedIcon && item.themedIcon[theme] ? item.themedIcon[theme] : item.icon) | safeHtml"></div>
                </a>
              </li>
            </ng-template>
          </ng-template>
        </ul>
      </div>
      <ng-template #EmptySideNav>
        <ul><li class="empty"></li></ul>
        <ul><li class="empty"></li></ul>
        <ul><li class="empty"></li></ul>
        <ul><li class="empty"></li></ul>
        <ul><li class="empty"></li></ul>
      </ng-template>
    </ng-container>
    <ng-template #NoRoleUserMenu>
      <ul>
        <ng-container *ngIf="open || pin">
          <li #navElem>
            <a
              [routerLink]="['./home']"
              routerLinkActive="selected"
              (mouseover)="showTooltip($event, navElem, 'main-navigation.home')"
              (mouseleave)="hideTooltip()">
              <div class="title">
                {{'main-navigation.home'|translate}}
              </div>
            </a>
          </li>
        </ng-container>
      </ul>
    </ng-template>
  </div>

  <div class="user-nav">
    <div class="horizontal-bar"></div>
    <ul>

      <!-- File Manager -->
      <li #fileElem>
        <a
          [routerLink]="['./files']"
          class="parent files"
          routerLinkActive="selected"
          (mouseover)="showTooltip($event, fileElem, 'main-navigation.files')"
          (mouseleave)="hideTooltip()">
          <div class="icon">
            <img src="./assets/icons/line-icons/nav/grey/files.svg" width="24px" height="24px" />
          </div>
        </a>
      </li>

      <!-- Help -->
      <li #helpElem>
        <div class="parent">
          <a
            href="https://desk.zoho.com/portal/odysseyenergysolutions"
            target="_blank"
            (mouseover)="showTooltip($event, helpElem, 'main-navigation.help')"
            (mouseleave)="hideTooltip()">
            <div class="icon">
              <img src="./assets/icons/line-icons/nav/grey/help.svg" />
            </div>
          </a>
        </div>
      </li>

      <!-- Role Selector -->
      <li>
        <div class="parent role-selector-container">
          <oes-role-selector
            [navOpen]="pin || open"
            *ngIf="user"
            [showTooltip]="showTooltip"
            [hideTooltip]="hideTooltip">
          </oes-role-selector>
        </div>
      </li>

      <!-- User/Account Management -->
      <li>
        <div class="parent">
          <oes-user-setting
            [showTooltip]="showTooltip"
            [hideTooltip]="hideTooltip"
            [navOpen]="pin || open">
          </oes-user-setting>
        </div>
      </li>
    </ul>

  </div>
</div>
