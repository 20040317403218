<div class="home" data-testid="eth-home-container">
  <div class="body">
    <div class="header">
      <img src="/assets/images/dream-ethiopia/dream-ethiopia.jpg" width="280px">
    </div>
    <div class="image-display">
      <div class="image-container"></div>
      <div class="image-container"></div>
      <div class="image-container"></div>
      <div class="image-container"></div>
      <div class="image-container"></div>
      <div class="image-container"></div>
      <div class="image-container"></div>
      <div class="image-container"></div>
      <div class="image-container"></div>
      <div class="credit text-right w-100">Photos courtesy of African Development Bank. All rights reserved.</div>
    </div>
    <div class="bg-container">
      <div class="logos">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/eth-all-logos-small.jpg" />

      </div>
      <div class="">
        <button class="btn btn-primary" [routerLink]="['../finance/programs']">
          {{ 'buttons.apply' | translate }}
        </button>
      </div>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>

