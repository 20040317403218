import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-dares',
  templateUrl: './dares.component.html',
  styleUrls: ['./dares.component.scss']
})
export class DaresComponent {

  cardsLeft = [
    {
      title: 'Mini-Grids',
      icon: 'solar-monitor.png',
      text: 'Performance-Based Grants for Isolated Mini-grids',
      link: '',
      disabled: false
    },
    {
      title: 'Mini-Grids',
      icon: 'solarpanels.png',
      text: 'Minimum-subsidy Tender for Interconnected Mini-grids',
      link: '',
      disabled: false
    },
    {
      title: 'Mini-Grids',
      icon: 'panel-battery.png',
      text: 'Performance-Based Grants for Interconnected Mini-grids',
      link: '',
      disabled: true
    },
    {
      title: 'Mini-Grids',
      icon: 'solar-document.png',
      text: 'Minimum-subsidy Tender for Isolated Mini-grids',
      link: '',
      disabled: true
    }
  ];
  currentCardLeft = 0;

  cardsRight = [
    {
      title: 'Stand Alone Solar',
      icon: 'solarhouse.png',
      text: 'Performance-Based Grants for Stand-Alone Solar',
      link: '',
      disabled: false
    },
    {
      title: 'Stand Alone Solar',
      icon: 'appliances.png',
      text: 'Performance-Based Grants for Productive Use Equipment',
      link: '',
      disabled: true
    }
  ];
  currentCardRight = 0;

  constructor() {}

  switchCard(side: 'left' | 'right', amount: 1 | -1) {
    switch (side) {
      case 'left':
        this.currentCardLeft = this.currentCardLeft + amount;
        break;
      case 'right':
        this.currentCardRight = this.currentCardRight + amount;
        break;
      default:
        break;
    }
  }
}
