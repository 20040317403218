import {Component, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {Organization} from '@shared/models/organization/organization.model';
import {OrganizationService} from '../../organization.service';

import {EventService} from '@shared/event.service';
import {MICROGRID_STATUS} from '@shared/microgrid-statuses';
import {UserService} from '@user/user.service';
import {User} from '@user/user.model';
import {ROLE_TYPE} from '@user/role-type';
import {DeveloperInformationService} from '../developer-information.service';
import {DeveloperInformation} from '../developer-information.model';
import {UnsavedDataModalComponent} from '@shared/components/unsaved-data-modal/unsaved-data-modal.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ComponentCanDeactivate } from '@organization-management/organization/shared/guard/component-can-deactivate.interface';
import { DOCUMENT_TAG_TYPE } from '@shared/components/files/shared/document-tag-type.enum';
import { FilesActions } from '@shared/components/files/shared/models/action.model';
import { TagType } from '@shared/components/files/shared/models/tag-type.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-organization-developer-public-profile',
  templateUrl: 'organization-developer-public-profile.component.html',
  styleUrls: ['organization-developer-public-profile.component.scss'],
})

export class OrganizationDeveloperPublicProfileComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(UnsavedDataModalComponent, {static: false}) unSavedDataModalComponent: UnsavedDataModalComponent;
  @Input() showSaveCancelButtons = true;
  @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
  public organization: Organization;
  public developerInfo: DeveloperInformation;
  private user: User;
  public userIsOrgAdmin = false;
  public microGridStatus = MICROGRID_STATUS;
  public tagTypes: TagType[];
  public formGroup: UntypedFormGroup;
  public accessControl = new FilesActions({
    copy: true,
    create: true,
    delete: true,
    download: true,
    move: true,
    upload: true,
    tag: true,
    editName: true,
    preview: true
  });

  constructor(private _organizationService: OrganizationService,
              private _developerInformationService: DeveloperInformationService,
              private _translateService: TranslateService,
              private _userService: UserService,
              private _eventService: EventService) {
  }

  ngOnInit() {
    this.createForm();
    this.getOrganization();
  }

  private createForm() {
    this.formGroup = new UntypedFormGroup({
      companyOverview: new UntypedFormControl('', Validators.required),
      videoLink: new UntypedFormControl(),
      videoLinkDescription: new UntypedFormControl(),
      microGridStatus: new UntypedFormControl('', Validators.required),
    });
    this.formGroup.valueChanges.subscribe(result => {
      const a = result;
    });
  }

  private getOrganization() {
    this._organizationService
      .getMyOrganization()
      .subscribe((organization: Organization) => {
        this.organization = organization;
        this.getDeveloperInfo();
      });
  }

  private getDeveloperInfo() {
    this._developerInformationService.list(this.organization.id)
    .subscribe((developerInfo: DeveloperInformation) => {
      this.developerInfo = developerInfo;
      this.setValues(developerInfo);
      this.getUser();
    });
  }

  private setValues(developerInfo: DeveloperInformation) {
    this.formGroup.controls['videoLink'].setValue(developerInfo?.videoLink, {emitEvent: false});
    this.formGroup.controls['videoLinkDescription'].setValue(developerInfo?.videoLinkDescription, {emitEvent: false});
    this.formGroup.controls['microGridStatus'].setValue(developerInfo?.microGridStatus, {emitEvent: false});
  }

  private getUser() {
    this._userService.getCurrentUser().subscribe((user: User) => {
      this.user = new User(user);
      if (this.user.roles.filter(role => role.name === ROLE_TYPE.ORGANIZATION_ADMIN).length) {
        this.userIsOrgAdmin = true;
        this.tagTypes = [{
          docTagItemId: this.developerInfo.id,
          docTagType: DOCUMENT_TAG_TYPE.DEVELOPER_INFORMATION,
          name: (DOCUMENT_TAG_TYPE.DEVELOPER_INFORMATION).replace('_', ' ').toLowerCase()
        }, {
          docTagItemId: this.organization.id,
          docTagType: DOCUMENT_TAG_TYPE.ORGANIZATION,
          name: user.organization.name
        }];
      }
    });
  }

  private saveChanges() {
    this.organization.companyOverview = this.formGroup.controls['companyOverview']?.value?.textControl;
    this._organizationService.save(this.organization)
    .subscribe(() => {
      this.developerInfo.companyOverview = this.formGroup.controls['companyOverview'].value;
      this.developerInfo.videoLink = this.formGroup.controls['videoLink'].value;
      this.developerInfo.videoLinkDescription = this.formGroup.controls['videoLinkDescription'].value;
      this.developerInfo.microGridStatus = this.formGroup.controls['microGridStatus'].value;
      this._developerInformationService.update(this.organization.id, this.developerInfo)
      .subscribe(() => {
        this.formGroup.markAsPristine();
        this._eventService.success(this._translateService.instant('success-message.changes-saved'));
        this.saved.emit(true);
        this.unSavedDataModalComponent.hide();
      });
    });
  }

  // ComponentCanDeactivate requires
  hasUnsavedData(): boolean {
    return this.formGroup.dirty && this.formGroup.touched;
  }

  // ComponentCanDeactivate requires
  showUnsavedDataModal(nextUrl: string) {
    this.unSavedDataModalComponent.show(nextUrl);
    return false;
  }

  public discardChanges() {
    this.formGroup.markAsPristine();
  }

  // access from account-setup.component
  public save() {
    this.saveChanges();
  }
}
