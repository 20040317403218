import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { OdysseyPlatform } from '../odyssey-platform';

@Injectable({
  providedIn: 'root'
})
export class OdysseyPlatformService extends BaseRestApiService {
  private url: string = environment.serverUri + `api/${environment.apiVersion}/dashboards/platform-metrics`;
  private titles: any;

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _translateService: TranslateService) {
    super(_restApiWrapperService);
    this.titles = this._translateService.instant('home-kpi.odyssey');
  }

  public getData(): Observable<OdysseyPlatform> {
    return this.get<OdysseyPlatform>(this.url);
  }
}
