import { ROLE_TYPE } from '@user/role-type';
import { NavItem } from '../nav-item.model';
import { MainMenuCategory } from '@main-navigation/shared/main-menu-category.enum';

export class OperateNav {
  public static item: NavItem = {
    title: 'main-navigation.operate',
    icon: '<img src="./assets/icons/line-icons/nav/colors/activity.svg" width="24px" height="24px">',
    themedIcon: {
      avorenewables: '<img src="./assets/images/avorenewables/icons/activity.svg" width="24px" height="24px">'
    },
    bold: true,
    roles: [ROLE_TYPE.USER],
    type: MainMenuCategory.operate,
    children: [
      {
        title: 'main-navigation.fernview',
        externalUrl: 'https://fernview.odysseyenergysolutions.com/',
        testId: 'fernview-nav-link',
        roles: [ROLE_TYPE.USER],
      },
      {
        title: 'main-navigation.data-admin',
        url: '/oes/analytics/data-admin',
        testId: 'data-admin-nav-link',
        roles: [ROLE_TYPE.SYSTEM_ADMIN],
      },
    ]
  };
}
