import { BaseModel } from '@shared/base.model';

export class FeatureToggle extends BaseModel<FeatureToggle> {
  name: string;
  description: string;
  enabled: boolean;

  constructor(incomingFeatureToggle: any) {
    super();
    this.name = incomingFeatureToggle.name;
    this.description = incomingFeatureToggle.description;
    this.enabled = incomingFeatureToggle.enabled;
  }
}
