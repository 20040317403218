import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@user/user.service';
import { ROLE_TYPE } from '@user/role-type';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { OrganizationImage } from '@organization-management/organization/organization-image.model';
import { OrganizationLogoImageService } from '@organization-management/organization/organization-logo-image.service';
import { User } from '@user/user.model';

@Component({
  selector: 'oes-theme-odyssey',
  templateUrl: './odyssey.component.html',
  styleUrls: ['./odyssey.component.scss']
})
export class OdysseyComponent implements OnInit, OnDestroy {
  cdnUri: string;
  hasCommercialInvestorDemoRole: boolean;
  logoImage: OrganizationImage;
  orgAdmin: boolean;
  roleCards: any;
  roleType = ROLE_TYPE;
  roles = new Map();
  selectedRole: ROLE_TYPE;
  user: User;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _translateService: TranslateService,
              private _organizationLogoImageService: OrganizationLogoImageService,
              private _userService: UserService) {
  }

  ngOnInit(): void {
    this.cdnUri = environment.organizationCdnUri;
    const text = this._translateService.instant('home-2');
    this._userService.userRole$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((role: ROLE_TYPE) => {
      if (role) {
        this.selectedRole = role;
      }
      if (text[this.selectedRole]) {
        this.roleCards = text[this.selectedRole].cards;
      }
      this.hasCommercialInvestorDemoRole = this._userService.hasRole(ROLE_TYPE.COMMERCIAL_INVESTOR_DEMO_USER);
    });
    this.getUser();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private getUser() {
    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe((user: User) => {
      this.orgAdmin = user.hasRole(ROLE_TYPE.ORGANIZATION_ADMIN);
      this.user = user;
      this.getLogo(user?.organization?.id);
    });
  }

  private getLogo(organizationId: string) {
    this._organizationLogoImageService.getImage(organizationId)
    .pipe(take(1))
    .subscribe((responses: OrganizationImage[]) => {
      if (responses?.length > 0) {
        this.logoImage = responses[0];
      }
    });
  }
}
