import {Injectable} from '@angular/core';
import {DataImportRequest} from '@shared/models/io/data-import-request.model';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataImportRequestService extends BaseRestApiService {
  public organizationsUri = environment.serverUri + `api/${environment.apiVersion}/organizations`;
  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public list(organizationId: string): Observable<DataImportRequest[]> {
    return this.get<DataImportRequest[]>(`${this.organizationsUri}/${organizationId}/data/requests`, {params: {page: 0, size: 10, sort: 'created,DESC'}})
            .pipe(
              map(results => results.map(result => new DataImportRequest(result)))
            );
  }

  public uploadUrl(organizationId: string): string {
    return `${this.organizationsUri}/${organizationId}/data/import/PROJECT`;
  }
}
