import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts} from 'ngx-bootstrap-multiselect';
import {Country} from '@shared/services/country/country.model';
import { CountryService } from '@shared/services/country/country.service';

@Component({
  selector: 'oes-country-multiselect',
  templateUrl: './country-multiselect.component.html',
  styleUrls: ['./country-multiselect.component.scss'],
})

export class CountryMultiselectComponent implements OnInit {
  pickerOptions: IMultiSelectOption[] = [];
  pickerSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 4,
    selectionLimit: 0,
    autoUnselect: false,
    showCheckAll: true,
    showUncheckAll: true
  };
  pickerTexts: IMultiSelectTexts = {
    checkAll: 'Select All',
    uncheckAll: 'Unselect All'
  };
  allCountries: Country[] = [];
  selectedCountries: Country[] = [];
  selectedCountriesIndexes: number[] = [];
  @Input()
  currentCountries: Country[] = [];
  @Input()
  countriesSubset: Country[] = [];
  @Input()
  buttonClasses: string;
  @Input()
  dynamicTitleMaxItems: number;
  @Output()
  countriesChanged: EventEmitter<Country[]> = new EventEmitter();

  constructor(private _countryService: CountryService) {
  }

  onChange(event) {
    if (event.length === this.allCountries.length) {
      this.selectedCountries = this.allCountries;
    } else {
      this.selectedCountries = [];
      event.forEach(id => {
        this.selectedCountries.push(this.allCountries[id]);
      });
    }
    this.countriesChanged.emit(this.selectedCountries);
  }

  ngOnInit() {
    if (this.countriesSubset.length) {
      this.allCountries = this.countriesSubset;
      this.allCountries.forEach((country, index) => {
        this.pickerOptions.push({
          id: index,
          name: `${country.code} - ${country.name}`
        });
      });
    } else {
      this._countryService.getCountries()
      .subscribe((countries: Country[]) => {
        if (countries && countries.length > 0) {
          this.allCountries = countries;
          countries.forEach((country, index) => {
            this.pickerOptions.push({id: index, name: country.code + ' - ' + country.name});
            if (this.currentCountries.find(el => el.code === country.code)) {
              this.selectedCountriesIndexes.push(index);
            }
          });
        }
      });
    }

    if (this.buttonClasses) {
      this.pickerSettings.buttonClasses = this.buttonClasses;
    }
    if (this.dynamicTitleMaxItems) {
      this.pickerSettings.dynamicTitleMaxItems = this.dynamicTitleMaxItems;
    }
  }

}
