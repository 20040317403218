<div class="messages-container">
  <ng-container *ngFor="let messageHandler of messageHandlers">
    <div class="message-container d-flex justify-content-start"
         [ngClass]="{'error': messageHandler.messageType === eventType.ERROR_MESSAGE,
                     'warning':messageHandler.messageType === eventType.WARNING_MESSAGE,
                     'success':messageHandler.messageType === eventType.SUCCESS_MESSAGE
                    }">
      <div class="icon-box">
        <div [ngSwitch]="messageHandler.messageType">
          <img *ngSwitchCase="eventType.SUCCESS_MESSAGE" src="/assets/images/error-handler/icn-success-white.svg">
          <img *ngSwitchDefault src="/assets/images/error-handler/icn-warning-error-white.svg">
        </div>
      </div>
      <div class="message align-self-center py-1">
        <p class="my-0 mx-2 error-message-text">{{messageHandler.message}}</p>
      </div>
      <div class="close ml-auto" (click)="clearMessage(messageHandler.timerId)">
        <i class="fa fa-close"></i>
      </div>
    </div>
  </ng-container>
</div>
