import { environment } from '@environments/environment';

export class FeatureToggleConstant {
  public static featureToggles = environment.serverUri + `api/${environment.apiVersion}/feature-toggles`;
  public static list() {
    return `${FeatureToggleConstant.featureToggles}/`;
  }
  public static create() {
    return `${FeatureToggleConstant.featureToggles}/`;
  }
  public static detail(featureToggleId: string) {
    return `${FeatureToggleConstant.featureToggles}/${featureToggleId}`;
  }
  public static update(featureToggleId: string) {
    return `${FeatureToggleConstant.featureToggles}/${featureToggleId}`;
  }
}
