<form #organizationProfileForm="ngForm"
      (ngSubmit)="save()"
      class="org-profile-form">
  <div class="table-wrap">
    <div class="mb-30">
      <h3 id="company-information-heading" data-testid="organization-profile-company-heading">{{'organization-information.company-information.heading' | translate}}</h3>
      <p>{{'organization-information.company-information.statement'|translate}}</p>
      <div class="row mt-30">
        <div class="col-12">
          <div class="row" id="company-meta-data-container" data-testid="organization-profile-meta-data">
            <div class="col-2 ml-3 p-2 text-center" *ngIf="logoImage == null || logoImage == undefined">
              <div style="border: 1px dashed">
                <div class="upload-btn-wrapper mr-2 mt-4">
                  <input type="file" name="logo_file" ng2FileSelect [uploader]="logoUploader" accept=".jpg,.gif,.png">
                  <button type="button" class="btn btn-small btn-primary">{{'buttons.choose-file'|translate}}</button>
                </div>
                <button type="button"
                        class="btn btn-small btn-secondary mb-3"
                        (click)="uploadLogo()"
                        *ngIf="logoUploader.queue.length">
                  {{'project-group-introduction.overview.IMAGE'|translate}}
                </button>
                <p>{{'organization-information.company-information.upload-requirements'|translate}}</p>
              </div>
              <div class="err-msg" *ngIf="logoImageInput.invalid && organizationProfileForm.submitted">
                <p>{{'organization-information.upload-photos.no-logo-err-msg' | translate}}</p>
              </div>
            </div>
            <div class="col-2 ml-3 p-0 existing-logo" *ngIf="logoImage != null && logoImage != undefined">
              <img src="{{cdnUri}}/{{logoImage.id}}" style="width: 100%;" />
              <div class="change-logo text-sm-center">
                <div class="upload-btn-wrapper mr-2 mt-4">
                  <input type="file" name="logo_file" ng2FileSelect [uploader]="logoUploader">
                  <button type="button" class="btn btn-small btn-primary">Upload Logo</button>
                </div>
                <button type="button" class="btn btn-small btn-secondary mb-2"
                        (click)="prepareUpload(logoImage.id)"
                        *ngIf="logoUploader.queue.length">
                  {{'project-group-introduction.overview.IMAGE'|translate}}
                </button>
                <p>Must be a .jpg, .gif, or .png file smaller than 10MB and at least 400px by 400px.</p>
              </div>
            </div>
            <!-- Using the input below to allow angular to require a logo: Error message above -->
            <input hidden name="logo-image-id" type="text" [(ngModel)]="logoImageId" required #logoImageInput="ngModel">
            <div class="col-4">
              <div class="row">
                <div class="col">
                  <label for="name">{{'organization-information.company-information.name' | translate}}</label>
                  <input type="text" class="form-control" name="name" id="name" placeholder="{{organization.name}}" disabled>
                </div>
              </div>
              <div class="row">
                <div class="col pl-0 years-in-business-container">
                  <label for="yearsInBusiness">{{'organization-information.organization-profile.years-in-business' | translate}}</label>
                  <input type="number" class="form-control" id="yearsInBusiness" name="yearsInBusiness"
                    placeholder="{{'organization-information.organization-profile.years-in-business-placeholder' | translate}}"
                    [(ngModel)]="organization.yearsInBusiness" required #yearsInBusiness="ngModel">
                  <div class="err-msg" *ngIf="yearsInBusiness.invalid &&
                                              (yearsInBusiness.touched ||
                                              yearsInBusiness.dirty ||
                                              organizationProfileForm.submitted)">
                    <p>{{'organization-information.organization-profile.years-in-business-err-msg' | translate}}</p>
                  </div>
                </div>
                <div class="col">
                  <label for="numberOfEmployees">{{'organization-information.organization-profile.number-of-employees' | translate}}</label>
                  <input type="number" class="form-control" id="numberOfEmployees" name="numberOfEmployees"
                    placeholder="{{'organization-information.organization-profile.number-of-employees-placeholder' | translate}}"
                    [(ngModel)]="organization.numberOfEmployees" required #numberOfEmployees="ngModel">
                  <div class="err-msg" *ngIf="numberOfEmployees.invalid &&
                                              (numberOfEmployees.touched ||
                                              numberOfEmployees.dirty ||
                                              organizationProfileForm.submitted)">
                    <p>{{'organization-information.organization-profile.number-of-employees-err-msg' | translate}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-5">
              <div class="row">
                <div class="col">
                  <label for="headquartersLocation">
                    {{'organization-information.organization-profile.headquarters-location' | translate}}
                  </label>
                  <input type="text" class="form-control" id="headquartersLocation" maxlength="255" name="headquartersLocation"
                    placeholder="{{'organization-information.organization-profile.headquarters-location-placeholder' | translate}}"
                    [(ngModel)]="organization.headquartersLocation" required #headquartersLocation="ngModel">
                  <div class="err-msg" *ngIf="headquartersLocation.invalid &&
                                              (headquartersLocation.touched ||
                                              headquartersLocation.dirty ||
                                              organizationProfileForm.submitted)">
                    <p>{{'organization-information.organization-profile.headquarters-location-err-msg' | translate}}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label for="otherLocations">
                    {{'organization-information.organization-profile.other-locations' | translate}}
                  </label>
                  <input type="text" class="form-control" id="otherLocations" name="otherLocations"
                    placeholder="{{'organization-information.organization-profile.other-locations' | translate}}"
                    [(ngModel)]="organization.otherLocations">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h3 id="contact-information-heading" data-testid="organization-profile-contact-info">{{'organization-information.contact-information.heading' | translate}}</h3>
      <p>{{'organization-information.contact-information.subheading' | translate}}</p>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="contactFirstName">
              {{'organization-information.contact-information.first-name' | translate}}:
            </label>
            <input type="text" class="form-control" id="contactFirstName" maxlength="255" name="contactFirstName"
                    placeholder="{{'organization-information.contact-information.first-name' | translate}}"
                    [(ngModel)]="organization.contactFirstName" required #contactFirstName="ngModel">
            <div class="err-msg" *ngIf="contactFirstName.invalid &&
                                        (contactFirstName.touched ||
                                        contactFirstName.dirty ||
                                        organizationProfileForm.submitted)">
              <p>{{'organization-information.contact-information.first-name-err-msg' | translate}}</p>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <div class="form-group">
              <label for="contactLastName">
                {{'organization-information.contact-information.last-name' | translate}}:
              </label>
              <input type="text" class="form-control" id="contactLastName" maxlength="255" name="contactLastName"
                      placeholder="{{'organization-information.contact-information.last-name' | translate}}"
                      [(ngModel)]="organization.contactLastName" required #contactLastName="ngModel">
              <div class="err-msg" *ngIf="contactLastName.invalid &&
                                          (contactLastName.touched ||
                                          contactLastName.dirty ||
                                          organizationProfileForm.submitted)">
                <p>{{'organization-information.contact-information.last-name-err-msg' | translate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="contactEmailAddress">
              {{'organization-information.contact-information.email-address' | translate}}:
            </label>
            <input type="text" class="form-control" id="contactEmailAddress" maxlength="255" name="contactEmailAddress"
                    placeholder="{{'organization-information.contact-information.email-address' | translate}}"
                    [(ngModel)]="organization.contactEmailAddress" required #contactEmailAddress="ngModel">
            <div class="err-msg" *ngIf="contactEmailAddress.invalid &&
                                        (contactEmailAddress.touched ||
                                        contactEmailAddress.dirty ||
                                        organizationProfileForm.submitted)">
              <p>{{'organization-information.contact-information.email-address-err-msg' | translate}}</p>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-grop">
            <label for="contactPrimaryPhone">
              {{'organization-information.contact-information.primary-phone' | translate}}:
            </label>
            <input type="text" class="form-control" id="contactPrimaryPhone" maxlength="255" name="contactPrimaryPhone"
                    placeholder="{{'organization-information.contact-information.primary-phone' | translate}}"
                    [(ngModel)]="organization.contactPrimaryPhone">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-30">
    <button *ngIf="showSaveCancelButtons"
            class="btn btn-primary pull-right" type="submit">{{'buttons.save'|translate}}</button>
  </div>
</form>
<oes-unsaved-data-modal
  (saveDataEmitter)="save()"
  (discardChangesEmitter)="discardChanges()">
</oes-unsaved-data-modal>
