<div class="home" data-testid="rea-home-container">
  <div class="header">
    <img src="/assets/images/rea/rea-logo-payoff-lg.jpg" width="225px">
  </div>
  <div class="body">
    <div class="image-display">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/rea1.jpg"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/rea2.jpg"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/rea3.jpg"/>
    </div>
    <div class="tile-navi">
      <ng-container *ngFor="let reaCard of reaRoleCards">
        <oes-program-homepage-item
          [heading]="reaCard.heading"
          [guideLink]="reaCard.guideLink"
          [guideIcon]="reaCard.guideIcon"
          [guideText]="reaCard.guideText">
        </oes-program-homepage-item>
      </ng-container>
    </div>
    <button class="btn btn-primary go-to-button mb-3" [routerLink]="['../finance/programs']">
      {{ 'home-rea-2.go-to-programs' | translate }}
    </button>
  </div>
</div>

<!-- agreement dialog -->
<oes-modal-dialog
  [title]="'Important'"
  [allowCancel]="false"
  [allowSubmit]="false">
  <div>
    <p>To view documents associated with the <b>REA NEP Competitive Tender</b>, navigate to the <b>Programs</b> tab.</p>
    <p>REA has made every attempt to ensure the accuracy and reliability of the information provided on the communities and sites included in this tender.  However, the information is provided as market intelligence, and REA does not accept any responsibility or liability for the accuracy or completeness of the information provided.</p>
    <div class="text-right">
      <button type="submit" class="btn btn-primary"
        (click)="reaHomeModal.hide()"
        (click)="saveModalResponse('rea')">I understand</button>
    </div>
  </div>
</oes-modal-dialog>
