<ng-container *ngIf="currentRole === 'FINANCE_USER'; else OdysseyTheme">
  <div class="home" data-testid="avorenewables-home-container">
    <div class="body">
      <div class="header-bg"></div>
      <div class="main-bg"></div>

      <div class="content">
        <div class="logo">
          <img src="/assets/images/avorenewables/nedbank-logo.png" width="120px">
        </div>
        <h2>{{ 'home-avorenewables.welcome-back' | translate }}{{ usernameString }}</h2>
        <h4>{{ 'home-avorenewables.subhead' | translate }}</h4>

        <div class="content-cards">
          <div class="content-card">
            <div class="card-icon card-icon-1">
              <img src="/assets/icons/line-icons/nav/colors/bar-chart-circle.svg" width="28" />
            </div>
            <h3>{{ 'home-avorenewables.program-home' | translate }}</h3>
            <p>{{ 'home-avorenewables.program-subhead' | translate }}</p>
            <ng-container *ngIf="environment?.name === 'prod'; else ProgramsHome">
              <a [routerLink]="['../finance/programs/4d860d6d-383e-49cc-a99e-e419cfdacab8/home']" class="orange-link link-plain">{{ 'home-avorenewables.view-program-home' | translate }}<img class="arrow" src="/assets/images/avorenewables/icons/arrow-right.svg" /></a>
            </ng-container>
            <ng-template #ProgramsHome>
              <a [routerLink]="['../finance/programs']" class="orange-link link-plain">{{ 'home-avorenewables.view-program-home' | translate }}<img class="arrow" src="/assets/images/avorenewables/icons/arrow-right.svg" /></a>
            </ng-template>
          </div>

          <div class="content-card">
            <div class="card-icon card-icon-2">
              <img src="/assets/icons/line-icons/nav/grey/layers.svg" width="28" />
            </div>
            <h3>{{ 'home-avorenewables.installer-applications' | translate }}</h3>
            <p>{{ 'home-avorenewables.installer-applications-subhead' | translate }}</p>
            <ng-container *ngIf="environment?.name === 'prod'; else ProgramsApplications">
              <a [routerLink]="['../finance/programs/4d860d6d-383e-49cc-a99e-e419cfdacab8/applications-list']" class="orange-link link-plain">{{ 'home-avorenewables.view-applications' | translate }}<img class="arrow" src="/assets/images/avorenewables/icons/arrow-right.svg" /></a>
            </ng-container>
            <ng-template #ProgramsApplications>
              <a [routerLink]="['../finance/programs']" class="orange-link link-plain">{{ 'home-avorenewables.view-applications' | translate }}<img class="arrow" src="/assets/images/avorenewables/icons/arrow-right.svg" /></a>
            </ng-template>
          </div>

          <div class="content-card">
            <div class="card-icon card-icon-3">
              <img src="/assets/icons/line-icons/nav/colors/perspective.svg" width="26" />
            </div>
            <h3>{{ 'home-avorenewables.project-submissions' | translate }}</h3>
            <p>{{ 'home-avorenewables.project-submissions-subhead' | translate }}</p>
            <ng-container *ngIf="environment?.name === 'prod'; else ProgramsClaims">
              <a [routerLink]="['../finance/programs/4d860d6d-383e-49cc-a99e-e419cfdacab8/claims']" class="orange-link link-plain">{{ 'home-avorenewables.view-bids' | translate }}<img class="arrow" src="/assets/images/avorenewables/icons/arrow-right.svg" /></a>
            </ng-container>
            <ng-template #ProgramsClaims>
              <a [routerLink]="['../finance/programs']" class="orange-link link-plain">{{ 'home-avorenewables.view-bids' | translate }}<img class="arrow" src="/assets/images/avorenewables/icons/arrow-right.svg" /></a>
            </ng-template>
          </div>

          <div class="content-card">
            <div class="card-icon card-icon-4">
              <img src="/assets/icons/line-icons/nav/colors/activity.svg" width="28" />
            </div>
            <h3>{{ 'home-avorenewables.operational-systems' | translate }}</h3>
            <p>{{ 'home-avorenewables.operational-systems-subhead' | translate }}</p>
            <a href="https://fernview.odysseyenergysolutions.com/" target="_blank" class="orange-link link-plain">{{ 'home-avorenewables.view-systems' | translate }}<img class="arrow" src="/assets/images/avorenewables/icons/arrow-right.svg" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #OdysseyTheme>
  <oes-theme-odyssey></oes-theme-odyssey>
</ng-template>
