<ng-container *ngIf="platformVis">
  <div class="metrics">
    <div class="metrics-left">

      <div class="metric-header">
        <h3>{{ 'home-kpi.projects-header.' + selectedRole | translate }}</h3>
        <a [routerLink]="['../projects/my-projects']" class="orange-link link-plain">{{ 'home.view-projects' | translate }}<img class="arrow" src="/assets/icons/line-icons/shared/orange/chev-right.svg" /></a>
      </div>

      <div class="map">
        <ng-container *ngIf="mapProjects?.length > 0; else NoProjects">
          <oes-project-map
            [projects]="mapProjects"
            [zoomControl]="true"
            [height]="'310px'"
            [mapTypeId]="'roadmap'"
            [mapTypeControl]="false">
          </oes-project-map>
        </ng-container>
        <ng-template #NoProjects>
          {{ 'home-kpi.no-shared-project' | translate }}
        </ng-template>
      </div>
    </div>

    <!-- <ng-container *ngIf="selectedRole !== 'FINANCE_USER' || !hasCommercialInvestorDemoRole; else CommercialInvestorDemo"> -->
      <div class="metrics-right">
        <div class="metric-header">
          <h3>{{ 'home.portfolio-overview' | translate }}</h3>
        </div>

        <div class="kpis">
          <div class="kpi">
            <h5>{{ 'home.number-of-projects' | translate }}</h5>
            <div class="kpi-container">
              <div class="icon-container orange">
                <img src="/assets/icons/line-icons/nav/orange/grid-dots.svg" width="28" />
              </div>
              <div class="kpi-display">{{ platformVis?.organizationMetrics?.projectsCount }}</div>
            </div>
          </div>
          <div class="kpi">
            <h5>{{ 'home.project-portfolios' | translate }}</h5>
            <div class="kpi-container">
              <div class="icon-container orange">
                <img src="/assets/icons/line-icons/nav/orange/layers.svg" width="28" />
              </div>
              <div class="kpi-display">{{ platformVis?.organizationMetrics?.projectPortfoliosCount }}</div>
            </div>
          </div>
          <div class="kpi">
            <h5>{{ 'home.ci-sites' | translate }}</h5>
            <div class="kpi-container">
              <div class="icon-container yellow">
                <img src="/assets/icons/line-icons/nav/colors/building.svg" width="28" />
              </div>
              <div class="kpi-display">{{ platformVis?.organizationMetrics?.ciSitesCount }}</div>
            </div>
          </div>
          <div class="kpi">
            <h5>{{ 'home.ci-portfolios' | translate }}</h5>
            <div class="kpi-container">
              <div class="icon-container yellow">
                <img src="/assets/icons/line-icons/nav/colors/layers.svg" width="28" />
              </div>
              <div class="kpi-display">{{ platformVis?.organizationMetrics?.ciPortfoliosCount }}</div>
            </div>
          </div>
          <div class="kpi mb-0">
            <h5>{{ 'home.enrolled-programs' | translate }}</h5>
            <div class="kpi-container">
              <div class="icon-container blue">
                <img src="/assets/icons/line-icons/nav/colors/bank.svg" width="28" />
              </div>
              <div class="kpi-display">{{ platformVis?.organizationMetrics?.enrolledProgramsCount }}</div>
            </div>
          </div>
        </div>
      </div>
    <!-- </ng-container> -->



  </div>
</ng-container>
