import { AbstractControl } from '@angular/forms';

export function CheckAtLeastOneValidator(control: AbstractControl) {
  const checked = Object.keys(control.value).filter(key => control.value[key] === true);
  // invalid
  if (!checked || checked?.length === 0) {
    return { checkAtLeastOne: true };
  }
  // valid
  return null;
}
