import { Injectable } from '@angular/core';
import { User } from '@user/user.model';

declare global {
  interface Window { __insp: any; }
}

@Injectable({
  providedIn: 'root'
})
export class InspectletService {

  constructor() { }

  addInspectletInfo(user: User) {
    if (window.__insp) {
      let result = 'Username unknown';
      if (user.firstName && user.lastName) {
        result = `${user.firstName} ${user.lastName}`;
      } else if (user.emailAddress) {
        result = user.emailAddress;
      }
      window.__insp.push(['identify', result]);
    }
  }
}
