import { ProjectNav } from '../project.constant';
import { VendorMarketplaceNav } from '../vendor-marketplace.constant';
import { NavItem } from '../nav-item.model';
import { AnalyticsNav } from './analytics.constant';

export class VendorNav {
  public static item: NavItem[] = [
    ProjectNav.item,
    VendorMarketplaceNav.item,
    AnalyticsNav.item
  ];
}
