<div class="home" data-testid="ifc-home-container">
  <div class="body">
    <div class="header">
      <img src="/assets/images/ifc/ifc-home.png" width="400px">
    </div>
    <div class="ifc-content-container">
      <div class="ifc-content-left">
        <h4>{{ 'home.ifc.scale' | translate }}</h4>
        <p>{{ 'home.ifc.home-text-left' | translate }}</p>
      </div>
      <div>
          <h4>{{ 'home.ifc.accelerate' | translate }}</h4>
        <p>{{ 'home.ifc.home-text-right' | translate }}</p>
      </div>
    </div>
    <button class="btn btn-primary" [routerLink]="['../finance/programs']">
      {{ 'home-rea-2.go-to-programs' | translate }}
    </button>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
