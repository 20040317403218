import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureToggle } from '@global/feature-toggle/feature-toggle.model';
import { FeatureToggleConstant } from '@global/feature-toggle/feature-toggle.constant';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService extends BaseRestApiService {
  private featureToggles: any = {};

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public list(params: any = {page: 0, size: 100}): Observable<FeatureToggle[]> {
    return this.get<FeatureToggle[]>(FeatureToggleConstant.list(), {params: params})
      .pipe(map((featureToggles: FeatureToggle[]) => {
        return featureToggles.map(featureToggle => new FeatureToggle(featureToggle));
      }));
  }

  public load(featureToggleResponse: FeatureToggle[]) {
    this.featureToggles = featureToggleResponse.reduce((acc, featureToggle) => {
      acc[featureToggle.name] = featureToggle.enabled;
      return acc;
    }, {});
  }

  public getFeatureTogglesAsObject() {
    return this.featureToggles;
  }

  isFeatureEnabled(featureName: string): boolean {
    return this.featureToggles[featureName];
  }
}
