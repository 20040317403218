<div class="home" data-testid="brilho-home-container">
  <div class="body">
    <div class="body-styling-container">

      <img src="/assets/images/brilho/brilho-logo.png" width="396px" style="margin-bottom: 12px;">

      <div class="image-display-with-spacer">
        <div>
          <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/brilho1.jpg')"></div>
          <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/brilho2.jpg')"></div>
          <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/brilho3.jpg')"></div>
        </div>
      </div>

    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>


