import { Component } from '@angular/core';

@Component({
  selector: 'oes-organization-navigation',
  templateUrl: './organization-navigation.component.html',
  styleUrls: ['./organization-navigation.component.scss']
})
export class OrganizationNavigationComponent {

  constructor() {
  }
}

