import { Injectable } from '@angular/core';
import { ModulePermissions } from '@project/shared/module-permissions.model';
import { NavItem } from './navi-item/nav-item.model';
import { Project } from '@project/shared/project.model';
import { ProjectDetailMinigridNav } from './navi-item/project-detail-minigrid.constant';
import { ProjectService } from '@project/shared/project.service';
import { SideNavigationService } from '@main-navigation/side-navigation/side-navigation.service';
import { take } from 'rxjs/operators';
import { kebabCase } from 'lodash-es';
import { SideNavigationSecondaryService } from 'src/app/side-navigation-secondary/side-navigation-secondary.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectMenuService {
  constructor(private _sideNavigationSecondaryService: SideNavigationSecondaryService,
              private _projectService: ProjectService) {
  }

  public checkProject(baseUrl: string) {
    this.selectMenuByProjectType(baseUrl);
  }

  private selectMenuByProjectType(baseUrl: string) {
    const uuid = this.uuid(baseUrl);
    if (uuid?.length > 0) {
      this._projectService.detail(uuid[0])
      .pipe(take(1))
      .subscribe((project: Project) => {
        if (project) {
          this._sideNavigationSecondaryService.project = project;
          const applicationRequired = project?.program?.projectApplicationFormRequired;
          const modulePermissions: ModulePermissions = project?.projectPermissions?.project?.modulePermissions;
          const nav = this.buildNav(Object.assign([], ProjectDetailMinigridNav.item), applicationRequired, modulePermissions);
          this._sideNavigationSecondaryService.joinParentPath(baseUrl, nav, true);
        }
      });
    }
  }

  private buildNav(navItems: NavItem[], applicationRequired: boolean, modulePermissions: ModulePermissions): NavItem[] {
    navItems = this.filterNavByPermissions(navItems, modulePermissions);
    return this.filterForProjectApplicationRequired(navItems, applicationRequired);
  }

  private filterNavByPermissions(navItems: NavItem[], modulePermissions: ModulePermissions): NavItem[] {
    const keys = Object.keys(modulePermissions);
    keys.forEach(modulePermission => {
      if (!modulePermissions[modulePermission].view) {
        const removeIndex = navItems.findIndex(navItem => navItem.url === kebabCase(modulePermission));
        if (removeIndex >= 0) {
          navItems.splice(removeIndex, 1);
        }
      }
    });
    return navItems;
  }

  private filterForProjectApplicationRequired(navItems: NavItem[], applicationRequired: boolean): NavItem[] {
    return applicationRequired ? navItems : navItems.filter(item => item.url !== 'project-application');
  }

  private uuid(baseUrl: string): any {
    return baseUrl.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/);
  }
}
