<div class="home" data-testid="omdf-home-container">
  <div class="body">
    <div class="header mt-1 mb-3">
      <img src="/assets/images/omdf/omdf.png" width="340px">
    </div>
    <div class="image-display">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/mad1.jpg" />
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/mad2.jpg" />
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/mad3.jpg" />
    </div>
    <h4 class="mt-3 mb-1">{{ 'themes.partners' | translate }}:</h4>
    <div class="bg-container">
      <div class="logos mb-2">
        <img src="/assets/images/omdf/bcp.png" width="230px" class="mr-2">
        <img src="/assets/images/omdf/soc-gen.png" width="230px" class="ml-2">
      </div>
      <div class="logos mb-5">
        <img src="/assets/images/omdf/meh.jpg" width="115px" class="mr-2">
        <img src="/assets/images/omdf/world-bank.png" width="230px" class="ml-2">
      </div>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>

