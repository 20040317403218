<div class="home" data-testid="phares-home-container">
  <div class="body">
    <div class="logos">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/esmap-logo.png" width="250px">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/worldbank-logo.png" width="240px">
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>

