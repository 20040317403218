<div class="home" data-testid="ipff-home-container">
  <div class="body">
    <div class="body-styling-container">

      <img src="/assets/images/ipff/ipff-logo.png" width="396px" style="margin-bottom: 12px;">

      <div class="image-display-with-spacer">
        <div>
          <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/ipff-1.jpg')"></div>
          <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/ipff-2.jpg')"></div>
          <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/ipff-3.jpg')"></div>
        </div>
      </div>

      <h5>{{ 'home.partners' | translate }}:</h5>
      <div class="partner-images">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/ikea-foundation-logo.png" width="225px" style="margin-bottom: 12px;">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/cpi-logo.png" width="225px" style="margin-bottom: 12px;">
      </div>

    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
