import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-reaeei',
  templateUrl: './reaeei.component.html',
  styleUrls: ['./reaeei.component.scss']
})
export class ReaeeiComponent {

  constructor() { }

}
