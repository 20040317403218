<div class="home" data-testid="ceiafrica-home-container">
  <div class="body">
    <div class="header">
      <img src="/assets/images/ceiafrica/cei-logo-text.svg" width="400px">
    </div>
    <div class="home-3-image-container">
      <div class="tape"></div>
      <div class="home-image-1"></div>
      <div class="home-image-2"></div>
      <div class="home-image-3"></div>
    </div>
    <div class="home-description">
      <p>{{ 'home-ceiafrica.description-1' | translate }}</p>
      <p>{{ 'home-ceiafrica.description-2' | translate }}</p>
      <p>{{ 'home-ceiafrica.description-3' | translate }}</p>
      <a class="action-button" href="#/oes/finance/programs">
        {{ 'home-ceiafrica.action-button' | translate }}
      </a>
    </div>
    <div class="bg-container">
      <h4>{{ 'themes.partners' | translate }}</h4>
      <div class="logos mb-2">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/greenmax-logo.png" width="235px" class="mr-3">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/persistent-logo.png" width="95px">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/triple-jump-logo.png" width="235px" class="ml-3">
      </div>
      <div class="logos mb-5">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/kfw-logo.png" width="150px" class="mr-2">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/bmz-logo.png" width="175px" class="ml-2">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/swiss-logo.png" width="150px" class="ml-2">
      </div>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>

