import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-tdb',
  templateUrl: './tdb.component.html',
  styleUrls: ['./tdb.component.scss']
})
export class TdbComponent {

  constructor() { }

}
