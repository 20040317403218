import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {EventService} from '@shared/event.service';
import {TranslateService} from '@ngx-translate/core';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class TokenCheckGuard  {

  constructor(private _eventService: EventService,
              private _translateService: TranslateService,
              private _oauthService: OAuthService) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Not signed-in
    if (!this._oauthService.hasValidAccessToken()) {
      this._eventService.error(this._translateService.instant('error-message.please-login'));
      this._oauthService.logOut();
      return false;
    }
    return true;
  }
}
