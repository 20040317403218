<oes-internet-status></oes-internet-status>

<div class="nav-body">
  <oes-side-navigation></oes-side-navigation>
  <oes-side-navigation-secondary [ngClass]="secondaryNavOpen ? 'open' : ''"></oes-side-navigation-secondary>
  <div class="nav-main-container" *ngIf="loadingCompleted">
    <div class="main-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
