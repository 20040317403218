<ng-container [ngSwitch]="currentTheme">
  <ng-container *ngSwitchCase="theme.ANPER">
    <oes-theme-anper></oes-theme-anper>
  </ng-container>
  <ng-container *ngSwitchCase="theme.ASER">
    <oes-theme-aser></oes-theme-aser>
  </ng-container>
  <ng-container *ngSwitchCase="theme.CB">
    <oes-theme-cb></oes-theme-cb>
  </ng-container>
  <ng-container *ngSwitchCase="theme.DB">
    <oes-theme-db></oes-theme-db>
  </ng-container>
  <ng-container *ngSwitchCase="theme.ECREEE">
    <oes-theme-ecreee></oes-theme-ecreee>
  </ng-container>
  <ng-container *ngSwitchCase="theme.EEU">
    <oes-theme-eeu></oes-theme-eeu>
  </ng-container>
  <ng-container *ngSwitchCase="theme.EPP">
    <oes-theme-epp></oes-theme-epp>
  </ng-container>
  <ng-container *ngSwitchCase="theme.ESSOR">
    <oes-theme-essor></oes-theme-essor>
  </ng-container>
  <ng-container *ngSwitchCase="theme.GBECOTEDIVOIRE">
    <oes-theme-gbe-cotedivoire></oes-theme-gbe-cotedivoire>
  </ng-container>
  <ng-container *ngSwitchCase="theme.GBEBENIN">
    <oes-theme-gbe-benin></oes-theme-gbe-benin>
  </ng-container>
  <ng-container *ngSwitchCase="theme.BENIN">
    <oes-theme-benin></oes-theme-benin>
  </ng-container>
  <ng-container *ngSwitchCase="theme.IFC">
    <oes-theme-ifc></oes-theme-ifc>
  </ng-container>
  <ng-container *ngSwitchCase="theme.NERC">
    <oes-theme-nerc></oes-theme-nerc>
  </ng-container>
  <ng-container *ngSwitchCase="theme.PHARES">
    <oes-theme-phares></oes-theme-phares>
  </ng-container>
  <ng-container *ngSwitchCase="theme.IPFF">
    <oes-theme-ipff></oes-theme-ipff>
  </ng-container>
  <ng-container *ngSwitchCase="theme.CPI">
    <oes-theme-cpi></oes-theme-cpi>
  </ng-container>
  <ng-container *ngSwitchCase="theme.TDB">
    <oes-theme-tdb></oes-theme-tdb>
  </ng-container>
  <ng-container *ngSwitchCase="theme.DARES">
    <oes-theme-dares></oes-theme-dares>
  </ng-container>
  <ng-container *ngSwitchCase="theme.ESMAPHFE">
    <oes-theme-esmap-hfe></oes-theme-esmap-hfe>
  </ng-container>
  <ng-container *ngSwitchCase="theme.DREAMETHIOPIA">
    <oes-theme-dream-ethiopia></oes-theme-dream-ethiopia>
  </ng-container>
  <ng-container *ngSwitchCase="theme.BRILHO">
    <oes-theme-brilho></oes-theme-brilho>
  </ng-container>
  <ng-container *ngSwitchCase="theme.REA">
    <oes-theme-rea></oes-theme-rea>
  </ng-container>
  <ng-container *ngSwitchCase="theme.REAEEI">
    <oes-theme-reaeei></oes-theme-reaeei>
  </ng-container>
  <ng-container *ngSwitchCase="theme.REAREF">
    <oes-theme-rearef></oes-theme-rearef>
  </ng-container>
  <ng-container *ngSwitchCase="theme.RENSOURCE">
    <oes-theme-rensource></oes-theme-rensource>
  </ng-container>
  <ng-container *ngSwitchCase="theme.SOMALILAND">
    <oes-theme-somaliland></oes-theme-somaliland>
  </ng-container>
  <ng-container *ngSwitchCase="theme.NEA">
    <oes-theme-nea></oes-theme-nea>
  </ng-container>
  <ng-container *ngSwitchCase="theme.AMADER">
    <oes-theme-amader></oes-theme-amader>
  </ng-container>
  <ng-container *ngSwitchCase="theme.BURUNDI">
    <oes-theme-burundi></oes-theme-burundi>
  </ng-container>
  <ng-container *ngSwitchCase="theme.AMAP">
    <oes-theme-amap></oes-theme-amap>
  </ng-container>
  <ng-container *ngSwitchCase="theme.AGER">
    <oes-theme-ager></oes-theme-ager>
  </ng-container>
  <ng-container *ngSwitchCase="theme.RMI">
    <oes-theme-rmi></oes-theme-rmi>
  </ng-container>
  <ng-container *ngSwitchCase="theme.SUNAFRICA">
    <oes-theme-sun-africa></oes-theme-sun-africa>
  </ng-container>
  <ng-container *ngSwitchCase="theme.UEF">
    <oes-theme-uef></oes-theme-uef>
  </ng-container>
  <ng-container *ngSwitchCase="theme.EARF">
    <oes-theme-earf></oes-theme-earf>
  </ng-container>
  <ng-container *ngSwitchCase="theme.PAOP">
    <oes-theme-paop></oes-theme-paop>
  </ng-container>
  <ng-container *ngSwitchCase="theme.ABER">
    <oes-theme-aber></oes-theme-aber>
  </ng-container>
  <ng-container *ngSwitchCase="theme.ARENE">
    <oes-theme-arene></oes-theme-arene>
  </ng-container>
  <ng-container *ngSwitchCase="theme.TCHAD">
    <oes-theme-tchad></oes-theme-tchad>
  </ng-container>
  <ng-container *ngSwitchCase="theme.FUNAE">
    <oes-theme-funae></oes-theme-funae>
  </ng-container>
  <ng-container *ngSwitchCase="theme.OGEF">
    <oes-theme-ogef></oes-theme-ogef>
  </ng-container>
  <ng-container *ngSwitchCase="theme.CEIAFRICA">
    <oes-theme-ceiafrica></oes-theme-ceiafrica>
  </ng-container>
  <ng-container *ngSwitchCase="theme.POWERTRUST">
    <oes-theme-powertrust></oes-theme-powertrust>
  </ng-container>
  <ng-container *ngSwitchCase="theme.RREALIBERIA">
    <oes-theme-rrealiberia></oes-theme-rrealiberia>
  </ng-container>
  <ng-container *ngSwitchCase="theme.AVORENEWABLES">
    <oes-theme-avorenewables></oes-theme-avorenewables>
  </ng-container>
  <ng-container *ngSwitchCase="theme.OMDF">
    <oes-theme-omdf></oes-theme-omdf>
  </ng-container>
  <ng-container *ngSwitchCase="theme.A2EI">
    <oes-theme-a2ei></oes-theme-a2ei>
  </ng-container>
  <!-- Odyssey -->
  <ng-container *ngSwitchDefault>
    <oes-theme-odyssey></oes-theme-odyssey>
  </ng-container>
</ng-container>
