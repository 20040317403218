import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfileComponent } from '@user/profile/user-profile.component';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
import { HomeComponent } from './home/home.component';
import { OrganizationUnsavedGuard } from '@organization-management/organization/shared/guard/organization-unsaved.guard';
import { OrganizationNavigationComponent } from '@organization-management/organization/organization-navigation/organization-navigation.component';
import { OrganizationDeveloperPublicProfileComponent } from '@organization-management/organization/developer-information/public-profile/organization-developer-public-profile.component';
import { OrganizationDeveloperFurtherInformationComponent } from '@organization-management/organization/developer-information/further-information/organization-developer-further-information.component';
import { OrganizationInvestorPublicProfileComponent } from '@organization-management/organization/investor-information/public-profile/organization-investor-public-profile.component';
import { OrganizationInvestorFurtherInformationComponent } from '@organization-management/organization/investor-information/further-information/organization-investor-further-information.component';
import { OrganizationVendorPublicProfileComponent } from '@organization-management/organization/vendor-information/public-profile/organization-vendor-public-profile.component';
import { OrganizationProfileComponent } from '@organization-management/organization/organization-profile/organization-profile.component';
import { TokenCheckGuard } from '@global/token-check.guard';
import { DataManagerComponent } from '@organization-management/data-manager/data-manager.component';
import { UserAdminComponent } from '@shared/components/manage-users/user-admin.component';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { FormioFileUploaderComponent } from '@program/shared/formio-custom-components/formio-file-uploader/formio-file-uploader.component';
import { GlobalFilesComponent } from '@shared/components/files/global-files/global-files.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'oes',
    pathMatch: 'full',
  },
  {
    path: 'oes',
    component: MainNavigationComponent,
    canActivateChild: [TokenCheckGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeComponent,
        data: {
          breadcrumb: 'main-navigation.home',
        },
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
        data: {
          breadcrumb: 'user-profile.account-settings-header',
        },
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'organization-management',
        children: [
          {
            path: 'users',
            component: UserAdminComponent,
            data: {
              breadcrumb: 'main-navigation.organization-users',
            },
          },
          {
            path: 'data-manager',
            component: DataManagerComponent,
            data: {
              breadcrumb: 'main-navigation.data-manager',
            },
          },
          {
            path: 'profile',
            component: OrganizationNavigationComponent,
            data: {
              breadcrumb: '',
            },
            children: [
              {
                path: '',
                redirectTo: 'organization-profile',
                pathMatch: 'full',
              },
              {
                path: 'organization-profile',
                component: OrganizationProfileComponent,
                data: {
                  breadcrumb: 'profile-nav.org-profile',
                },
                canDeactivate: [OrganizationUnsavedGuard],
              },
              {
                path: 'developer',
                children: [
                  {
                    path: '',
                    redirectTo: 'public-profile',
                    pathMatch: 'full',
                  },
                  {
                    path: 'public-profile',
                    component: OrganizationDeveloperPublicProfileComponent,
                    data: {
                      breadcrumb: 'profile-nav.public-profile',
                    },
                    canDeactivate: [OrganizationUnsavedGuard],
                  },
                  {
                    path: 'further-information',
                    component: OrganizationDeveloperFurtherInformationComponent,
                    data: {
                      breadcrumb: 'profile-nav.further-info',
                    },
                    canDeactivate: [OrganizationUnsavedGuard],
                  },
                ],
              },
              {
                path: 'investor',
                children: [
                  {
                    path: '',
                    redirectTo: 'public-profile',
                    pathMatch: 'full',
                  },
                  {
                    path: 'public-profile',
                    component: OrganizationInvestorPublicProfileComponent,
                    data: {
                      breadcrumb: 'profile-nav.public-profile',
                    },
                    canDeactivate: [OrganizationUnsavedGuard],
                  },
                  {
                    path: 'further-information',
                    component: OrganizationInvestorFurtherInformationComponent,
                    data: {
                      breadcrumb: 'profile-nav.further-info',
                    },
                    canDeactivate: [OrganizationUnsavedGuard],
                  },
                ],
              },
              {
                path: 'vendor',
                children: [
                  {
                    path: '',
                    redirectTo: 'public-profile',
                    pathMatch: 'full',
                  },
                  {
                    path: 'public-profile',
                    component: OrganizationVendorPublicProfileComponent,
                    data: {
                      breadcrumb: 'profile-nav.public-profile',
                    },
                    canDeactivate: [OrganizationUnsavedGuard],
                  },
                ],
              },
            ],
          },
        ],
      },
      // Finance
      {
        path: 'finance',
        loadChildren: () =>
          import('./finance/finance.module').then((m) => m.FinanceModule),
        data: {
          breadcrumb: '',
        },
      },
      // Project
      {
        path: 'projects',
        loadChildren: () =>
          import('./project/project.module').then((m) => m.ProjectTabsModule),
        data: {
          breadcrumb: '',
        },
      },
      // RFQ (Quotes)
      {
        path: 'procurement',
        loadChildren: () =>
          import('./procurement/quote/quote.module').then((m) => m.QuoteModule),
        data: {
          breadcrumb: '',
        },
      },
      // Vendor Marketplace
      {
        path: 'vendor-marketplace',
        loadChildren: () =>
          import('./vendor-marketplace/vendor-marketplace.module').then(
            (m) => m.VendorMarketplaceModule
          ),
        data: {
          breadcrumb: '',
        },
      },
      // Files
      {
        path: 'files',
        component: GlobalFilesComponent,
        data: {
          breadcrumb: 'main-navigation.files',
        },
      },
      // Analytics
      {
        path: 'analytics',
        loadChildren: () =>
          import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
        data: {
          breadcrumb: '',
        },
      },
      {
        path: 'opportunities',
        loadChildren: () =>
          import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
        data: {
          breadcrumb: '',
        },
      },
    ],
  },
  // This is to ensure that this component is added to bundle
  {
    component: FormioFileUploaderComponent,
    path: 'dont-use-this-route-it-wont-work',
  },
  // Use this for 404 NOT FOUND
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // useHash: true,
      initialNavigation: 'disabled',
      urlUpdateStrategy: 'eager',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      enableTracing: false,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
