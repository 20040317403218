import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-gbe-cotedivoire',
  templateUrl: './gbe-cotedivoire.component.html',
  styleUrls: ['./gbe-cotedivoire.component.scss']
})
export class GbeCotedivoireComponent {

  constructor() { }

}
