<form #investorFurtherInfoForm="ngForm">
  <div>
    <div class="table-wrap">
      <oes-notify
        class="warning"
        [type]="'warning'"
        [iconName]="'fa-bullhorn'"
        [message]="'organization-information.investor.further-information.this-is-detailed-info'|translate">
      </oes-notify>

      <table *ngIf="investorInfo != null && investorInfo != undefined" class="mt-3">
        <tr>
          <td style="width: 350px;">
            <label for="yearsOfInvesting" class="bold">
              {{'organization-information.investor.further-information.how-many-years'|translate}}
            </label>
          </td>
          <td style="width: 200px;">
            <div class="input-group input-group--sm">
              <input type="number" class="form-control form-control--sm"
                     id="yearsOfInvesting" name="yearsOfInvesting"
                     placeholder="How many years have you been making investments?"
                     [(ngModel)]="investorInfo.yearsOfInvesting"/>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <label for="numberInvestmentsLastYear" class="bold">
              {{'organization-information.investor.further-information.total-number-of-investments'|translate}}:
            </label>
          </td>
          <td>
            <div class="input-group input-group--sm">
              <input type="number" class="form-control form-control--sm"
                     id="numberInvestmentsLastYear" name="numberInvestmentsLastYear"
                     placeholder="Total number of investments made last year"
                     [(ngModel)]="investorInfo.numberInvestmentsLastYear"/>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <label for="valueInvestmentsLastYear" class="bold">
              {{'organization-information.investor.further-information.total-amount-investments'|translate}}:
            </label>
          </td>
          <td>
            <div class="input-group input-group--sm">
              <div class="input-group-addon">$</div>
              <input type="number" class="form-control form-control--sm"
                     id="valueInvestmentsLastYear" name="valueInvestmentsLastYear"
                     placeholder="Total amount investments made last year"
                     [(ngModel)]="investorInfo.valueInvestmentsLastYear"/>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <label for="numberInvestmentsTotal" class="bold">
              {{'organization-information.investor.further-information.total-cumulative-number'|translate}}:
            </label>
          </td>
          <td>
            <div class="input-group input-group--sm">
              <input type="number" class="form-control form-control--sm"
                     id="numberInvestmentsTotal" name="numberInvestmentsTotal"
                     placeholder="Total cumulative number of investments"
                     [(ngModel)]="investorInfo.numberInvestmentsTotal"/>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <label for="valueInvestmentsTotal" class="bold">
              {{'organization-information.investor.further-information.total-cumulative-amount'|translate}}:
            </label>
          </td>
          <td>
            <div class="input-group input-group--sm">
              <div class="input-group-addon">$</div>
              <input type="number" class="form-control form-control--sm"
                     id="valueInvestmentsTotal" name="valueInvestmentsTotal"
                     placeholder="Total cumulative amount of investments"
                     [(ngModel)]="investorInfo.valueInvestmentsTotal"/>
            </div>
          </td>
        </tr>
      </table>

      <div class="mt-30">
        <h4>{{'organization-information.investor.further-information.rate'|translate}}:</h4>
        <div class="form-group ml-2" *ngIf="investorInfo != null && investorInfo != undefined">
          <label for="marketRate" class="input-control--sm input-control--checkbox mb-2">
            {{'organization-information.investor.further-information.market-rate'|translate}}
            <input type="checkbox" name="finance" id="marketRate"
                   [(ngModel)]="investorInfo.marketRate"/>
            <div class="input-control__indicator--sm"></div>
          </label>
          <label for="discountedMarketRate" class="input-control--sm input-control--checkbox mb-2">
            {{'organization-information.investor.further-information.discounted-market-rate'|translate}}
            <input type="checkbox" name="project-development" id="discountedMarketRate"
                   [(ngModel)]="investorInfo.discountedMarketRate"/>
            <div class="input-control__indicator--sm"></div>
          </label>
          <label for="zeroRate" class="input-control--sm input-control--checkbox mb-2">
            {{'organization-information.investor.further-information.zero-percent'|translate}}
            <input type="checkbox" name="hardware-software-supply" id="zeroRate"
                   [(ngModel)]="investorInfo.zeroRate"/>
            <div class="input-control__indicator--sm"></div>
          </label>
          <label for="grantRate" class="input-control--sm input-control--checkbox">
            {{'organization-information.investor.further-information.grant'|translate}}
            <input type="checkbox" name="epc" id="grantRate" [(ngModel)]="investorInfo.grantRate"/>
            <div class="input-control__indicator--sm"></div>
          </label>
        </div>

        <table *ngIf="investorInfo != null && investorInfo != undefined">
          <tr>
            <td style="width: 350px;">
              <label for="maximumTerm" class="bold">
                {{'organization-information.investor.further-information.maximum-term'|translate}}:
              </label>
            </td>
            <td style="width: 200px;">
              <input type="number" class="form-control form-control--sm" id="maximumTerm"
                     placeholder="years" name="maximumTerm"
                     [(ngModel)]="investorInfo.maximumTerm"/>
            </td>
          </tr>
          <tr>
            <td>
              <label for="minimumTerm" class="bold">
                {{'organization-information.investor.further-information.minimum-term'|translate}}:
              </label>
            </td>
            <td>
              <input type="number" class="form-control form-control--sm" id="minimumTerm"
                     placeholder="years" name="minimumTerm"
                     [(ngModel)]="investorInfo.minimumTerm"/>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="mt-2">
      <button class="btn btn-primary pull-right" type="button"
              (click)="saveChanges()"
              [disabled]="!userIsOrgAdmin">{{'buttons.save-changes'|translate}}
      </button>
    </div>
  </div>
</form>
<oes-unsaved-data-modal
  (saveDataEmitter)="saveChanges()"
  (discardChangesEmitter)="discardChanges()">
</oes-unsaved-data-modal>
