import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Organization } from '@shared/models/organization/organization.model';
import { EventService } from '@shared/event.service';
import { UserService } from '@user/user.service';
import { User } from '@user/user.model';
import { ROLE_TYPE } from '@user/role-type';
import { OrganizationService } from '../../organization.service';
import { Country } from '@shared/services/country/country.model';
import { InvestorInformation } from '../investor-information.model';
import { UnsavedDataModalComponent } from '@shared/components/unsaved-data-modal/unsaved-data-modal.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ComponentCanDeactivate } from '@organization-management/organization/shared/guard/component-can-deactivate.interface';
import { InvestorInformationService } from '../investor-information.service';
import { take } from 'rxjs/operators';
import { CheckAtLeastOneValidator } from './check-at-least-one-validator';
import { SelectCountryValidator } from './select-country-validator';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-organization-investor-public-profile',
  templateUrl: 'organization-investor-public-profile.component.html',
  styleUrls: ['organization-investor-public-profile.component.scss'],
})

export class OrganizationInvestorPublicProfileComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(UnsavedDataModalComponent, {static: false}) unSavedDataModalComponent: UnsavedDataModalComponent;
  @Input() showSaveCancelButtons = true;
  @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
  organization: Organization;
  investorInfo: InvestorInformation;
  user: User;
  userIsOrgAdmin = false;
  hasValidCapitalOffering: boolean;
  hasValidActivities: boolean;
  public formGroup: UntypedFormGroup;

  constructor(private _organizationService: OrganizationService,
              private _investorInformationService: InvestorInformationService,
              private _translateService: TranslateService,
              private _userService: UserService,
              private _eventService: EventService) {
  }


  ngOnInit() {
    this.getUser();
    this.getOrganization();
  }

  private getUser() {
    this.createForm();
    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe(user => {
      this.user = user;
      if (this.user.roles.filter(role => role.name === ROLE_TYPE.ORGANIZATION_ADMIN).length > 0) {
        this.userIsOrgAdmin = true;
      }
    });
  }

  private getOrganization() {
    this._organizationService.getMyOrganization()
      .subscribe((organization: Organization) => {
        this.organization = organization;
        this.getInvestorInfo();
      });
  }

  private getInvestorInfo() {
    this._investorInformationService.list(this.organization.id)
    .subscribe((investorInfo: InvestorInformation) => {
      this.investorInfo = investorInfo;
      this.setFormValue(investorInfo, this.organization);
    });
  }

  private createForm() {
    this.formGroup = new UntypedFormGroup({
      // required
      languagesSpoken: new UntypedFormControl('', Validators.required), // from organization object
      companyOverview: new UntypedFormControl('', Validators.required), // from organization object
      sectorExperience: new UntypedFormControl('', Validators.required),
      investmentObjectives: new UntypedFormControl('', Validators.required),
      allCountries: new UntypedFormControl('', [Validators.required, SelectCountryValidator]),
      countries: new UntypedFormControl(''),
      // select one at least
      concessionaryDebtCapital: new UntypedFormControl(),
      corporateEquityCapital: new UntypedFormControl(),
      debtCapital: new UntypedFormControl(),
      grantCapital: new UntypedFormControl(),
      projectEquityCapital: new UntypedFormControl(),
      // select one at least
      epcActivities: new UntypedFormControl(),
      financeActivities: new UntypedFormControl(),
      projectDevelopmentActivities: new UntypedFormControl(),
      supplyActivities: new UntypedFormControl(),

      // Capital
      capitalOfferingGroup: new UntypedFormGroup(
        {
          grantCapital: new UntypedFormControl(false),
          projectEquityCapital: new UntypedFormControl(false),
          corporateEquityCapital: new UntypedFormControl(false),
          debtCapital: new UntypedFormControl(false),
          concessionaryDebtCapital: new UntypedFormControl(false),
        },
        CheckAtLeastOneValidator
      ),
      // Activity
      activitiesGroup: new UntypedFormGroup(
        {
          financeActivities: new UntypedFormControl(false),
          projectDevelopmentActivities: new UntypedFormControl(false),
          supplyActivities: new UntypedFormControl(false),
          epcActivities: new UntypedFormControl(false),
        },
        CheckAtLeastOneValidator
      ),

      // other standard formControl
      minimumProjectSize: new UntypedFormControl(),
      noMinimumProjectSize: new UntypedFormControl(),
      maximumProjectSize: new UntypedFormControl(),
      noMaximumProjectSize: new UntypedFormControl(),
      preferredSize: new UntypedFormControl(),
      noPreferredSize: new UntypedFormControl(),
      preferredTerm: new UntypedFormControl(),
      noPreferredTerm: new UntypedFormControl(),
      investedInMicroGrids: new UntypedFormControl(),
    });
    this.subscribeSizes();
  }

  private subscribeSizes() {
    this.formGroup.controls['allCountries'].valueChanges
    .subscribe(value => {
      // selected "Any County"
      if (value) {
        this.formGroup.controls['countries'].reset();
      }
    });

    this.formGroup.controls['noMinimumProjectSize'].valueChanges
    .subscribe(value => {
      if (value) {
        this.formGroup.controls['minimumProjectSize'].reset();
        this.formGroup.controls['minimumProjectSize'].disable();
      } else {
        this.formGroup.controls['minimumProjectSize'].enable();
      }
    });
    this.formGroup.controls['noMaximumProjectSize'].valueChanges
    .subscribe(value => {
      if (value) {
        this.formGroup.controls['maximumProjectSize'].reset();
        this.formGroup.controls['maximumProjectSize'].disable();
      } else {
        this.formGroup.controls['maximumProjectSize'].enable();
      }
    });
    this.formGroup.controls['noPreferredSize'].valueChanges
    .subscribe(value => {
      if (value) {
        this.formGroup.controls['preferredSize'].reset();
        this.formGroup.controls['preferredSize'].disable();
      } else {
        this.formGroup.controls['preferredSize'].enable();
      }
    });
    this.formGroup.controls['noPreferredTerm'].valueChanges
    .subscribe(value => {
      if (value) {
        this.formGroup.controls['preferredTerm'].reset();
        this.formGroup.controls['preferredTerm'].disable();
      } else {
        this.formGroup.controls['preferredTerm'].enable();
      }
    });
  }

  private setFormValue(value: InvestorInformation, organization: Organization) {
    this.formGroup.controls['allCountries'].setValue(value.allCountries, {emitEvent: false});
    this.formGroup.controls['countries'].setValue(value.countries, {emitEvent: false});

    this.formGroup.controls['minimumProjectSize'].setValue(value.minimumProjectSize, {emitEvent: false});
    this.formGroup.controls['noMinimumProjectSize'].setValue(value.noMinimumProjectSize, {emitEvent: false});
    if (value.noMinimumProjectSize) {
      this.formGroup.controls['minimumProjectSize'].disable({emitEvent: false});
    }

    this.formGroup.controls['maximumProjectSize'].setValue(value.maximumProjectSize, {emitEvent: false});
    this.formGroup.controls['noMaximumProjectSize'].setValue(value.noMaximumProjectSize, {emitEvent: false});
    if (value.noMaximumProjectSize) {
      this.formGroup.controls['maximumProjectSize'].disable({emitEvent: false});
    }

    this.formGroup.controls['preferredSize'].setValue(value.preferredSize, {emitEvent: false});
    this.formGroup.controls['noPreferredSize'].setValue(value.noPreferredSize, {emitEvent: false});
    if (value.noPreferredSize) {
      this.formGroup.controls['preferredSize'].disable({emitEvent: false});
    }

    this.formGroup.controls['preferredTerm'].setValue(value.preferredTerm, {emitEvent: false});
    this.formGroup.controls['noPreferredTerm'].setValue(value.noPreferredTerm, {emitEvent: false});
    if (value.noPreferredTerm) {
      this.formGroup.controls['preferredTerm'].disable({emitEvent: false});
    }

    this.formGroup.controls['investedInMicroGrids'].setValue(value.investedInMicroGrids, {emitEvent: false});

    (<UntypedFormGroup>this.formGroup.controls['capitalOfferingGroup']).controls['grantCapital'].setValue(value.grantCapital, {emitEvent: false});
    (<UntypedFormGroup>this.formGroup.controls['capitalOfferingGroup']).controls['projectEquityCapital'].setValue(value.projectEquityCapital, {emitEvent: false});
    (<UntypedFormGroup>this.formGroup.controls['capitalOfferingGroup']).controls['corporateEquityCapital'].setValue(value.corporateEquityCapital, {emitEvent: false});
    (<UntypedFormGroup>this.formGroup.controls['capitalOfferingGroup']).controls['debtCapital'].setValue(value.debtCapital, {emitEvent: false});
    (<UntypedFormGroup>this.formGroup.controls['capitalOfferingGroup']).controls['concessionaryDebtCapital'].setValue(value.concessionaryDebtCapital, {emitEvent: false});
    (<UntypedFormGroup>this.formGroup.controls['activitiesGroup']).controls['financeActivities'].setValue(value.financeActivities, {emitEvent: false});
    (<UntypedFormGroup>this.formGroup.controls['activitiesGroup']).controls['projectDevelopmentActivities'].setValue(value.projectDevelopmentActivities, {emitEvent: false});
    (<UntypedFormGroup>this.formGroup.controls['activitiesGroup']).controls['supplyActivities'].setValue(value.supplyActivities, {emitEvent: false});
    (<UntypedFormGroup>this.formGroup.controls['activitiesGroup']).controls['epcActivities'].setValue(value.epcActivities, {emitEvent: false});

    this.formGroup.controls['languagesSpoken'].setValue(organization.languagesSpoken, {emitEvent: false});
  }

  private saveChanges() {
    this.getFormValue();
    this._organizationService.save(this.organization)
    .pipe(take(1))
    .subscribe(() => {
      this._investorInformationService.update(this.organization.id, this.investorInfo)
      .subscribe(() => {
        this.formGroup.markAsPristine();
        this._eventService.success(this._translateService.instant('success-message.changes-saved'));
        this.saved.emit(true);
        this.unSavedDataModalComponent.hide();
      });
    });
  }

  private getFormValue() {
    this.investorInfo.sectorExperience = this.formGroup.controls['sectorExperience']?.value?.textControl;
    this.investorInfo.investmentObjectives = this.formGroup.controls['investmentObjectives']?.value?.textControl;
    this.investorInfo.allCountries = this.formGroup.controls['allCountries'].value;
    this.investorInfo.countries = this.formGroup.controls['countries'].value;
    this.investorInfo.concessionaryDebtCapital = this.formGroup.controls['concessionaryDebtCapital'].value;
    this.investorInfo.corporateEquityCapital = this.formGroup.controls['corporateEquityCapital'].value;
    this.investorInfo.debtCapital = this.formGroup.controls['debtCapital'].value;
    this.investorInfo.grantCapital = this.formGroup.controls['grantCapital'].value;
    this.investorInfo.projectEquityCapital = this.formGroup.controls['projectEquityCapital'].value;
    this.investorInfo.epcActivities = this.formGroup.controls['epcActivities'].value;
    this.investorInfo.financeActivities = this.formGroup.controls['financeActivities'].value;
    this.investorInfo.projectDevelopmentActivities = this.formGroup.controls['projectDevelopmentActivities'].value;
    this.investorInfo.supplyActivities = this.formGroup.controls['supplyActivities'].value;
    this.investorInfo.minimumProjectSize = this.formGroup.controls['minimumProjectSize'].value;
    this.investorInfo.noMinimumProjectSize = this.formGroup.controls['noMinimumProjectSize'].value;
    this.investorInfo.maximumProjectSize = this.formGroup.controls['maximumProjectSize'].value;
    this.investorInfo.noMaximumProjectSize = this.formGroup.controls['noMaximumProjectSize'].value;
    this.investorInfo.preferredSize = this.formGroup.controls['preferredSize'].value;
    this.investorInfo.noPreferredSize = this.formGroup.controls['noPreferredSize'].value;
    this.investorInfo.preferredTerm = this.formGroup.controls['preferredTerm'].value;
    this.investorInfo.noPreferredTerm = this.formGroup.controls['noPreferredTerm'].value;
    this.investorInfo.investedInMicroGrids = this.formGroup.controls['investedInMicroGrids'].value;

    if (this.formGroup.controls['capitalOfferingGroup'].value) {
      Object.keys(this.formGroup.controls['capitalOfferingGroup'].value).forEach(key => {
        this.investorInfo[key] = this.formGroup.controls['capitalOfferingGroup'].value[key];
      });
    }
    if (this.formGroup.controls['activitiesGroup'].value) {
      Object.keys(this.formGroup.controls['activitiesGroup'].value).forEach(key => {
        this.investorInfo[key] = this.formGroup.controls['activitiesGroup'].value[key];
      });
    }

    this.organization.languagesSpoken = this.formGroup.controls['languagesSpoken']?.value;
    this.organization.companyOverview = this.formGroup.controls['companyOverview']?.value?.textControl;
  }

  public clearCountries() {
    this.investorInfo.countries.length = 0;
    this.formGroup.markAsDirty();
  }

  public countriesChanged(countries: Country[]) {
    this.formGroup.controls['countries'].setValue(countries, {emitEvent: false});
    this.formGroup.controls['allCountries'].updateValueAndValidity();
  }

  // ComponentCanDeactivate requires
  hasUnsavedData() {
    return this.formGroup.dirty && this.formGroup.touched;
  }

  // ComponentCanDeactivate requires
  showUnsavedDataModal(nextUrl: string) {
    this.unSavedDataModalComponent.show(nextUrl);
    return false;
  }

  discardChanges() {
    this.formGroup.markAsPristine();
  }

  public save() {
    this.saveChanges();
  }
}
