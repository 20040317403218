import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-ogef',
  templateUrl: './ogef.component.html',
  styleUrls: ['./ogef.component.scss']
})
export class OgefComponent {

  constructor() { }

}
