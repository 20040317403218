<div class="home" data-testid="benin-home-container">
  <div class="body">
    <div class="logos">
      <div class="d-flex justify-content-center logo-row align-items-center">
        <img src="/assets/images/benin/aberme.png" width="200px" height="200px"/>
        <img src="/assets/images/benin/moe.png" width="330px" height="95px"/>
      </div>
      <div class="d-flex justify-content-center logo-row align-items-center">
        <img src="/assets/images/benin/moh.png" width="330px" height="95px"/>
        <img src="/assets/images/benin/are.jpg" width="364px" height="60px"/>
      </div>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
