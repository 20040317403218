import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-esmap-hfe',
  templateUrl: './esmap-hfe.component.html',
  styleUrls: ['./esmap-hfe.component.scss']
})
export class EsmapHfeComponent {

  constructor() { }

}
