import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private _oauthService: OAuthService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers) {
      const auth = request.headers.get('Authorization');
      if (!auth || (auth && !auth.includes('Bearer '))) {
        // add a token to all http outgoings
        const token = this._oauthService.getAccessToken();
        if (token) {
          request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
        }
      }
    }

    // When you send a file, it will be FormData
    if (!(request.body instanceof FormData)) {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      }
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    return next.handle(request);
  }
}
