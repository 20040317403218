<div class="home" data-testid="phares-home-container">
  <div class="body">
    <div class="header">
      <img src="/assets/images/phares/phares-logo.png" width="340px">
    </div>
    <div class="image-display">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/phares1s.jpg" />
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/phares2s.jpg" />
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/phares3s.jpg" />
    </div>
    <div class="bg-container">
      <div class="logos">
        <img src="/assets/images/phares/mtptc-home.png" width="140px">
        <img src="/assets/images/phares/anarse-home.png" width="180px" height="140px">
      </div>
      <div class="">
        <button class="btn btn-primary" [routerLink]="['../finance/programs']">
          {{ 'buttons.apply' | translate }}
        </button>
      </div>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>

