import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-phares',
  templateUrl: './phares.component.html',
  styleUrls: ['./phares.component.scss']
})
export class PharesComponent {

  constructor() { }

}
