import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-ecreee',
  templateUrl: './ecreee.component.html',
  styleUrls: ['./ecreee.component.scss']
})
export class EcreeeComponent {

  constructor() { }

}
