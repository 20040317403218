import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-db',
  templateUrl: './db.component.html',
  styleUrls: ['./db.component.scss']
})
export class DbComponent {

  constructor() { }

}
