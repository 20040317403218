import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-aser',
  templateUrl: './aser.component.html',
  styleUrls: ['./aser.component.scss']
})
export class AserComponent {

  constructor() { }

}
