<ngx-bootstrap-multiselect 
  name="countriesPicker" 
  class="countries-picker"
  *ngIf="pickerOptions?.length > 0"
  [options]="pickerOptions"
  [settings]="pickerSettings"
  [texts]="pickerTexts"
  [(ngModel)]="selectedCountriesIndexes"
  (ngModelChange)="onChange($event)">
</ngx-bootstrap-multiselect>
