import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { InvestorInformationApiConstants } from './investor-information.constant';
import { InvestorInformation } from '@organization-management/organization/investor-information/investor-information.model';

@Injectable({
  providedIn: 'root'
})
export class InvestorInformationService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  // TODO: backend returns no array. What is the difference with detail and this endpoint
  public list(organizationId: string): Observable<InvestorInformation> {
    return this.get<InvestorInformation>(
            InvestorInformationApiConstants.investorInformation.list(organizationId),
            {params: {page: 0, size: 1000}}
           ).pipe(
             map(organization => new InvestorInformation(organization))
           );
  }

  public detail(organizationId: string, informationId: string): Observable<InvestorInformation> {
    return this.get<InvestorInformation>(
            InvestorInformationApiConstants.investorInformation.detail(organizationId, informationId)
           ).pipe(
             map(organization => new InvestorInformation(organization))
           );
  }


  public update(organizationId: string, investorInformation: InvestorInformation): Observable<any> {
    return this.put(
             InvestorInformationApiConstants.investorInformation.update(organizationId, investorInformation.id),
             JSON.stringify(investorInformation)
           );
  }
}
