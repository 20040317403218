<div class="d-flex flex-column program-homepage-card-container">
  <div class="card-header-box align-items-center">
    <h4 class="m-0 font-weight-bold">{{heading}}</h4>
  </div>
  <div class="card-content-box">
    <a class="d-flex" [href]="videoLink" target="_blank">
      <img class="program-homepage-icon" [src]="videoIcon" />
      <span>{{videoText}}</span>
    </a>
    <a class="d-flex" [href]="guideLink" target="_blank">
      <img class="program-homepage-icon" [src]="guideIcon" />
      <span class="program-homepage-text">{{guideText}}</span>
    </a>
  </div>
</div>
