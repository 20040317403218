<div class="home" data-testid="a2ei-home-container">
  <div class="body">
    <div class="header mt-1 mb-3">
      <img src="/assets/images/a2ei/a2ei-logo-wb.svg" width="450px">
    </div>
    <div class="image-display">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/A2EI-1.jpg" />
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/A2EI-2.jpg" />
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/A2EI-3.jpg" />
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>

