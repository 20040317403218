import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-burundi',
  templateUrl: './burundi.component.html',
  styleUrls: ['./burundi.component.scss']
})
export class BurundiComponent {

  constructor() { }

}
