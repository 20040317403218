import { ProjectNav } from '../project.constant';
import { ProcurementNav } from '../procurement.constant';
import { FinanceDeveloperNav } from '../finance-developer.constant';
import { NavItem } from '../nav-item.model';
import { OperateNav } from './operate-nav.constant';
import { AnalyticsNav } from './analytics.constant';

export class DeveloperNav {
  public static item: NavItem[] = [
    ProjectNav.item,
    FinanceDeveloperNav.item,
    ProcurementNav.item,
    OperateNav.item,
    AnalyticsNav.item
  ];
}
