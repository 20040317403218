import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-somaliland',
  templateUrl: './somaliland.component.html',
  styleUrls: ['./somaliland.component.scss']
})
export class SomalilandComponent {

  constructor() { }

}
