import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Organization } from '@shared/models/organization/organization.model';
import { EventService } from '@shared/event.service';
import { UserService } from '@user/user.service';
import { User } from '@user/user.model';
import { ROLE_TYPE } from '@user/role-type';
import { OrganizationService } from '../../organization.service';
import { VendorInformation } from '../vendor-information.model';
import { VendorInformationService } from '../vendor-information.service';
import { Country } from '@shared/services/country/country.model';
import { UnsavedDataModalComponent } from '@shared/components/unsaved-data-modal/unsaved-data-modal.component';
import { UntypedFormControl , UntypedFormGroup, Validators} from '@angular/forms';
import { ComponentCanDeactivate } from '@organization-management/organization/shared/guard/component-can-deactivate.interface';
import { DOCUMENT_TAG_TYPE } from '@shared/components/files/shared/document-tag-type.enum';
import { FilesActions } from '@shared/components/files/shared/models/action.model';
import { TagType } from '@shared/components/files/shared/models/tag-type.model';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-organization-vendor-public-profile',
  templateUrl: 'organization-vendor-public-profile.component.html',
  styleUrls: ['organization-vendor-public-profile.component.scss'],
})

export class OrganizationVendorPublicProfileComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(UnsavedDataModalComponent, {static: false}) unSavedDataModalComponent: UnsavedDataModalComponent;
  @Input() showSaveCancelButtons = true;
  @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
  public organization: Organization;
  public vendorInfo: VendorInformation;
  private user: User;
  public userIsOrgAdmin = false;
  public tagTypes: TagType[];
  public formGroup: UntypedFormGroup;

  constructor(private _organizationService: OrganizationService,
              private _vendorInformationService: VendorInformationService,
              private _translateService: TranslateService,
              private _userService: UserService,
              private _eventService: EventService) {
  }

  ngOnInit() {
    this.createForm();
    this._organizationService.getMyOrganization()
    .pipe(take(1))
    .subscribe(organization => {
      this.organization = new Organization(organization);
      this._vendorInformationService.list(this.organization.id)
      .pipe(take(1))
      .subscribe((vendorInfo: VendorInformation) => {
        this.vendorInfo = vendorInfo;
        this.setFormValue();
        this.validateProductOffering();
        this.getUser();
      });
    });
  }

  private createForm() {
    this.formGroup = new UntypedFormGroup({
      companyOverview: new UntypedFormControl('', Validators.required), // from organization object
      productSupplies: new UntypedFormControl(false, Validators.required),
      marketsOfSaleCountries: new UntypedFormControl(),
      marketsOfSupportCountries: new UntypedFormControl(),
      marketsOfDeliveryCountries: new UntypedFormControl(),
      productOffering: new UntypedFormControl(),
      totalProductsDeployed: new UntypedFormControl(),
    });
  }

  private setFormValue() {
    this.formGroup.controls['marketsOfSaleCountries'].setValue(this.vendorInfo.marketsOfSaleCountries, {emitEvent: false});
    this.formGroup.controls['marketsOfSupportCountries'].setValue(this.vendorInfo.marketsOfSupportCountries, {emitEvent: false});
    this.formGroup.controls['marketsOfDeliveryCountries'].setValue(this.vendorInfo.marketsOfDeliveryCountries, {emitEvent: false});
    this.formGroup.controls['totalProductsDeployed'].setValue(this.vendorInfo.totalProductsDeployed, {emitEvent: false});
  }

  private getUser() {
    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe(user => {
      this.user = user;
      if (this.user.roles.filter(role => role.name === ROLE_TYPE.ORGANIZATION_ADMIN).length) {
        this.userIsOrgAdmin = true;
        this.tagTypes = [{
          docTagItemId: this.vendorInfo.id,
          docTagType: DOCUMENT_TAG_TYPE.VENDOR_INFORMATION,
          name: (DOCUMENT_TAG_TYPE.VENDOR_INFORMATION).replace('_', ' ').toLowerCase()
        }, {
          docTagItemId: this.organization.id,
          docTagType: DOCUMENT_TAG_TYPE.ORGANIZATION,
          name: user.organization.name
        }];
      }
    });
  }

  get accessControl (): any {
    return new FilesActions({
      copy: true,
      create: true,
      delete: true,
      download: true,
      move: true,
      upload: true,
      tag: true,
      editName: true,
      preview: true
    });
  }

  private saveChanges() {
    this.organization.companyOverview = this.formGroup.controls['companyOverview'].value.textControl;
    this._organizationService
      .save(this.organization)
      .subscribe(() => {
        this.vendorInfo.marketsOfSaleCountries = this.formGroup.controls['marketsOfSaleCountries'].value;
        this.vendorInfo.marketsOfSupportCountries = this.formGroup.controls['marketsOfSupportCountries'].value;
        this.vendorInfo.marketsOfDeliveryCountries = this.formGroup.controls['marketsOfDeliveryCountries'].value;
        this.vendorInfo.productOffering = this.formGroup.controls['productOffering'].value.textControl;
        this.vendorInfo.totalProductsDeployed = this.formGroup.controls['totalProductsDeployed'].value;
        this._vendorInformationService.update(this.organization.id, this.vendorInfo)
        .subscribe(() => {
          this._eventService.success(this._translateService.instant('success-message.changes-saved'));
          this.formGroup.markAsPristine();
          this.saved.emit(true);
          this.unSavedDataModalComponent.hide();
        });
      });
  }

  updateCountries(countries: Country[], key: string) {
    this.vendorInfo[key] = countries;
    this.formGroup.controls[key].setValue(countries, {emitEvent: false});
  }

  // TODO: Update oes-product-select because it overwrite a parent's variables.
  public validateProductOffering(touched = false) {
    if (this.vendorInfo) {
      if (this.vendorInfo.offerAppliances ||
          this.vendorInfo.offerConsultingServices ||
          this.vendorInfo.offerControllers ||
          this.vendorInfo.offerEpcServices ||
          this.vendorInfo.offerIntegratedKits ||
          this.vendorInfo.offerMeters ||
          this.vendorInfo.offerOmSolutions ||
          this.vendorInfo.offerPvInverters ||
          this.vendorInfo.offerPvPanels ||
          this.vendorInfo.offerStorage ||
          this.vendorInfo.offerStorageConverters ||
          this.vendorInfo.offerTurnkeySystems) {
        this.formGroup.controls['productSupplies'].setValue(true);
        if (touched) {
          this.formGroup.controls['productSupplies'].markAsTouched();
        }
      } else {
        this.formGroup.controls['productSupplies'].reset();
        if (touched) {
          this.formGroup.controls['productSupplies'].markAsTouched();
        } else {
          this.formGroup.controls['productSupplies'].markAsPristine();
        }
      }
    }
  }

  // ComponentCanDeactivate requires
  hasUnsavedData() {
    return this.formGroup.dirty && this.formGroup.touched;
  }

  // ComponentCanDeactivate requires
  showUnsavedDataModal(nextUrl: string) {
    this.unSavedDataModalComponent.show(nextUrl);
    return false;
  }

  discardChanges() {
    this.formGroup.markAsPristine();
  }

  public save() {
    this.saveChanges();
  }
}
