import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-eeu',
  templateUrl: './eeu.component.html',
  styleUrls: ['./eeu.component.scss']
})
export class EeuComponent {

  constructor() { }

}
