import { NavItem } from '../nav-item.model';

export class PortfolioDetailDeveloperNav {
  public static item: NavItem[] = [
    {
      title: 'project-group-navigation.project-details',
      url: 'project-overview',
      icon: '<img src="./assets/icons/line-icons/nav/grey/overview.svg" width="23px" height="23px">',
      testId: 'portfolio-nav-project-data',
    },
    // {
    //   title: 'project-group-navigation.introduction',
    //   url: 'introduction',
    //   icon: '<img src="./assets/images/main-nav/portfolio/introduction.svg" width="23px" height="23px">',
    //   testId: 'portfolio-nav-introduction',
    // },
    {
      title: 'project-group-navigation.business-plan.business-plan',
      url: 'business-plan',
      testId: 'portfolio-nav-business-plan',
      icon: '<img src="./assets/icons/line-icons/nav/grey/business-plan.svg" width="23px" height="23px">',
    },
    {
      title: 'project-group-navigation.data-room',
      url: 'data-room',
      icon: '<img src="./assets/icons/line-icons/nav/grey/files.svg" width="23px" height="23px">',
      testId: 'portfolio-nav-data-room',
    },
    {
      title: 'project-group-navigation.submission',
      url: 'submission',
      icon: '<img src="./assets/icons/line-icons/nav/grey/submission.svg" width="23px" height="23px">',
      testId: 'portfolio-nav-submission',
    },
    {
      title: 'project-group-navigation.summary',
      icon: '<img src="./assets/icons/line-icons/nav/grey/summary.svg" width="23px" height="23px">',
      url: 'summary',
      testId: 'portfolio-nav-summary',
    },
  ];
}
