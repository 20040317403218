import { environment } from '@environments/environment';

export class VisualizationProfileApiConstants {
  public static visualization = environment.serverUri + `api/${environment.apiVersion}/visualization-profile`;
  public static kpi = {
    create: () => {
      return `${VisualizationProfileApiConstants.visualization}`;
    },
    list: () => {
      return `${VisualizationProfileApiConstants.visualization}`;
    },
    get: (id: string) => {
      return `${VisualizationProfileApiConstants.visualization}/${id}`;
    },
    update: (id: string) => {
      return `${VisualizationProfileApiConstants.visualization}/${id}`;
    },
    delete: (id: string) => {
      return `${VisualizationProfileApiConstants.visualization}/${id}`;
    },
    select: (id: string) => {
      return `${VisualizationProfileApiConstants.visualization}/${id}/select`;
    }
  };
}
