import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-benin',
  templateUrl: './benin.component.html',
  styleUrls: ['./benin.component.scss']
})
export class BeninComponent {

  constructor() { }

}
