import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-rearef',
  templateUrl: './rearef.component.html',
  styleUrls: ['./rearef.component.scss']
})
export class RearefComponent {

  constructor() { }

}
