import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';

@Component({
  selector: 'oes-theme-rea',
  templateUrl: './rea.component.html',
  styleUrls: ['./rea.component.scss']
})
export class ReaComponent implements AfterViewInit {
  @ViewChild(ModalDialogComponent, {static: false}) public reaHomeModal: ModalDialogComponent;

  reaRoleCards: any;

  constructor(private _translateService: TranslateService) {
    this.reaRoleCards = this._translateService.instant('home-rea-2.cards');
  }

  ngAfterViewInit() {
    if (!this.checkIfModalSeen('rea')) {
      this.reaHomeModal.show();
    }
  }

  private checkIfModalSeen(modal: string) {
    return localStorage.getItem(`${modal}_home_modal_seen`) === 'true' ? true : false;
  }

  saveModalResponse(modal: string) {
    localStorage.setItem(`${modal}_home_modal_seen`, 'true');
  }
}
