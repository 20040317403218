<div class="data-manager d-flex flex-column">
  <div class="header">
    <h2>{{ 'bulk-data.heading' | translate }}</h2>
    <div class="buttons-container ml-auto">
      <div class="upload-btn-wrapper">
        <label class="btn btn-primary" for="upload-btn">{{'bulk-data.import-data'|translate}}</label>
        <input type="file" id="upload-btn" name="attached_file" ng2FileSelect [uploader]="uploader" (change)="onUploadChange($event)" />
      </div>
    </div>
  </div>
  <oes-grid-sub-action
    *ngIf="gridApi"
    [gridState]="gridState"
    [defaultGridState]="defaultGridState"
    (action)="subAction($event)">
  </oes-grid-sub-action>
  <div class="ag-grid-header-sticky" oesAgHorizontalScroll>
    <ag-grid-angular
      #agGridDataManager
      id="agGridDataManager"
      style="width: auto; height: calc(100vh - 260px);"
      class="grid ag-theme-alpine"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (filterChanged)="gridStatusChanged($event, 'filter')"
      (sortChanged)="gridStatusChanged($event, 'sort')"
      (displayedColumnsChanged)="gridStatusChanged($event, 'column')">
    </ag-grid-angular>
  </div>
</div>
