<div class="home">
  <div class="header">
    <div>
      <img src="/assets/images/essor/ukaid.png" width="92px">
      <img src="/assets/images/essor/drc-flag.png" width="145px">
    </div>
    <img src="/assets/images/essor/essor.png" width="287px">
  </div>
  <div class="body">
    <p>
      Appel d'offres pour la conception, le développement, le financement, la construction,
      l’exploitation, l’entretien et la maintenance de projets de mini-réseaux solaires dans
      les villes de Géména, Bumba et Isiro en République Démocratique du Congo.
    </p>

    <a href="https://help.odysseyenergysolutions.com/portal/kb/odyssey-energy-solutions-knowledge-base/essor"
       target="_blank">Manuel d’utilisation</a>

    <div class="">
      <h4>Allez aux <a [routerLink]="['../finance/programs']">Programmes</a> pour commencer.</h4>
      <div>Vous pouvez envoyer vos questions à l’adresse
        <span class="color-brand">
          <a href="mailto: electricity@essor-rdc.org">electricity&#64;essor-rdc.org</a>.
        </span>
      </div>
    </div>
  </div>
</div>