import { MainMenuCategory } from '../main-menu-category.enum';

export class CommercialInvestorDemoOriginateNav {
  public static item = {
    title: 'main-navigation.originate',
    icon: '<img src="./assets/icons/line-icons/nav/colors/compass.svg" width="24px" height="24px">',
    themedIcon: {
      avorenewables: '<img src="./assets/images/avorenewables/icons/compass.svg" width="24px" height="24px">'
    },
    testId: 'projects-nav-link',
    type: MainMenuCategory.plan,
    bold: true,
    children: [
      {
        title: 'main-navigation.pipeline-analytics',
        url: '/oes/opportunities/dashboards',
        queryParams: {
          dashboardId: 'f93ea79c-2abc-44da-b4f4-de8795a96303'
        },
        testId: 'pipeline-analytics-nav-link',
      },
      {
        title: 'main-navigation.projects-in-pipeline',
        url: '/oes/projects/shared-projects',
        queryParams: {
          type: 'list'
        },
        testId: 'shared-projects-nav-link',
      },
      {
        title: 'main-navigation.portfolios',
        url: '/oes/finance/finance-marketplace',
        testId: 'portfolio-nav-link',
      },
    ]
  };
}
