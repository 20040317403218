import { Injectable } from '@angular/core';
import { NavItem } from './navi-item/nav-item.model';
import { UserService } from '@user/user.service';

@Injectable({
  providedIn: 'root'
})
export class RoleCheckService {

  constructor(private _userService: UserService) {
  }

  public check(navItems: NavItem[]): NavItem[] {
    const newNavItems: NavItem[] = [];
    navItems.forEach(item => {
      if (item.roles?.length > 0) {
        const result = item.roles.filter(role => this._userService.hasRole(role));
        // multiple roles are "OR"
        if (result?.length > 0) {
          if (item.children?.length > 0) {
            item.children = this.check(item.children);
          }
          newNavItems.push(item);
        }
      } else {
        if (item.children?.length > 0) {
          item.children = this.check(item.children);
        }
        newNavItems.push(item);
      }
    });
    return newNavItems;
  }

}
