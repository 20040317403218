import { Injectable } from '@angular/core';
import { ProgramService } from '@program/shared/program.service';
import { ROLE_TYPE } from '@user/role-type';
import { take } from 'rxjs/operators';
import { SideNavigationService } from '@main-navigation/side-navigation/side-navigation.service';
import { PROGRAM_RULE_TYPES } from '@program/shared/program-rule-types.enum';
import { ProgramPortfolioRules } from '@program/shared/program-project-rules.model.1';
import { ProjectGroupService } from '@shared/services/project-group.service';
import { ProjectGroup } from '@shared/services/project-group.model';
import { PortfolioDetailDeveloperNav } from './navi-item/portfolio-detail/developer.constant';
import { PROGRAM_PORTFOLIO_MODULE_FLAGS } from '@program/shared/program-portfolio-module-flags.enum';
import { PortfolioDetailInvestorNav } from './navi-item/portfolio-detail/investor.constant';
import { NavItem } from './navi-item/nav-item.model';
import { FinanceMarketplaceService } from '@finance/investor-portfolio/shared/finance-marketplace.service';
import { ProjectSetPortfolioDetailInvestorNav } from './navi-item/project-set-portfolio-detail/investor.constant';
import { ProjectSetPortfolioDetailDeveloperNav } from './navi-item/project-set-portfolio-detail/developer.constant';
import { ProjectSetPortfolioService } from '@project/offtakers/project-set-portfolio/project-set-portfolio.service';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { SideNavigationSecondaryService } from 'src/app/side-navigation-secondary/side-navigation-secondary.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioMenuService {

  constructor(private _programService: ProgramService,
              private _sideNavigationSecondaryService: SideNavigationSecondaryService,
              private _financeMarketplaceService: FinanceMarketplaceService,
              private _projectSetPortfolioService: ProjectSetPortfolioService,
              private _projectGroupService: ProjectGroupService) {
  }

  selectMenuByProgram(selectedRole: ROLE_TYPE, baseUrl: string) {
    const uuid = baseUrl.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/);
    switch (selectedRole) {
      case ROLE_TYPE.DEVELOPER_USER:
        this.developerNav(uuid[0], baseUrl);
        break;
      case ROLE_TYPE.FINANCE_USER:
        this.financeNav(uuid[0], baseUrl);
        break;
      default:
        break;
    }
  }

  getCiPortfolioNavByRole(selectedRole: ROLE_TYPE, baseUrl: string) {
    const uuid = baseUrl.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/);
    this._projectSetPortfolioService.detail(uuid[0])
      .pipe(take(1))
      .subscribe((psp: ProjectSetPortfolio) => {
        if (psp?.program?.id) {
          this._programService.getProgramRules(psp.program.id, PROGRAM_RULE_TYPES.PORTFOLIO_RULE)
            .pipe(take(1))
            .subscribe((rules: ProgramPortfolioRules) => {
              switch (selectedRole) {
                case ROLE_TYPE.FINANCE_USER: {
                  const filteredPortfolioInvestorNav = this.filterPortfolioNav(ProjectSetPortfolioDetailInvestorNav.item, rules);
                  this._sideNavigationSecondaryService.joinParentPath(baseUrl, filteredPortfolioInvestorNav);
                  break;
                }
                case ROLE_TYPE.DEVELOPER_USER: {
                  const filteredPortfolioDeveloperNav = this.filterPortfolioNav(ProjectSetPortfolioDetailDeveloperNav.item, rules);
                  this._sideNavigationSecondaryService.joinParentPath(baseUrl, filteredPortfolioDeveloperNav);
                  break;
                }
                default:
                  break;
              }
            });
          } else {
            const rules: ProgramPortfolioRules = new ProgramPortfolioRules({});
            switch (selectedRole) {
              case ROLE_TYPE.FINANCE_USER: {
                const filteredPortfolioInvestorNav = this.filterPortfolioNav(ProjectSetPortfolioDetailInvestorNav.item, rules);
                this._sideNavigationSecondaryService.joinParentPath(baseUrl, filteredPortfolioInvestorNav);
                break;
              }
              case ROLE_TYPE.DEVELOPER_USER: {
                const filteredPortfolioDeveloperNav = this.filterPortfolioNav(ProjectSetPortfolioDetailDeveloperNav.item, rules);
                this._sideNavigationSecondaryService.joinParentPath(baseUrl, filteredPortfolioDeveloperNav);
                break;
              }
              default:
                break;
            }
          }
        });

  }

  private financeNav(projectGroupId: string, baseUrl: string) {
    this._financeMarketplaceService.getProjectGroup(projectGroupId)
    .pipe(take(1))
    .subscribe((projectGroup: ProjectGroup) => {
      if (!projectGroup.program || !projectGroup.program.id) {
        this._sideNavigationSecondaryService.joinParentPath(baseUrl, PortfolioDetailInvestorNav.item);
      } else {
        this._sideNavigationSecondaryService.portfolio = projectGroup;
        this._programService.getProgramRules(projectGroup.program.id, PROGRAM_RULE_TYPES.PORTFOLIO_RULE)
        .pipe(take(1))
        .subscribe((rules: ProgramPortfolioRules) => {
          const filteredPortfolioNav = this.filterPortfolioNav(PortfolioDetailInvestorNav.item, rules);
          this._sideNavigationSecondaryService.joinParentPath(baseUrl, filteredPortfolioNav);
        });
      }
    });
  }

  private developerNav(projectGroupId: string, baseUrl: string) {
    this._projectGroupService.detail(projectGroupId)
    .pipe(take(1))
    .subscribe((projectGroup: ProjectGroup) => {
      if (!projectGroup.program || !projectGroup.program.id) {
        this._sideNavigationSecondaryService.joinParentPath(baseUrl, PortfolioDetailDeveloperNav.item);
      } else {
        this._sideNavigationSecondaryService.portfolio = projectGroup;
        this._programService.getProgramRules(projectGroup.program.id, PROGRAM_RULE_TYPES.PORTFOLIO_RULE)
        .pipe(take(1))
        .subscribe((rules: ProgramPortfolioRules) => {
          const filteredPortfolioNav = this.filterPortfolioNav(PortfolioDetailDeveloperNav.item, rules);
          this._sideNavigationSecondaryService.joinParentPath(baseUrl, filteredPortfolioNav);
        });
      }
    });
  }

  private filterPortfolioNav(nav: any, rules: ProgramPortfolioRules): NavItem[] {
    return nav.filter(navItem => {
      switch (navItem.url) {
        case 'business-plan':
          return rules.businessPlanModuleRequirementFlag === PROGRAM_PORTFOLIO_MODULE_FLAGS.REQUIRED;
        case 'data-room':
          return rules.dataRoomModuleRequirementFlag === PROGRAM_PORTFOLIO_MODULE_FLAGS.REQUIRED;
        case 'submission':
          return rules.portfolioSubmissionModuleRequirementFlag === PROGRAM_PORTFOLIO_MODULE_FLAGS.REQUIRED;
        case 'project-overview':
          return rules.projectOverviewModuleRequirementFlag === PROGRAM_PORTFOLIO_MODULE_FLAGS.REQUIRED;
        case 'summary':
          return rules.summaryModuleRequirementFlag === PROGRAM_PORTFOLIO_MODULE_FLAGS.REQUIRED;
        default:
          return true;
      }
    });
  }
}
