<div class="dashboard">

  <!-- Logo and Org Name -->
  <div class="home-header">
    <div class="org-logo">
      <ng-container *ngIf="logoImage?.id; else NoLogo">
        <img src="{{ cdnUri }}/{{ logoImage.id }}" />
      </ng-container>
      <ng-template #NoLogo>
        <ng-container *ngIf="orgAdmin">
          <a [routerLink]="['../organization-management/profile/organization-profile']" class="no-logo-empty-state">
            {{ 'home.add-logo' | translate }}
          </a>
        </ng-container>
      </ng-template>
    </div>
    <h3>{{ user?.organization?.name }}</h3>
  </div>

  <!-- Banner -->
  <oes-nav-header [isHome]="true"></oes-nav-header>

  <div class="main-container">
    <h3>{{ 'home.quick-links' | translate }}</h3>
    <div class="link-boxes">

      <!-- Programs -->
      <div class="link-box">
        <div class="link-left">
          <div class="left-icon soft-blue">
            <img src="/assets/icons/line-icons/nav/colors/bank.svg" width="20" />
          </div>
        </div>
        <div class="link-right">
          <h4>{{ 'home.explore-financing-programs' | translate }}</h4>
          <p>{{ 'home.explore-financing-programs-sub' | translate }}</p>
          <a [routerLink]="['../finance/programs']" class="orange-link link-plain">{{ 'home.explore-programs' | translate }}<img class="arrow" src="/assets/icons/line-icons/shared/orange/arrow-right.svg" /></a>
        </div>
      </div>

      <!-- Procurement -->
      <div class="link-box">
        <div class="link-left">
          <div class="left-icon lavender-purple">
            <img src="/assets/icons/line-icons/nav/colors/package.svg" width="20" />
          </div>
        </div>
        <div class="link-right">
          <h4>{{ 'home.procure-equipment' | translate }}</h4>
          <p>{{ 'home.procure-equipment-sub' | translate }}</p>
          <a href="https://procure.odyssey.energy/" target="_blank" class="orange-link link-plain">{{ 'home.browse-equipment' | translate }}<img class="arrow up-right" src="/assets/icons/line-icons/shared/orange/arrow-right.svg" /></a>
        </div>
      </div>

      <!-- Monitoring -->
      <div class="link-box">
        <div class="link-left">
          <div class="left-icon dry-green">
            <img src="/assets/icons/line-icons/nav/colors/activity.svg" width="20" />
          </div>
        </div>
        <div class="link-right">
          <h4>{{ 'home.remote-monitoring' | translate }}</h4>
          <p>{{ 'home.remote-monitoring-sub' | translate }}</p>
          <a href="https://fernview.odysseyenergysolutions.com/" target="_blank" class="orange-link link-plain">{{ 'home.view-dashboard' | translate }}<img class="arrow up-right" src="/assets/icons/line-icons/shared/orange/arrow-right.svg" /></a>
        </div>
      </div>
    </div>

    <!-- Organization Metrics -->
    <oes-org-metrics
      [hasCommercialInvestorDemoRole]="hasCommercialInvestorDemoRole"
      [selectedRole]="selectedRole"
      class="mb-5"
      [mapProjects]="mapProjects"
      [platformVis]="platformVis">
    </oes-org-metrics>

    <!-- What's happening in Odyssey? -->
    <oes-odyssey-platform
      [selectedRole]="selectedRole"
      class="mb-5"
      [platformVis]="platformVis">
    </oes-odyssey-platform>

  </div>
</div>

<!-- create/edit dialog -->
<oes-modal-dialog
  [title]="'home-kpi.dialog-title' | translate"
  [submitButtonText]="'buttons.add' | translate"
  [allowSubmit]="formGroup?.valid"
  (submit)="addMetric()"
  (close)="close()">
  <div [formGroup]="formGroup" class="form">
    <!-- order -->
    <label>
      {{'home-kpi.order' | translate}}
    </label>
    <input
      class="field"
      type="text"
      formControlName="order">
    <!-- title -->
    <label>
      {{'home-kpi.title' | translate}}
    </label>
    <input
      class="field"
      type="text"
      formControlName="title">
    <!-- type -->
    <label class="required">
      {{'home-kpi.type' | translate}}
    </label>
    <div class="select-wrapper field">
      <select
        class="form-control"
        formControlName="type">
        <option
          *ngFor="let type of types"
          [ngValue]="type">
          {{type}}
        </option>
      </select>
    </div>
    <!-- Kibana index name -->
    <label class="required">
      {{'home-kpi.index-name' | translate}}
    </label>
    <input
      class="field"
      type="text"
      formControlName="kibanaIndex">
    <!-- query -->
    <label class="required">
      {{'home-kpi.query' | translate}}
    </label>
    <textarea
      class="field"
      type="text"
      formControlName="query">
    </textarea>
  </div>
</oes-modal-dialog>
