<div class="home" data-testid="cpi-home-container">
  <div class="header-images">
    <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/cpi-1.png')"></div>
    <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/cpi-2.png')"></div>
    <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/cpi-3.png')"></div>
  </div>

  <div class="cpi-content-top">
    <img src="/assets/images/cpi/cpi-logo.png" width="200px" />
    <p>Climate Policy Initiative is an analysis and advisory organization with deep expertise in finance and policy. Our
      mission is to support governments, businesses, and financial institutions in driving economic growth while
      addressing climate change</p>
  </div>

  <div class="cpi-content-bottom">
    <div class="cpi-program">
      <div class="sponsor-logo-container">
        <img class="sponsor-logo" src="https://oes-hosted-images-public.s3.amazonaws.com/ipff-logo.png" width="150px" />
      </div>
      <p>Climate Policy Initiative has introduced the India PURE Finance Facility (IPFF), a pioneering
         initiative aimed at promoting the productive utilization of renewable energy (PURE) in rural,
         semi-urban, and remote areas across the country. By facilitating the transition from fossil fuel-
         based processes and equipment, IPFF contributes to emissions reduction while unlocking significant
         potential for livelihood generation, as well as social and economic development.</p>
      <p>Supported by committed funds from the renowned international philanthropy, IKEA Foundation, IPFF
         focuses on preparing select PURE projects to attract long-term equity or debt financing from both
         domestic and international financiers, with CPI acting as the program manager to coordinate its
         implementation.</p>
      <div class="cpi-program-footer">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/ikea-foundation.png" width="150px" height="16.55px" />
        <button [routerLink]="['../finance/programs']" class="btn btn-primary">{{ 'buttons.apply' | translate }}</button>
      </div>
    </div>

    <div class="cpi-program">
      <div class="sponsor-logo-container">
        <img class="sponsor-logo" src="https://oes-hosted-images-public.s3.amazonaws.com/icef-logo.png" width="110px" />
      </div>
      <p>India Clean Energy Finance (ICEF) is a project preparation facility managed by Climate Policy
         Initiative in India, building on the successful completion of US-India Clean Energy Finance Facility
         (USICEF). ICEF is a one-of-its-kind initiative to promote applications of distributed renewable energy
         (DRE) by supporting early-to-mid stage development of DRE projects and scaling up of DRE businesses.</p>
      <p>With funding support from MacArthur Foundation and Bloomberg Philanthropies, ICEF provides technical
         assistance that can help catalyse long-term debt financing and equity investments for DRE projects
         from banks, NBFCs, impact investors, and other international financial institutions.</p>
      <div class="cpi-program-footer">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/bloomberg-philanthropies.svg" width="110px" />
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/macarthur-foundation.jpg" width="100px" />
        <button [routerLink]="['../finance/programs']" class="btn btn-primary">{{ 'buttons.apply' | translate }}</button>
      </div>
    </div>
  </div>
</div>
