<div class="home" data-testid="paop-home-container">
  <div class="body">
    <img src="/assets/images/paop/paop.svg" width="280px" class="my-3">
    <div class="image-display">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/gen1.jpg"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/gen2.jpg"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/gen3.jpg"/>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
