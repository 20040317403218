import {BaseModel} from '../../base.model';
import { DataImportRequest } from './data-import-request.model';

export class DataImportRequestItem extends BaseModel<DataImportRequestItem> {
  created: Date;
  data: string;
  dataImportRequest: DataImportRequest;
  requestItemType: string;
  status: string;
  updated: Date;
  constructor(data: DataImportRequestItem) {
    super();
    this.created = data.created;
    this.data = data.data;
    this.dataImportRequest = data.dataImportRequest;
    this.requestItemType = data.requestItemType;
    this.status = data.status;
    this.updated = data.updated;
  }
}
