import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ComponentCanDeactivate } from './component-can-deactivate.interface';

@Injectable()
export class OrganizationUnsavedGuard  {
  constructor() {}

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot) {
    if (component.hasUnsavedData()) {
      return component.showUnsavedDataModal(nextState.url);
    }
    return true;
  }
}
