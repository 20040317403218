<div class="home" data-testid="arene-home-container">
  <div class="body">
    <img src="/assets/images/arene/logo-arene.png" width="300px" class="my-3">
    <div class="image-display">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/arene-1.jpg"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/arene-2.jpg"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/arene-3.jpg"/>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
