import { AuthConfig } from 'angular-oauth2-oidc';
import {environment} from '@environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.keycloak.issuer,
  redirectUri: window.location.origin,
  clientId: 'oes-dt-ui',
  responseType: 'code',
  scope: 'openid profile email',
  requireHttps: false,
  showDebugInformation: false,
  sessionChecksEnabled: true,
  sessionCheckIntervall: 1000 * 60 * 5, // (once every 5 minutes)
  silentRefreshShowIFrame: false,
  timeoutFactor: 0.01
};
