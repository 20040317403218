import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataImportRequestItem} from '@shared/models/io/data-import-request-item.model';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataImportRequestItemService extends BaseRestApiService {
  public organizationsUri = environment.serverUri + `api/${environment.apiVersion}/organizations`;
  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public getRequestItems(organizationId: string, requestId: string): Observable<DataImportRequestItem[]> {
    return this.get<DataImportRequestItem[]>(`${this.organizationsUri}/${organizationId}/data/requests/${requestId}/items`, {params: {page: 0, size: 100000}})
            .pipe(
              map(results => results.map(result => new DataImportRequestItem(result)))
            );
  }
}
