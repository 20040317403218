import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-rensource',
  templateUrl: './rensource.component.html',
  styleUrls: ['./rensource.component.scss']
})
export class RensourceComponent {

  constructor() { }

}
