import { Organization } from '@shared/models/organization/organization.model';

export enum VisualizationType {
  kpi = 'KPI',
  line = 'LINE',
  pie = 'PIE',
  currency = 'CURRENCY',
}

export enum VisualizationCategory {
  projects = 'projectVisualizations',
  organization = 'organizationVisualizations',
  platform = 'platformVisualizations',
}

export enum VisualizationAction {
  edit = 'edit',
  delete = 'delete'
}

export interface VisualizationItem {
  visualizationType: VisualizationType;
  kibanaIndex: string;
  query: any;
  value?: any; // for odyssey platform which has no query, but has a value
  name: string;
  order: number;
}

export class Visualization {
  projectVisualizations: VisualizationItem[];
  organizationVisualizations: VisualizationItem[];
  /**
   * @deprecated: Odyssey Platform moves to an independent object
   */
  platformVisualizations: VisualizationItem[];

  constructor (visualization: Visualization) {
    this.projectVisualizations = [];
    this.organizationVisualizations = [];
    if (visualization.projectVisualizations?.length > 0) {
      this.projectVisualizations = visualization.projectVisualizations.sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        } else if (a.order === b.order) {
          return 0;
        }
        return -1;
      });
    }
    if (visualization.organizationVisualizations?.length > 0) {
      this.organizationVisualizations = visualization.organizationVisualizations.sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        } else if (a.order === b.order) {
          return 0;
        }
        return -1;
      });
    }
  }
}

export class VisualizationProfile {
  id?: string;
  organization?: Organization;
  name: string;
  visualizations: Visualization;

  constructor(profile: VisualizationProfile) {
    if (profile.id) {
      this.id = profile.id;
    }
    if (profile.organization) {
      this.organization = new Organization(profile.organization);
    }
    this.name = profile.name;
    if (profile.visualizations) {
      this.visualizations = new Visualization(profile.visualizations);
    }
  }
}
