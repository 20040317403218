<div class="home" data-testid="burundi-home-container">
  <div class="body">
    <img src="/assets/images/burundi/burundi-logo.jpg" width="670px">
    <img src="/assets/images/burundi/esmap-logo.png" width="240px" class="mt-5">
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
