<div class="home">
  <div class="body">
    <div class="header">
      <img src="/assets/images/earf/earf-main.png" width="400px">
    </div>
    <div class="image-display">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/earf1s.jpg"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/earf2s.jpg"/>
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/earf3s.jpg"/>
    </div>
    <div class="bg-container">
      <div class="logos">
        <img src="/assets/images/earf/sima-home.png" class="d-block mx-auto earf-home-logo" style="width: 250px;">
      </div>
      <button class="btn btn-primary" [routerLink]="['../finance/programs']">
        {{ 'buttons.apply' | translate }}
      </button>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
