import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-aber',
  templateUrl: './aber.component.html',
  styleUrls: ['./aber.component.scss']
})
export class AberComponent {

  constructor() { }

}
