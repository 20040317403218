import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-earf',
  templateUrl: './earf.component.html',
  styleUrls: ['./earf.component.scss']
})
export class EarfComponent {

  constructor() { }

}
