import { Component, Input } from '@angular/core';

@Component({
  selector: 'oes-program-homepage-item',
  templateUrl: './program-homepage-item.component.html',
  styleUrls: ['./program-homepage-item.component.scss']
})
export class ProgramHomepageItemComponent {

  @Input() heading: string;
  @Input() videoLink: string;
  @Input() videoIcon: string;
  @Input() videoText: string;
  @Input() guideLink: string;
  @Input() guideIcon: string;
  @Input() guideText: string;

  constructor() { }
}
