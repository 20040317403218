import {Injectable} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {EventService} from '@shared/event.service';
import {CacheRegistrationService} from './cache-registration.service';
import {OAuthService} from 'angular-oauth2-oidc';
import { environment } from '@environments/environment';

@Injectable()
export class CacheErrorInterceptor implements HttpInterceptor {
  // TODO: translateService doesn't work in interceptor now, let's check this at the newer version.
  private message = {};
  private cache = new Map<string, any>();

  constructor(private _router: Router,
              private _eventService: EventService,
              private _cacheRegistrationService: CacheRegistrationService,
              private _translateService: TranslateService,
              private _oauthService: OAuthService) {
    this.message = this._translateService.instant('error-message');
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(errorResponse => this.oesCatchError(errorResponse, request)),
    );
  }

  private oesCatchError(errorResponse: HttpErrorResponse, request?: HttpRequest<any>) {
    if (!errorResponse || !errorResponse.status) {
      return throwError(errorResponse);
    }
    const errorDescription = errorResponse?.error?.error_description;
    switch (errorResponse?.status) {
      // Backend: IllegalArgumentException
      case (400):
        console.log('400', errorDescription);
        if (errorResponse?.error?.message?.includes('Could not locate record')) {
          this._oauthService.logOut();
        }
        // Client session idle timeout. We can't renew your token after this timeout, bring a user to login
        if (errorResponse?.error?.error === 'invalid_grant') {
          if (errorDescription === 'Account disabled') {
            this._eventService.error(errorDescription + '. ' + this._translateService.instant('general-message.error.contact'));
          } else {
            this._eventService.error(this._translateService.instant('error-message.please-login'));
          }
          if (environment.name !== 'dev') {
            this._oauthService.logOut();
          }
          return EMPTY;
        }
        break;
      // Backend: Forbidden
      case (403):
        this._eventService.error(this._translateService.instant('Not authorized'));
        return EMPTY;
        break;
      // Backend: security exception
      case (401):
        this._eventService.error(this._translateService.instant('Not authorized'));
        if (environment.name !== 'dev') {
          this._oauthService.logOut();
        }
        return EMPTY;
      // Backend: DataRetrievalFailureException
      // Backend: NoSuchElementException
      // Backend: IllegalStateException
      case (404):
        // do something
        break;
      // Backend: NullPointer exception
      // Backend: exceptions that are not mapped
      case (500):
        // do something
        break;
    }
    return throwError(errorResponse);
  }

  private getUsernameFromRequest(request: HttpRequest<any>): string {
    const params = request.body.split('&').reduce((accum: Object, keyValue: string) => {
      const keyValueArray = keyValue.split('=');
      accum[keyValueArray[0]] = keyValueArray[1];
      return accum;
    }, {});
    return unescape(params.username);
  }
}
