import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-nerc',
  templateUrl: './nerc.component.html',
  styleUrls: ['./nerc.component.scss']
})
export class NercComponent {

  constructor() { }

}
