import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';

import { OrganizationNavigationComponent } from './organization/organization-navigation/organization-navigation.component';
import { OrganizationDeveloperPublicProfileComponent } from './organization/developer-information/public-profile/organization-developer-public-profile.component';
import { OrganizationDeveloperFurtherInformationComponent } from './organization/developer-information/further-information/organization-developer-further-information.component';
import { OrganizationInvestorPublicProfileComponent } from './organization/investor-information/public-profile/organization-investor-public-profile.component';
import { OrganizationInvestorFurtherInformationComponent } from './organization/investor-information/further-information/organization-investor-further-information.component';
import { OrganizationVendorPublicProfileComponent } from './organization/vendor-information/public-profile/organization-vendor-public-profile.component';
import { OrganizationProfileComponent } from './organization/organization-profile/organization-profile.component';
import { CountryMultiselectComponent } from './organization/shared/country-multiselect/country-multiselect.component';
import { DataManagerComponent } from './data-manager/data-manager.component';
import { GetStartedComponent } from './organization/get-started/get-started.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [
    OrganizationNavigationComponent,
    OrganizationDeveloperPublicProfileComponent,
    OrganizationDeveloperFurtherInformationComponent,
    OrganizationInvestorPublicProfileComponent,
    OrganizationInvestorFurtherInformationComponent,
    OrganizationVendorPublicProfileComponent,
    OrganizationProfileComponent,
    CountryMultiselectComponent,
    DataManagerComponent,
    GetStartedComponent,
  ],
  providers: [
  ]
})
export class OrganizationManagementModule {}
