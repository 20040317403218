import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';
import { User } from '@user/user.model';

@Injectable({
  providedIn: 'root'
})
export class FreshchatService {

  constructor(@Inject(DOCUMENT) private document: any) {
  }

  // call from app.component
  public addScript() {
    if (window['fcWidget']) {
      window['fcWidget'].init({
        token: '093b7a8b-e68c-4503-8c31-cf95fe332778',
        host: 'https://wchat.freshchat.com',
        siteId: environment.name
      });
    }
  }

  // call from main-navigation
  public addUserInformation(user: User) {
    if (window['fcWidget']) {
      window['fcWidget'].user.setProperties({
        firstName: user.firstName,
        lastName: user.lastName,
        organization: user.organization.name,
        email: user.emailAddress,
        externalId: user.emailAddress
      });
    }
  }
}
