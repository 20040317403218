import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('CompanyName=Odyssey Energy Solutions, Inc,LicensedApplication=Odyssey,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-034314,SupportServicesEnd=27_October_2023_[v2]_MTY5ODM2MTIwMDAwMA==e04ae29aede0c9a0b4cbd08f01fc67e3');

if (environment.production) {
  enableProdMode();
}

// document.addEventListener('DOMContentLoaded', () => {
//   platformBrowserDynamic().bootstrapModule(AppModule);
// });

// from angular9's main.ts
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
