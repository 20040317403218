import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-anper',
  templateUrl: './anper.component.html',
  styleUrls: ['./anper.component.scss']
})
export class AnperComponent {

  constructor() { }
}
