import {Component, OnInit} from '@angular/core';
import {EventService} from '@shared/event.service';
import {EVENT_TYPE} from '@shared/event-types';
import {MessageItem} from './message-item.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'oes-popup-message',
  templateUrl: 'popup-message.component.html',
  styleUrls: ['popup-message.component.scss']
})



export class PopupMessageComponent implements OnInit {
  ERROR_DISPLAY_TIME_SECONDS: number = 15;
  SUCCESS_DISPLAY_TIME_SECONDS: number = 5;
  eventType = EVENT_TYPE;
  messageHandlers: MessageItem[] = [];
  duplicateMessages: string[] = [];

  constructor(protected _eventService: EventService,
              private _translateService: TranslateService) {
  }

  handleMessage(eventType: EVENT_TYPE, message: string | string[]) {
    let incomingMessage;
    incomingMessage = new MessageItem({
      message: message,
      messageType: eventType
    });

    if (this.messageHandlers.length < 6 && this.allowDuplicateMessages()) {
      this.messageHandlers.push(incomingMessage);
    } else {
      const index = this.messageHandlers
                    .map((messageItem: MessageItem) => messageItem.messageType)
                    .lastIndexOf(incomingMessage.messageType);

      if (index >= 0) {
        const prevMessageHandler = this.messageHandlers.splice(index, 1, incomingMessage);
        if (prevMessageHandler && prevMessageHandler.length > 0) {
          clearTimeout(prevMessageHandler[0].timerId);
        }
      }
    }
    incomingMessage.displayTime = this.messageDisplayTime(eventType);
    this.removeMessagesAfterTime(incomingMessage);
  }

  messageDisplayTime(eventType: EVENT_TYPE): number {
    switch (eventType) {
      case EVENT_TYPE.ERROR_MESSAGE:
      case EVENT_TYPE.WARNING_MESSAGE:
        return this.ERROR_DISPLAY_TIME_SECONDS * 1000;
      case EVENT_TYPE.SUCCESS_MESSAGE:
        return this.SUCCESS_DISPLAY_TIME_SECONDS * 1000;
      default:
        return this.ERROR_DISPLAY_TIME_SECONDS * 1000;
    }
  }

  removeMessagesAfterTime(errorHandler: MessageItem) {
    let timerId: number;
    timerId = window.setTimeout(() => {
      this.clearMessage(this.messageHandlers[0].timerId);
    }, errorHandler.displayTime);
    errorHandler.timerId = timerId;
  }


  clearMessage(id: number) {
    clearTimeout(id);
    const index = this.messageHandlers
                    .map((errHandler: MessageItem) => errHandler.timerId)
                    .indexOf(id);
    this.messageHandlers.splice(index, 1);
  }

  allowDuplicateMessages(): boolean {
    const currErrMessages = this.messageHandlers.map((errorHandler: MessageItem) => errorHandler.message);
    for (let i = 0; i < currErrMessages.length; i++) {
      for (let j = 0; j < this.duplicateMessages.length; j++) {
        if (currErrMessages[i] === this.duplicateMessages[j]) {
          return false;
        }
      }
    }
    return true;
  }

  ngOnInit() {
    this._eventService.on(EVENT_TYPE.ERROR_MESSAGE, message => this.handleMessage(EVENT_TYPE.ERROR_MESSAGE, message));
    this._eventService.on(EVENT_TYPE.WARNING_MESSAGE, message => this.handleMessage(EVENT_TYPE.WARNING_MESSAGE, message));
    this._eventService.on(EVENT_TYPE.SUCCESS_MESSAGE, message => this.handleMessage(EVENT_TYPE.SUCCESS_MESSAGE, message));
    this.duplicateMessages.push(this._translateService.instant('error-message.security-expiration'));
  }
}
