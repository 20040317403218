import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { EventService } from '@shared/event.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { OdysseyPlatform } from './odyssey-platform';
import { OdysseyPlatformService } from './odyssey-platform/odyssey-platform.service';
import { Organization } from '@shared/models/organization/organization.model';
import { OrganizationImage } from '@organization-management/organization/organization-image.model';
import { OrganizationLogoImageService } from '@organization-management/organization/organization-logo-image.service';
import { ProjectService } from '@project/shared/project.service';
import { ProjectVisualizationService } from './project-visualization.service';
import { ROLE_TYPE } from '@user/role-type';
import { SimpleProject } from '@project/shared/simple-project.model';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { VisualizationProfile, VisualizationItem, VisualizationAction, VisualizationType, VisualizationCategory } from './visualization-profile.model';
import { VisualizationProfileService } from './visualization-profile.service';

@Component({
  selector: 'oes-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @Input() hasCommercialInvestorDemoRole: boolean;
  @Input()
  get selectedRole(): ROLE_TYPE { return this._selectedRole; }
  set selectedRole(selectedRole: ROLE_TYPE) {
    this._selectedRole = selectedRole;
    this.getProjectsByRole(selectedRole);
  }
  _selectedRole: ROLE_TYPE;

  @ViewChild(ModalDialogComponent, {static: false}) createVisualizationDialog: ModalDialogComponent;

  cdnUri: string;
  dataAnalyst: boolean;
  formGroup: UntypedFormGroup;
  logoImage: OrganizationImage;
  mapProjects: SimpleProject[];
  orgAdmin: boolean;
  organization: Organization;
  platformVis: OdysseyPlatform;
  profile: VisualizationProfile;
  showOrganizationKpi = false;
  systemAdmin: boolean = false;
  types = Object.values(VisualizationType);
  user: User;
  visualizationCategory = VisualizationCategory;
  visualizationType = VisualizationType;

  private targetCategory: VisualizationCategory;

  constructor(private _visualizationProfileService: VisualizationProfileService,
              private _projectService: ProjectService,
              private _translateService: TranslateService,
              private _userService: UserService,
              private _organizationLogoImageService: OrganizationLogoImageService,
              private _odysseyPlatformService: OdysseyPlatformService,
              private _eventService: EventService,
              private _projectVisualizationService: ProjectVisualizationService) {
  }

  ngOnInit(): void {
    this.cdnUri = environment.organizationCdnUri;
    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe((user: User) => {
      this.user = user;
      this.dataAnalyst = user.hasRole(ROLE_TYPE.DATA_ANALYST);
      this.organization = user.organization;
      this.orgAdmin = user.hasRole(ROLE_TYPE.ORGANIZATION_ADMIN);
      this.showOrganizationKpi = (user?.organization?.name !== 'Odyssey Energy Solutions');
      this.getLogo(user.organization.id);
      this.loadOdysseyPlatform();
    });
    this.systemAdmin = this._userService.hasRole(ROLE_TYPE.SYSTEM_ADMIN);
    this.initForm();
    this.loadVisualizationProfile();
  }

  private loadOdysseyPlatform() {
    this._odysseyPlatformService.getData()
    .pipe(take(1))
    .subscribe((platformVis: OdysseyPlatform) => {
      this.platformVis = platformVis;
    });
  }

  getLogo(organizationId: string) {
    this._organizationLogoImageService.getImage(organizationId)
    .pipe(take(1))
    .subscribe((responses: OrganizationImage[]) => {
      if (responses?.length > 0) {
        this.logoImage = responses[0];
      }
    });
  }

  private getMyProjects() {
    this._projectService.myList()
    .pipe(take(1))
    .subscribe((projects: SimpleProject[]) => {
      this.mapProjects = projects;
    });
  }

  private getSharedProjects() {
    this._projectService.sharedList()
    .pipe(take(1))
    .subscribe((projects: SimpleProject[]) => {
      this.mapProjects = projects;
    });
  }

  private getProjectsByRole(roleType: ROLE_TYPE) {
    switch (roleType) {
      case ROLE_TYPE.FINANCE_USER:
        this.getSharedProjects();
        break;
      case ROLE_TYPE.DEVELOPER_USER:
      default:
        this.getMyProjects();
        break;
    }
  }

  private loadVisualizationProfile() {
    this._visualizationProfileService.getProfiles()
    .pipe(take(1))
    .subscribe((profiles: VisualizationProfile[]) => {
      if (profiles?.length > 0) {
        // profiles[0] is for Developer
        this.profile = profiles[0];
      } else {
        this.createDefaultProfiles();
      }
    });
  }

  private initForm() {
    this.formGroup = new UntypedFormGroup({
      visualizationId: new UntypedFormControl(),
      title: new UntypedFormControl(),
      type: new UntypedFormControl('', Validators.required),
      kibanaIndex: new UntypedFormControl('', Validators.required),
      query: new UntypedFormControl('', Validators.required),
      order: new UntypedFormControl(),
    });
  }

  // open dialog
  add(visualizationCategory: VisualizationCategory) {
    this.targetCategory = visualizationCategory;
    this.createVisualizationDialog.show();
  }

  // dialog callback
  addMetric() {
    if (this.profile) {
      const editIndex = this.formGroup.controls['visualizationId'].value;
      const newMetric = this.setVisualizationItem(this.formGroup);
      if (this.targetCategory) {
        if (typeof editIndex === 'number' && editIndex >= 0) {
          this.profile.visualizations[this.targetCategory][editIndex] = newMetric;
        } else {
          this.profile.visualizations[this.targetCategory].push(newMetric);
        }
      }
      this._visualizationProfileService.addProfile(this.profile)
      .pipe(take(1))
      .subscribe(result => {
        this._eventService.success(this._translateService.instant('success-message.vis-added'));
        this.loadVisualizationProfile();
      }, error => {
        this._eventService.error(this._translateService.instant('success-error.vis-not-added'));
      });
      this.targetCategory = undefined;
      this.formGroup.reset();
      this.createVisualizationDialog.hide();
    } else {
      this._eventService.error(this._translateService.instant('error-message.please-create'));
    }
  }

  close() {
    this.targetCategory = undefined;
    this.formGroup.reset();
  }

  // Create default profiles
  createDefaultProfiles() {
    const profileDeveloper = this._projectVisualizationService.getVisualization(ROLE_TYPE.DEVELOPER_USER);
    this._visualizationProfileService.createProfile(profileDeveloper)
    .pipe(take(1))
    .subscribe((profile: VisualizationProfile) => {
      this.profile = profile;
    });
  }

  // delete the current profile
  // if you get an error, maybe the profile has no visualization. Update backend to delete.
  deleteProfile() {
    this._visualizationProfileService.deleteProfile(this.profile.id)
    .pipe(take(1))
    .subscribe(result => {
      this.loadVisualizationProfile();
      this._eventService.success(this._translateService.instant('success-message.profile-deleted'));
    });
  }

  actionVisualization(event: any, visualizationCategory: VisualizationCategory) {
    if (event.action === VisualizationAction.delete) {
      this.deleteVisualization(event.visualizationId, visualizationCategory);
      this._visualizationProfileService.updateProfile(this.profile)
      .pipe(take(1))
      .subscribe(result => {
        this._eventService.success(this._translateService.instant('success-message.vis-removed'));
        this.loadVisualizationProfile();
      }, error => {
        this._eventService.error(this._translateService.instant('error-message.vis-not-removed'));
      });
      this.targetCategory = undefined;
      this.formGroup.reset();
    } else if (event.action === VisualizationAction.edit) {
      this.edit(event.visualizationId, visualizationCategory);
    }
  }

  private deleteVisualization(visualizationId: number, visualizationCategory: VisualizationCategory) {
    if (visualizationCategory) {
      this.profile.visualizations[visualizationCategory].splice(visualizationId, 1);
    }
  }

  private edit(visualizationId: number, visualizationCategory: VisualizationCategory) {
    // set values
    let visualizationItem: VisualizationItem;
    if (visualizationCategory) {
      visualizationItem = this.profile.visualizations[visualizationCategory][visualizationId];
    }
    this.formGroup.controls['visualizationId'].setValue(visualizationId, {eventEmit: false});
    this.formGroup.controls['kibanaIndex'].setValue(visualizationItem.kibanaIndex, {eventEmit: false});
    this.formGroup.controls['title'].setValue(visualizationItem.name, {eventEmit: false});
    this.formGroup.controls['type'].setValue(visualizationItem.visualizationType, {eventEmit: false});
    this.formGroup.controls['query'].setValue(visualizationItem.query, {eventEmit: false});
    this.formGroup.controls['order'].setValue(visualizationItem.order, {eventEmit: false});
    // open dialog
    this.add(visualizationCategory);
  }

  private setVisualizationItem(formGroup: UntypedFormGroup): VisualizationItem {
    return {
      visualizationType: formGroup.controls['type'].value,
      kibanaIndex: formGroup.controls['kibanaIndex'].value,
      query: formGroup.controls['query'].value,
      name: formGroup.controls['title'].value,
      order: formGroup.controls['order'].value
    };
  }
}
