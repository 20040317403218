import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ROLE_TYPE } from '@user/role-type';
import { Subject } from 'rxjs';

import { UserService } from '@user/user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'oes-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss']
})
export class RoleSelectorComponent implements OnInit, OnDestroy {
  @Input() hideTooltip: () => void;
  @Input() navOpen: boolean = true;
  @Input() showTooltip: (e: MouseEvent, element: HTMLElement, text: string) => void;

  availableRoles = {
    developer: false,
    investor: false,
    vendor: false,
  };
  displayRoleSelector = 0;
  roleType = ROLE_TYPE;
  selectedRole: ROLE_TYPE;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _userService: UserService) {
  }

  ngOnInit() {
    this.subscribeUserRole();
    this.roleAvailability();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private subscribeUserRole() {
    this._userService.userRole$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((role: ROLE_TYPE) => {
      this.selectedRole = role;
    });
  }

  private roleAvailability() {
    const systemAdmin = this._userService.hasRole(ROLE_TYPE.SYSTEM_ADMIN);
    const orgAdmin = this._userService.hasRole(ROLE_TYPE.ORGANIZATION_ADMIN);
    const dataAnalyst = this._userService.hasRole(ROLE_TYPE.DATA_ANALYST);
    this.availableRoles.developer = this._userService.hasRole(ROLE_TYPE.DEVELOPER_USER);
    this.availableRoles.investor = this._userService.hasRole(ROLE_TYPE.FINANCE_USER);
    this.availableRoles.vendor = this._userService.hasRole(ROLE_TYPE.VENDOR_USER);

    // show role selector
    const multipleRoles = Object.values(this.availableRoles).filter(item => item);
    if (multipleRoles?.length > 0) {
      this.displayRoleSelector = multipleRoles?.length;
      // set default role
      const role = this._userService.getSelectedRole();
      this._userService.setSelectedRole(role);
    } else if (systemAdmin) {
      this._userService.setSelectedRole(ROLE_TYPE.SYSTEM_ADMIN);
    } else if (orgAdmin) {
      this._userService.setSelectedRole(ROLE_TYPE.ORGANIZATION_ADMIN);
    } else if (dataAnalyst) {
      this._userService.setSelectedRole(ROLE_TYPE.DATA_ANALYST);
    }
  }

  public selectType(type: ROLE_TYPE) {
    // save selected role
    this._userService.setSelectedRole(type);
  }
}
