<div class="home" data-testid="powertrust-home-container">
  <div class="body">
    <div class="header">
      <img src="/assets/images/powertrust/powertrust-logo.png" width="410px">
    </div>
    <div class="home-3-image-container">
      <div class="home-image-1"></div>
      <div class="home-image-2"></div>
      <div class="home-image-3"></div>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>

