<div class="home" data-testid="tchad-home-container">
  <div class="body">
    <div class="d-flex align-items-center tchad-main">
      <img src="/assets/images/tchad/tchad-logo.png" width="130px">
      <div class="tchad-header">
        <h5>MINISTERE DU PETROLE</h5>
        <h5>ET DE L'ENERGIE</h5>
      </div>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
