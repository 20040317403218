<form [formGroup]="formGroup" class="row">
  <div class="col-12">
    <div class="table-wrap" *ngIf="vendorInfo != null && vendorInfo != undefined">
      <div class="row">
        <div class="col-11">
          <h3>{{'organization-information.vendor.header' | translate}}</h3>
          <p>{{'organization-information.vendor.description' | translate}}</p>
        </div>
      </div>
      <div class="mt-30">
        <h4 class="mb-2 required">{{'organization-information.vendor.vendor-overview.heading' | translate}}</h4>
        <p class="mb-2">{{'organization-information.vendor.company-mission' | translate}}</p>
        <oes-quill
          *ngIf="organization"
          [initialValue]="organization.companyOverview"
          formControlName="companyOverview"
          [required]="true"
          [placeholder]="'organization-information.vendor.vendor-overview.overview' | translate"
          [characterLimit]="2000">
        </oes-quill>
        <oes-form-error-message
          [showInvalid]="formGroup?.controls?.companyOverview.invalid"
          [text]="'organization-information.vendor.vendor-overview.overview-err-msg' | translate">
        </oes-form-error-message>
      </div>
      <div class="mt-30">
        <!-- product offering -->
        <h4 class="mb-2 required">{{'organization-information.vendor.product-offering.header' | translate}}</h4>
        <p class="mb-2">{{'organization-information.vendor.product-offering.description' | translate}}</p>
        <oes-product-select
          [product]="vendorInfo"
          (selectionChange)="validateProductOffering(true)">
        </oes-product-select>
        <oes-form-error-message
          [showInvalid]="formGroup?.controls?.productOffering.invalid"
          [text]="'organization-information.vendor.product-offering.err-msg' | translate">
        </oes-form-error-message>
      </div>
      <div class="mt-30">
        <!-- Marketing material -->
        <h4 class="mb-3">{{'organization-information.vendor.marketing-material.header' | translate}}</h4>
        <p class="mb-2">
          {{'organization-information.vendor.marketing-material.description' | translate}}
        </p>
        <oes-files
          *ngIf="tagTypes"
          [storageKey]="'vendorPublicProfile'"
          [tagTypes]="tagTypes"
          [autoHeightOverride]="true"
          [accessControl]="accessControl">
        </oes-files>
        </div>
      <div class="mt-30">
        <!-- Markets of Sale -->
        <h4 class="mb-3">{{'organization-information.vendor.markets-of-sale.header' | translate}}</h4>
        <p class="mb-2">
          {{'organization-information.vendor.markets-of-sale.description' | translate}}
        </p>
        <oes-country-multiselect
          [currentCountries]="vendorInfo.marketsOfSaleCountries"
          (countriesChanged)="updateCountries($event, 'marketsOfSaleCountries')">
        </oes-country-multiselect>
      </div>
      <div class="mt-30">
        <!-- Product Distribution -->
        <h4 class="mb-3">{{'organization-information.vendor.product-distribution.header' | translate}}</h4>
        <p class="mb-2">
          {{'organization-information.vendor.product-distribution.description' | translate}}
        </p>
        <oes-country-multiselect
          [currentCountries]="vendorInfo.marketsOfDeliveryCountries"
          (countriesChanged)="updateCountries($event, 'marketsOfDeliveryCountries')">
        </oes-country-multiselect>
      </div>
      <div class="mt-30">
        <!-- Support service -->
        <h4 class="mb-3">{{'organization-information.vendor.support-services.header' | translate}}</h4>
        <p class="mb-2">
          {{'organization-information.vendor.support-services.description' | translate}}
        </p>
        <div class="mb-3">
          <oes-country-multiselect
            [currentCountries]="vendorInfo.marketsOfSupportCountries"
            (countriesChanged)="updateCountries($event, 'marketsOfSupportCountries')">
          </oes-country-multiselect>
        </div>
        <!-- Describe services or support offered in these markets -->
        <label class="mb-2">
          {{'organization-information.vendor.support-services.services-description' | translate}}
        </label>
        <oes-quill
          *ngIf="vendorInfo"
          [initialValue]="vendorInfo.productOffering"
          formControlName="productOffering"
          [placeholder]="'organization-information.vendor.support-services.services-placeholder' | translate"
          [characterLimit]="2000">
        </oes-quill>
      </div>
      <div class="mt-30">
        <!-- Total deployed to date -->
        <h4 class="mb-3">{{'organization-information.vendor.total-deployed.header' | translate}}</h4>
        <p class="mb-2">{{'organization-information.vendor.total-deployed.description' | translate}}</p>
        <input
          type="text"
          name="totalProductsDeployed"
          id="totalProductsDeployed"
          class="col-12 form-control"
          maxlength="128"
          placeholder="{{'organization-information.vendor.total-deployed.placeholder' | translate}}"
          formControlName="totalProductsDeployed"/>
      </div>
    </div>
    <div class="mt-2">
      <button *ngIf="showSaveCancelButtons"
        class="btn btn-primary pull-right mb-30"
        type="submit"
        (click)="save()"
        [disabled]="!userIsOrgAdmin || !formGroup.valid">
        {{'buttons.save' | translate}}
      </button>
    </div>
  </div>
</form>

<oes-unsaved-data-modal
  (saveDataEmitter)="save()"
  (discardChangesEmitter)="discardChanges()">
</oes-unsaved-data-modal>
