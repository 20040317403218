import { Injectable } from '@angular/core';
import { Visualization, VisualizationItem, VisualizationProfile, VisualizationType } from './visualization-profile.model';
import { ROLE_TYPE } from '@user/role-type';
// Developer
import operatingProjects from './es-queries/developer/operating-projects.json';
import enrolledPrograms from './es-queries/developer/enrolled-programs.json';
import installedCapacity from './es-queries/developer/installed-capacity.json';
import projectsInPipeline from './es-queries/developer/projects-in-pipeline.json';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectVisualizationService {
  private titles = {};

  constructor(private _translateService: TranslateService) {
    this.titles = _translateService.instant('home-kpi.project-kpi');
  }

  /**
   * @param roleType: we were thinking to use this for Developer and Investor
   * @returns VisualizationProfile
   */
   public getVisualization(roleType: ROLE_TYPE): VisualizationProfile {
    return new VisualizationProfile({
      name: `${roleType} Visualization Profile ${new Date()}`,
      visualizations: {
        projectVisualizations: this.getVisualizationItem(roleType),
        organizationVisualizations: [],
        platformVisualizations: []
      }
    });
  }

  private getVisualizationItem(roleType: ROLE_TYPE): VisualizationItem[] {
    // Developer
    return [
      {
        kibanaIndex: 'latest_project_logs',
        name: this.titles['project-in-pipeline'],
        order: 1,
        query: JSON.stringify(projectsInPipeline),
        visualizationType: VisualizationType.kpi
      },
      {
        kibanaIndex: 'latest_project_logs',
        name: this.titles['operating-projects'],
        order: 2,
        query: JSON.stringify(operatingProjects),
        visualizationType: VisualizationType.kpi
      },
      {
        kibanaIndex: 'latest_project_logs',
        name: this.titles['installed-capacity'],
        order: 3,
        query: JSON.stringify(installedCapacity),
        visualizationType: VisualizationType.kpi
      },
      {
        kibanaIndex: 'latest_project_logs',
        name: this.titles['enrolled-programs'],
        order: 4,
        query: JSON.stringify(enrolledPrograms),
        visualizationType: VisualizationType.kpi
      },
    ];
  }
}
