import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'oes-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  redirectSeconds = 15;

  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this._activatedRoute.url
      .pipe(take(1))
      .subscribe(url => {
        const lastSegment = url[url.length - 1].path;
        if (lastSegment === 'login') {
          this.redirectUserHome();
        } else {
          this.updateRedirectSeconds();
        }
      });
  }

  private updateRedirectSeconds() {
    setTimeout(() => {
      this.redirectSeconds = this.redirectSeconds - 1;
      if (this.redirectSeconds > 0) {
        this.updateRedirectSeconds();
      } else {
        this.redirectUserHome();
      }
    }, 1000);
  }

  redirectUserHome() {
    this.redirectSeconds = 0;
    this._router.navigate(['/oes/home']);
  }
}
