import { Component, Input } from '@angular/core';
import { ROLE_TYPE } from '@user/role-type';
import { OdysseyPlatform } from '../odyssey-platform';

@Component({
  selector: 'oes-odyssey-platform',
  templateUrl: './odyssey-platform.component.html',
  styleUrls: ['./odyssey-platform.component.scss']
})
export class OdysseyPlatformComponent {
  @Input() platformVis: OdysseyPlatform;
  @Input() selectedRole: ROLE_TYPE;

  // Manually Updated for Now
  totalAvailableFunding = '$2.3B';
}
