import { NavItem } from '../nav-item.model';

export class PortfolioDetailInvestorNav {
  public static item: NavItem[] = [
    {
      title: 'finance-marketplace.navigation.organization-profile',
      url: 'organization-profile',
      icon: '<img src="./assets/icons/line-icons/nav/grey/summary.svg" width="23px" height="23px">',
      testId: 'portfolio-nav-investor-organization-profile',
    },
    {
      title: 'finance-marketplace.navigation.project-details',
      url: 'project-overview',
      icon: '<img src="./assets/icons/line-icons/nav/grey/overview.svg" width="23px" height="23px">',
      testId: 'portfolio-nav-investor-project-details',
    },
    // {
    //   title: 'finance-marketplace.navigation.introduction',
    //   url: 'introduction',
    //   icon: '<img src="./assets/images/main-nav/portfolio/introduction.svg" width="23px" height="23px">',
    //   testId: 'portfolio-nav-investor-introduction',
    // },
    {
      title: 'finance-marketplace.navigation.business-plan.business-plan',
      url: 'business-plan',
      icon: '<img src="./assets/icons/line-icons/nav/grey/business-plan.svg" width="23px" height="23px">',
      testId: 'portfolio-nav-investor-business-plan',
    },
    {
      title: 'finance-marketplace.navigation.data-room',
      url: 'data-room',
      icon: '<img src="./assets/icons/line-icons/nav/grey/files.svg" width="23px" height="23px">',
      testId: 'portfolio-nav-investor-data-room',
    },
    {
      title: 'finance-marketplace.navigation.summary',
      url: 'summary',
      icon: '<img src="./assets/icons/line-icons/nav/grey/summary.svg" width="23px" height="23px">',
      testId: 'portfolio-nav-investor-summary',
    },
  ];
}
