import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-ifc',
  templateUrl: './ifc.component.html',
  styleUrls: ['./ifc.component.scss']
})
export class IfcComponent {

  constructor() { }

}
