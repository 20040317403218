<div class="user-profile">
  <section>
    <h3>{{'user-profile.account-settings-header'|translate}}</h3>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="formGroup">
          <div class="row">
            <div class="col-6">
              <label for="organization-name">{{'user-profile.organization' | translate}}</label>
              <input
                type="text"
                class="form-control"
                name="organization-name"
                data-testid="profile-organization-name"
                formControlName="organizationName">
            </div>
            <div class="col-6">
              <label for="username">{{'user-profile.username' | translate}}</label>
              <input
                type="text"
                class="form-control"
                name="username"
                data-testid="profile-username"
                placeholder="{{'user-profile.username' | translate}}"
                formControlName="userName">
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="first-name" class="required">{{'user-profile.first-name' | translate}}</label>
              <input
                type="text"
                class="form-control"
                name="first-name"
                data-testid="profile-first-name"
                placeholder="{{'user-profile.first-name' | translate}}"
                formControlName="firstName"
                required>
            </div>
            <div class="col-6">
              <label for="last-name" class="required">{{'user-profile.last-name' | translate}}</label>
              <input
                type="text"
                class="form-control"
                name="last-name"
                data-testid="profile-last-name"
                placeholder="{{'user-profile.last-name' | translate}}"
                formControlName="lastName"
                required>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="email-address">{{'user-profile.email-address' | translate}}</label>
              <input
                type="text"
                class="form-control"
                name="email-address"
                data-testid="profile-email"
                placeholder="{{'user-profile.email-address' | translate}}"
                formControlName="email">
            </div>
            <div class="col-6">
              <label for="primary-phone" class="required">{{'user-profile.primary-phone' | translate}}</label>
              <input
                type="text"
                class="form-control"
                name="primary-phone"
                data-testid="profile-primary-phone"
                placeholder="{{'user-profile.primary-phone' | translate}}"
                formControlName="phone"
                required>
            </div>
          </div>
          <!-- Buttons -->
          <div class="row pull-right mt-2">
            <div class="col account-settings-buttons">
              <button
                type="button"
                class="btn btn-secondary mt-auto mr-3"
                (click)="cancel()"
                data-testid="profile-cancel-button">
                {{'buttons.cancel' |translate}}
              </button>
              <button
                type="submit"
                class="btn btn-primary mt-auto"
                (click)="save()"
                data-testid="profile-save-button"
                [disabled]="!(formGroup?.touched && formGroup?.valid)">
                {{'buttons.save' | translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  <hr/>
  <section class="d-flex justify-content-start pb-4">
    <button
      type="submit"
      class="btn btn-primary mt-auto"
      data-testid="profile-change-password-submit-button"
      (click)="updatePassword()">
      {{'reset-password.password-header' | translate}}
    </button>
  </section>
</div>
