{
  "aggs": {},
  "size": 0,
  "fields": [
    {
      "field": "dataKey.timestamp",
      "format": "date_time"
    },
    {
      "field": "indexed",
      "format": "date_time"
    },
    {
      "field": "metadata.created",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.newest.DAILY_ASSET_SUMMARY",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.newest.DAILY_METER_SUMMARY",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.newest.ELECTRICITY_PAYMENT",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.newest.METER_METRICS",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.newest.MONTHLY_ASSET_SUMMARY",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.newest.MONTHLY_METER_SUMMARY",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.newest.UTILITY_METRICS",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.oldest.DAILY_ASSET_SUMMARY",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.oldest.DAILY_METER_SUMMARY",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.oldest.ELECTRICITY_PAYMENT",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.oldest.METER_METRICS",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.oldest.MONTHLY_ASSET_SUMMARY",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.oldest.MONTHLY_METER_SUMMARY",
      "format": "date_time"
    },
    {
      "field": "metadata.dataKeyStats.oldest.UTILITY_METRICS",
      "format": "date_time"
    },
    {
      "field": "metadata.deleteDate",
      "format": "date_time"
    },
    {
      "field": "metadata.deleteOn",
      "format": "date_time"
    },
    {
      "field": "metadata.project.cod",
      "format": "date_time"
    },
    {
      "field": "metadata.project.created",
      "format": "date_time"
    },
    {
      "field": "metadata.project.lockDateTime",
      "format": "date_time"
    },
    {
      "field": "metadata.project.updated",
      "format": "date_time"
    },
    {
      "field": "metadata.updated",
      "format": "date_time"
    },
    {
      "field": "project.cod",
      "format": "date_time"
    },
    {
      "field": "project.created",
      "format": "date_time"
    },
    {
      "field": "project.lockDateTime",
      "format": "date_time"
    },
    {
      "field": "project.projectFinancialModel.created",
      "format": "date_time"
    },
    {
      "field": "project.projectFinancialModel.projectFinancialYearInputs.created",
      "format": "date_time"
    },
    {
      "field": "project.projectFinancialModel.projectFinancialYearInputs.updated",
      "format": "date_time"
    },
    {
      "field": "project.projectFinancialModel.run.created",
      "format": "date_time"
    },
    {
      "field": "project.projectFinancialModel.run.updated",
      "format": "date_time"
    },
    {
      "field": "project.systemDistributionDesign.created",
      "format": "date_time"
    },
    {
      "field": "project.systemDistributionDesign.systemLoad.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.finalizeDateTime",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignCostItems.batteryInverterBosCost.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignCostItems.chargeControllerBosCost.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignCostItems.generatorBosCost.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignCostItems.otherBosCost.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignCostItems.pvInverterBosCost.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignCostItems.solarPvBosCost.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignCostItems.storageBosCost.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignCostItems.systemInverterBosCost.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignItems.batteryInverter.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignItems.chargeController.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignItems.generator.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignItems.grid.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignItems.panels.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignItems.solarInverter.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignItems.storage.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.projectGenerationDesignItems.systemInverter.created",
      "format": "date_time"
    },
    {
      "field": "project.systemGenerationDesign.systemLoad.created",
      "format": "date_time"
    },
    {
      "field": "project.updated",
      "format": "date_time"
    },
    {
      "field": "timestamp",
      "format": "date_time"
    },
    {
      "field": "yearMonth",
      "format": "date_time"
    }
  ],
  "script_fields": {
    "project.irr.percentage": {
      "script": {
        "source": "if(doc['project.projectFinancialModel.irr'].size() != 0){\n    return doc['project.projectFinancialModel.irr'].value\n}",
        "lang": "painless"
      }
    },
    "customer.category.business": {
      "script": {
        "source": "\nif(doc['project.systemLoadSummary.tiers.COMMERCIAL.connections'].size() != 0 && doc['project.systemLoadSummary.tiers.PRODUCTIVE.connections'].size() !=0){\n    return (doc['project.systemLoadSummary.tiers.COMMERCIAL.connections'].value + doc['project.systemLoadSummary.tiers.PRODUCTIVE.connections'].value)\n}\nif(doc['project.systemLoadSummary.tiers.COMMERCIAL.connections'].size() == 0 && doc['project.systemLoadSummary.tiers.PRODUCTIVE.connections'].size() !=0){\n    return (doc['project.systemLoadSummary.tiers.PRODUCTIVE.connections'].value)\n}\nif(doc['project.systemLoadSummary.tiers.COMMERCIAL.connections'].size() != 0 && doc['project.systemLoadSummary.tiers.PRODUCTIVE.connections'].size() == 0){\n    return (doc['project.systemLoadSummary.tiers.COMMERCIAL.connections'].value)\n}\nelse\n{return null}\n",
        "lang": "painless"
      }
    },
    "project.projectStageOrder": {
      "script": {
        "source": "if (doc['project.projectStage'].size() == 0) {return null}\nif (doc['project.projectStage'].value == 'SUBMITTED') {return 1}\nif (doc['project.projectStage'].value == 'RESUBMITTED') {return 2}\nif (doc['project.projectStage'].value == 'UNDER_REVIEW') {return 3}\nif (doc['project.projectStage'].value == 'INCOMPLETE') {return 4}\nif (doc['project.projectStage'].value == 'APPROVED') {return 5}\nif (doc['project.projectStage'].value == 'DECLINED') {return 6}\nif (doc['project.projectStage'].value == 'GRANT_AGREEMENT_SIGNATURE') {return 7}\nif (doc['project.projectStage'].value == 'FINANCING_SECURED') {return 8}\nif (doc['project.projectStage'].value == 'PURCHASE_ORDER_ASSETS') {return 9}\nif (doc['project.projectStage'].value == 'CLEARED_CUSTOMS') {return 10}\nif (doc['project.projectStage'].value == 'INSTALLATION') {return 11}\nif (doc['project.projectStage'].value == 'UNDER_CONSTRUCTION') {return 11.5}\nif (doc['project.projectStage'].value == 'COMMISSIONED') {return 12}\nif (doc['project.projectStage'].value == 'CUSTOMERS_CONNECTED') {return 13}\nif (doc['project.projectStage'].value == 'DISBURSED') {return 14}\nelse {return null}",
        "lang": "painless"
      }
    },
    "project.peopleImpacted": {
      "script": {
        "source": "\t\nif(doc['project.systemLoadSummary.tiers.RESIDENTIAL.connections'].size() != 0) \n{return 4.9 * doc['project.systemLoadSummary.tiers.RESIDENTIAL.connections'].value}\nelse {return 0}",
        "lang": "painless"
      }
    },
    "project.totalConnectionsSubmittedPaidApproved": {
      "script": {
        "source": "if (doc['project.totalConnectionsSubmitted'].size() !== 0) {return doc['project.totalConnectionsSubmitted'].value + doc['project.totalConnectionsApproved'].value + doc['project.totalConnectionsPaid'].value}",
        "lang": "painless"
      }
    },
    "project.GrantAgreementSigned": {
      "script": {
        "source": "if (doc['project.projectStage'].size() !== 0 && (doc['project.projectStage'].value == 'COMMISSIONED' || doc['project.projectStage'].value == 'GRANT_AGREEMENT_SIGNED')) {return 'SIGNED'} else {return 'NOT_SIGNED'}",
        "lang": "painless"
      }
    },
    "SiteApplicationStatus.LoadDataEntered": {
      "script": {
        "source": "if (doc['project.avgDailyKwh'].size() != 0 && doc['project.avgDailyKwh'].value > 0) {return 'Yes'} else {return 'No'}",
        "lang": "painless"
      }
    },
    "SiteApplicationStatus.GenDesignDataEntered": {
      "script": {
        "source": "if (doc['project.totalPV'].value > 0) {return 'Yes'} else {return 'No'}",
        "lang": "painless"
      }
    },
    "SiteApplicationData.DisitributionDesignDataEntered": {
      "script": {
        "source": "if (doc['project.systemDistributionDesign.totalCost'].value > 0) {return 'Yes'} else {return 'No'}",
        "lang": "painless"
      }
    },
    "SiteApplicationStatus.FinancialModelDataEntered": {
      "script": {
        "source": "if (doc['project.irr'].size() == 0) {return 'No'} if (doc['project.irr'].value == -10000) {return 'No'} else {return 'Yes'}",
        "lang": "painless"
      }
    },
    "SiteApplicationStatus.RevenueDataEntered": {
      "script": {
        "source": "if (doc['project.systemLoadSummary.totalAvgTariffPerKwh'].size() == 0) {return 'No'} if (doc['project.systemLoadSummary.totalAvgTariffPerKwh'].value == 0) {return 'No'} else {return 'Yes'}",
        "lang": "painless"
      }
    },
    "project.totalDailyLoadPerCustomer": {
      "script": {
        "source": "if(doc['project.systemLoadSummary.totalAvgDailyLoad'].size() != 0 && doc['project.totalCustomers'].size() != 0 \n&& doc['project.systemLoadSummary.totalAvgDailyLoad'].value != 0 && doc['project.totalCustomers'].value != 0)\n{return doc['project.systemLoadSummary.totalAvgDailyLoad'].value / doc['project.totalCustomers'].value} \nelse\n{return 0}",
        "lang": "painless"
      }
    }
  },
  "stored_fields": [
    "*"
  ],
  "_source": {
    "excludes": []
  },
  "query": {
    "bool": {
      "must": [],
      "filter": [
        {
          "match_all": {}
        },
        {
          "match_phrase": {
            "project.status": "OPERATING"
          }
        },
        {
          "range": {
            "timestamp": {
              "gte": "2021-06-19T23:15:50.217Z",
              "lte": "2021-07-19T23:15:50.217Z",
              "format": "strict_date_optional_time"
            }
          }
        }
      ],
      "should": [],
      "must_not": []
    }
  }
}
