<div class="home" data-testid="ecreee-home-container">
  <div class="body">
    <div class="header">
      <img src="/assets/images/ecr/ecr-logo-payoff.jpg" width="600px">
    </div>
    <h2>{{'home-ecr.welcome'|translate}}</h2>
    <div class="participation-guide">
      <h4>{{'home-ecr.read-first'|translate}}</h4>
      <div class="steps">
        <ul>
          <li>
            <a href="{{'home-ecr.eligibility-link' | translate}}" target="_blank">{{'home-ecr.eligibility' | translate}}
            </a>
          </li>
          <li>
            <a href="https://desk.zoho.com/portal/ecreee" target="_blank">{{'home-ecr.participation-guide' | translate}}
            </a>
          </li>
        </ul>
      </div>

      <table>
        <tr>
          <th>{{'home-ecr.submission-deadline-1'|translate}}</th>
          <td>{{'home-ecr.submission-deadline-2'|translate}}</td>
        </tr>
        <tr>
          <th>{{'home-ecr.submission-deadline-3'|translate}}</th>
          <td>{{'home-ecr.submission-deadline-4'|translate}}</td>
        </tr>
      </table>

      <div>
        {{'home-ecr.submission-deadline-5'|translate}}
        <span>
          <a href="mailto: support@odysseyenergysolutions.com">support&#64;odysseyenergysolutions.com</a>
        </span>
        {{'home-ecr.submission-deadline-6'|translate}}
        <span>
          <a href="{{'home-ecr.learn-more-link'|translate}}"
             target="_blank">{{'home-ecr.submission-deadline-7'|translate}}</a>.
        </span>
      </div>
      <div class="join-us">
        <a href="https://zoom.us/j/506741283"
           target="_blank">{{'home-ecr.join-us' | translate}}</a> {{'home-ecr.sessions' | translate}}
      </div>
      <div>
        <a href="https://goo.gl/forms/Vat0kB3ENDckhVQl1" target="_blank">
            {{'home-ecr.add-to-calendar' | translate}}
        </a>
      </div>
    </div>

    <div class="help-banner">
      <h4>{{'home.questions-1'|translate}}</h4>
      <p>{{'home.questions-2'|translate}}</p>
    </div>
  </div>
</div>
