import { Injectable } from '@angular/core';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';

import { Observable, of } from 'rxjs';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { VisualizationProfileApiConstants } from './visualization-profile.constant';
import { VisualizationProfile } from './visualization-profile.model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VisualizationProfileService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public getProfiles(): Observable<VisualizationProfile[]> {
    return this.get<VisualizationProfile[]>(VisualizationProfileApiConstants.kpi.list(), {params: {page: 0, size: 1000, sort: 'created,DESC'}})
      .pipe(
        map((result: any[]) => result.map(profile => new VisualizationProfile(profile)))
      );
  }

  public getProfile(id: string): Observable<VisualizationProfile> {
    return this.get<VisualizationProfile>(VisualizationProfileApiConstants.kpi.get(id));
  }

  public createProfile(profile: VisualizationProfile): Observable<any> {
    return this.post<any>(VisualizationProfileApiConstants.kpi.create(), JSON.stringify(profile));
  }

  public deleteProfile(id: string): Observable<any> {
    return this.delete<any>(VisualizationProfileApiConstants.kpi.delete(id));
  }

  public addProfile(profile: VisualizationProfile): Observable<any> {
    if (profile.id) {
      return this.put<any>(VisualizationProfileApiConstants.kpi.update(profile.id), JSON.stringify(profile));
    }
  }

  public updateProfile(profile: VisualizationProfile): Observable<any> {
    if (profile.id) {
      return this.put<any>(VisualizationProfileApiConstants.kpi.update(profile.id), JSON.stringify(profile));
    }
  }
}
