<div class="home" [ngClass]="{ 'developer' : selectedRole === roleType.DEVELOPER_USER || hasCommercialInvestorDemoRole }" data-testid="home-container">
  <ng-container *ngIf="(selectedRole !== roleType.VENDOR_USER &&
                        selectedRole !== roleType.FINANCE_USER &&
                        selectedRole !== roleType.ORGANIZATION_ADMIN) ||
                        hasCommercialInvestorDemoRole; else DefaultHome">
    <oes-dashboard
      [hasCommercialInvestorDemoRole]="hasCommercialInvestorDemoRole"
      [selectedRole]="selectedRole">
    </oes-dashboard>
  </ng-container>
  <ng-template #DefaultHome>

    <!-- Header/Logo -->
    <div class="home-header">
      <div class="org-logo">
        <ng-container *ngIf="logoImage?.id; else NoLogo">
          <img src="{{ cdnUri }}/{{ logoImage.id }}" />
        </ng-container>
        <ng-template #NoLogo>
          <div class="add-logo-container">
            <ng-container *ngIf="orgAdmin">
              <div class="add-logo-box">
                <a [routerLink]="['../organization-management/profile/organization-profile']" class="no-logo-empty-state">
                  {{ 'home.add-logo' | translate }}
                </a>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </div>
      <ng-container *ngIf="user?.firstName; else NoFirstName">
        <h3>{{ 'home.welcome-back' | translate }}, {{ user?.firstName }}</h3>
      </ng-container>
      <ng-template #NoFirstName>
        <h3>{{ 'home.welcome-back' | translate }}</h3>
      </ng-template>
      <p>{{ 'home.your-platform' | translate }}</p>
    </div>

    <!-- Links -->
    <div class="home-links">
      <div class="link">
        <div class="icon-container ic-1">
          <img src="./assets/icons/line-icons/nav/colors/compass.svg"/>
        </div>
        <h2>{{ 'home.opportunities' | translate }}</h2>
        <p>{{ 'home.analyze-and-respond' | translate }}</p>
        <a [routerLink]="['../projects/shared-projects']" [queryParams]="{ type: 'list' }" >{{ 'home.view-pipeline' | translate }} <img src="./assets/icons/line-icons/shared/orange/arrow-right.svg" /></a>
      </div>
      <div class="link">
        <div class="icon-container ic-2">
          <img src="./assets/icons/line-icons/nav/colors/bank.svg"/>
        </div>
        <h2>{{ 'home.due-diligence' | translate }}</h2>
        <p>{{ 'home.create-and-manage' | translate }}</p>
        <a [routerLink]="['../finance/programs']">{{ 'home.view-programs' | translate }} <img src="./assets/icons/line-icons/shared/orange/arrow-right.svg" /></a>
      </div>

      <div class="link">
        <div class="icon-container ic-3">
          <img src="./assets/icons/line-icons/nav/colors/pie-chart-03.svg"/>
        </div>
        <h2>{{ 'home.asset-management' | translate }}</h2>
        <p>{{ 'home.analyze-and-monitor' | translate }}</p>
        <a [routerLink]="['../analytics/dashboards']">{{ 'home.view-analytics' | translate }} <img src="./assets/icons/line-icons/shared/orange/arrow-right.svg" /></a>
      </div>
    </div>

    <!-- Images -->
    <div class="home-images">
      <div class="image-container imc-1"></div>
      <div class="image-container imc-2"></div>
      <div class="image-container imc-3"></div>
    </div>

  </ng-template>
</div>
