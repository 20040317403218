import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-tchad',
  templateUrl: './tchad.component.html',
  styleUrls: ['./tchad.component.scss']
})
export class TchadComponent {

  constructor() { }

}
